import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { languageAtom } from "state/app-language";

import { useProjectId } from "./Network/useProjectId";
import { useSessionUserInitial } from "./Network/useSessionUserInitial";

export const useSetUserLanguage = (isEnabled = true): undefined => {
  const projectId = useProjectId();
  const { data: sessionUser } = useSessionUserInitial(projectId);
  const setLanguage = useSetAtom(languageAtom);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    if (sessionUser) {
      setLanguage(sessionUser.language.id);
    }
  }, [isEnabled, sessionUser, setLanguage]);
};
