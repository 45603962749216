import type { AdminTicketActivityDto, AdminTicketCommentDto, AdminTicketDetailsDto, AppTicketDto } from "api/types";
import iconStar01 from "assets/icons/star-01.svg";
import { formatDate, FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { TicketStatus } from "components/Ticket/TicketStatus";
import { isDefined } from "helpers/util";
import { AnimatePresence } from "motion/react";
import { memo, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { groupActivities, TicketActivityWrapper, TicketComment } from "./TicketActivity";

interface TicketActivity extends AdminTicketActivityDto {
  createdAt?: string;
}

interface TicketActivitySectionProps {
  ticket: AdminTicketDetailsDto | AppTicketDto;
  daysOpen?: number;
  activities: TicketActivity[];
  onNoteEdit?: (comment: AdminTicketCommentDto) => void;
  isDescending?: boolean;
  isForResident?: boolean;
  scrollTarget?: React.ReactNode;
}

function TicketActivitySection({
  ticket,
  daysOpen,
  onNoteEdit,
  activities,
  isDescending,
  isForResident,
  scrollTarget,
}: TicketActivitySectionProps): React.ReactNode {
  const { t } = useTranslation();

  const groupedActivities = useMemo(() => groupActivities(activities), [activities]);

  if (activities.length === 0) {
    return <div className="pt-6">{t("page.tickets.details.activities.none")}</div>;
  }

  return (
    <div className="flex flex-col gap-2">
      {isDescending && scrollTarget}
      {groupedActivities.map(([date, activities]) => (
        <div key={date.toString()} data-testid="activities-date-group">
          <div className="relative my-4 flex items-center justify-center whitespace-nowrap text-caption text-grey-300">
            <span className="absolute h-px w-full max-w-xs bg-grey-300" />
            <span className="relative bg-white px-4 text-caption">
              <FormattedDate format="dateRelative" date={new Date(Number(date)).toISOString()} />
            </span>
          </div>
          <div>
            <AnimatePresence>
              {activities.map((activity) => {
                if (activity.comment) {
                  return (
                    <TicketComment
                      key={"comment-" + activity.comment.id}
                      ticketId={ticket.id}
                      comment={activity.comment}
                      onEdit={onNoteEdit}
                    />
                  );
                }
                if (activity.assigneeChange) {
                  return <TicketAssigneeChangeActivityItem key={activity.id} {...{ activity }} />;
                }
                if (activity.statusChange) {
                  return <TicketStatusChangeActivityItem key={activity.id} {...{ activity }} />;
                }
                if (activity.createdAt) {
                  return <TicketCreationActivityItem key={activity.id} {...{ activity }} />;
                }
                if (activity.rating) {
                  return (
                    <TicketRatingActivityItem
                      key={activity.id}
                      isForResident={isForResident}
                      amountDaysOpen={daysOpen}
                      {...{ activity }}
                    />
                  );
                }
              })}
            </AnimatePresence>
          </div>
        </div>
      ))}
      {!isDescending && scrollTarget}
    </div>
  );
}

type TicketActivityItemBaseProp = {
  activity: TicketActivity;
};

const TicketAssigneeChangeActivityItem = ({ activity }: TicketActivityItemBaseProp) => {
  if (!activity.assigneeChange) {
    return null;
  }

  return (
    <TicketActivityWrapper key={activity.id} author={activity.author} postedAt={activity.postedAt}>
      {activity.assigneeChange.oldAssignee ? (
        <Trans
          i18nKey="page.tickets.details.activities.assignee-change"
          components={{
            assignee: (
              <span
                className="mx-1 font-old-semibold not-italic"
                data-testid="activity-assignee-change"
                data-value={activity.assigneeChange.newAssignee.fullName}
              />
            ),
          }}
          values={{
            oldAssignee: activity.assigneeChange.oldAssignee.fullName,
            newAssignee: activity.assigneeChange.newAssignee.fullName,
          }}
        />
      ) : (
        <Trans
          i18nKey="page.tickets.details.activities.new-assignee"
          components={{
            assignee: (
              <span
                className="mx-1 font-old-semibold not-italic"
                data-testid="activity-assignee-change"
                data-value={activity.assigneeChange.newAssignee.fullName}
              />
            ),
          }}
          values={{
            newAssignee: activity.assigneeChange.newAssignee.fullName,
          }}
        />
      )}
    </TicketActivityWrapper>
  );
};

const TicketStatusChangeActivityItem = ({ activity }: TicketActivityItemBaseProp) => {
  if (!activity.statusChange) {
    return null;
  }

  return (
    <TicketActivityWrapper key={activity.id} author={activity.author} postedAt={activity.postedAt}>
      <Trans
        i18nKey="page.tickets.details.activities.status-change"
        components={{
          oldStatus: (
            <span className="mx-1 inline-block font-old-semibold not-italic">
              <TicketStatus
                description={activity.statusChange.oldStatus.name}
                labelColor={activity.statusChange.oldStatus.color}
              />
              <span />
            </span>
          ),
          newStatus: (
            <span
              className="mx-1 inline-block font-old-semibold not-italic"
              data-testid="activity-status-change"
              data-value={activity.statusChange.newStatus.id}
            >
              <TicketStatus
                description={activity.statusChange.newStatus.name}
                labelColor={activity.statusChange.newStatus.color}
              />
              <span />
            </span>
          ),
        }}
      />
    </TicketActivityWrapper>
  );
};

const TicketCreationActivityItem = ({ activity }: TicketActivityItemBaseProp) => {
  const { t, i18n } = useTranslation();

  if (!activity.createdAt) {
    return null;
  }

  return (
    <TicketActivityWrapper key={activity.id} author={activity.author} postedAt={activity.postedAt}>
      {t("page.tickets.details.activities.created", {
        date: formatDate(i18n, "datetimeRelative", activity.postedAt),
      })}
    </TicketActivityWrapper>
  );
};

type TicketRatingActivityItemProps = TicketActivityItemBaseProp & {
  isForResident?: boolean;
  amountDaysOpen: number | undefined;
};

const TicketRatingActivityItem = ({ activity, isForResident, amountDaysOpen }: TicketRatingActivityItemProps) => {
  const { t } = useTranslation();

  if (!activity.rating) {
    return null;
  }

  const feedbackLabels = activity.rating.ratingFeedback?.map((item) => item.value) || [];
  const isRatingAvailable = isDefined(activity.rating.rating) && activity.rating.rating > 0;

  return (
    <TicketActivityWrapper key={activity.id} author={activity.author} postedAt={activity.postedAt} withoutBorder>
      <div className="grid items-center gap-x-4 gap-y-0.5 not-italic all-child:odd:mt-2 xs:grid-cols-[auto_1fr] xs:gap-y-2 xs:all-child:odd:mt-0">
        <span className="text-body-bold xs:text-body">{t("page.tickets.details.activities.rated.rating")}</span>
        {isRatingAvailable ? (
          <span
            data-testid="log-rating-stars-element"
            className="inline-flex w-fit items-center gap-1 rounded bg-yellow-100 px-2 py-0.5"
          >
            <span className="flex gap-0.5">
              {Array(5)
                .fill(undefined)
                .map((_, i) => (
                  <Icon
                    key={i}
                    name={iconStar01}
                    className={twJoin(
                      "text-transparent",
                      activity.rating!.rating! > i ? "fill-yellow-500" : "fill-white",
                    )}
                  />
                ))}
            </span>
            <span className="text-body-bold">{activity.rating.rating}</span>
          </span>
        ) : (
          <span>{t("page.tickets.details.activities.rated.rating.not-relevant")}</span>
        )}

        {!isForResident && (
          <>
            <span className="text-body-bold xs:text-body">{t("page.tickets.details.activities.rated.run-time")}</span>
            <span>
              {amountDaysOpen == null
                ? "-"
                : t("page.tickets.details.activities.rated.run-time.days", { count: amountDaysOpen })}
            </span>
          </>
        )}

        {isRatingAvailable && (
          <>
            <span className="text-body-bold xs:text-body">
              {t("page.tickets.details.activities.rated.feedback-topics")}
            </span>
            <div className="flex flex-wrap gap-2">
              {feedbackLabels.length === 0 && <span>{t("common.label.not-applicable")}</span>}
              {feedbackLabels.length > 0 &&
                feedbackLabels.map((label) => {
                  return (
                    <Label
                      data-testid="log-rating-label-element"
                      theme="gray"
                      size="sm"
                      isUppercase={false}
                      key={label}
                    >
                      {label}
                    </Label>
                  );
                })}
            </div>
          </>
        )}

        <span className="text-body-bold xs:text-body">{t("page.tickets.details.activities.rated.remark")}</span>
        <span data-testid="log-rating-remark-element" className="italic">
          {activity.rating.ratingRemark || t("page.tickets.details.activities.rated.remark.none")}
        </span>
      </div>
    </TicketActivityWrapper>
  );
};

const TicketActivitySectionMemo = memo(TicketActivitySection);

export { TicketActivitySectionMemo as TicketActivitySection };
