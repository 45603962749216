import { keepPreviousData, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useCompanyQueries } from "queries/companies/queryOptions";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useMemo, useState } from "react";
import type { ApiQueryParams } from "types/api-types";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

const USERS_PAGE = 25;

export type UsersParams = Omit<ApiQueryParams<"getUsersV1">, "Limit" | "Offset">;
export type OnUpdateFilters = <T extends keyof UsersParams>(key: T, value: UsersParams[T]) => void;

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const sessionUser = useSessionUser();
  const api = useApi();

  const [memoryFilters, setMemoryFilters] = useState<UsersParams>(
    () =>
      ({
        Search: "",
        IncludeAdmins: true,
        IncludeDeleted: false,
        IncludeNotRegistered: true,
        SortBy: "firstName",
        SortDescending: false,
      }) as const,
  );

  const onUpdateFilters = useCallback<OnUpdateFilters>((key, value) => {
    setMemoryFilters((oldMemoryFilters) => ({
      ...oldMemoryFilters,
      [key]: value,
    }));
  }, []);

  const companyQueries = useCompanyQueries();
  const { data: companies, isLoading: isLoadingCompanies } = useQuery({
    ...companyQueries.getCompanies(),
    enabled: sessionUser.project.type === "companyBased",
  });

  const {
    data: userListData,
    isPending: isLoadingUsers,
    hasNextPage: hasMoreUsers,
    fetchNextPage: fetchMoreUsers,
    isFetching: isFetchingUsers,
    isFetchingNextPage: isFetchingMoreUsers,
    error: userListError,
  } = useInfiniteQuery({
    initialPageParam: 0,
    queryKey: QUERY_KEYS.USERS_QUERY(projectId, memoryFilters),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getUsersV1({
          ...memoryFilters,
          Offset: pageParam * USERS_PAGE,
          Limit: USERS_PAGE,
        })
        .then((items) => commonAPIDataSelector(items)),
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    placeholderData: keepPreviousData,
  });

  const {
    data: roles,
    isPending: isLoadingRoles,
    error: rolesError,
  } = useQuery({
    queryKey: QUERY_KEYS.ROLE_LIST(projectId),
    queryFn: () => api.getPermissionsRolesV1(),
    select: commonAPIDataSelector,
  });

  const userList = useMemo(() => userListData?.pages.flatMap((x) => x.items) ?? [], [userListData]);
  const userTotal = useMemo(() => userListData?.pages[0].total ?? 0, [userListData]);

  const error = userListError || rolesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isLoadingRoles || isLoadingCompanies;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    filters: memoryFilters,
    userTotal,
    onUpdateFilters,
    userList,
    isLoadingUsers,
    hasMoreUsers,
    loadMoreUsers: fetchMoreUsers,
    isFetchingMoreUsers,
    roles: roles ?? [],
    companies: companies?.items,
    selectedCompany: memoryFilters.CompanyId
      ? companies?.items.find((x) => x.id === memoryFilters.CompanyId)
      : undefined,
    isFetchingUsers: isFetchingUsers,
  });
}
