import type { AssetBookingDto } from "api/types";
import iconChevronLeft from "assets/icons/chevron-left.svg";
import iconChevronRight from "assets/icons/chevron-right.svg";
import { IconButton } from "components/Button/IconButton";
import { DateRange } from "components/DateRange/DateRange";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { addDays, addSeconds, differenceInDays, differenceInMinutes, isSameDay, parse, startOfDay } from "date-fns";
import { dayOfWeekIndex } from "helpers/date";
import { useInterval } from "hooks/useInterval";
import { min, sortBy } from "lodash-es";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { daysOptions } from "../constants";
import { getAllowedViewableDateRange, getDateFromTimeString, isTimeEndOfDay } from "../helpers";
import type { LayoutProps } from "../pages/AssetDetail/Layout";
import { ToggleCalendarButton } from "./ToggleCalendarButton";

interface CalendarWeekViewProps {
  assetDetails: LayoutProps["assetDetails"];
  startOfWeek: LayoutProps["startDate"];
  endOfWeek: LayoutProps["endDate"];
  isLoadingBookings: boolean;
  bookings: LayoutProps["bookings"];
  onChangeWeek: (startDate: Date) => void;
  onChangeView: () => void;
  onSelectBookings: (bookings: AssetBookingDto[]) => void;
}

export function CalendarWeekView({
  assetDetails,
  startOfWeek,
  endOfWeek,
  isLoadingBookings,
  bookings,
  onChangeWeek,
  onChangeView,
  onSelectBookings,
}: CalendarWeekViewProps): React.ReactNode {
  const [today, setToday] = useState(new Date());
  const { i18n, t } = useTranslation();
  const container = useRef<HTMLDivElement>(null);
  const containerNav = useRef(null);

  // Update the current time every 5 minutes
  useInterval(
    () => {
      setToday(new Date());
    },
    5 * 60 * 1000,
  );

  useEffect(() => {
    // Scroll past the blocked start hours
    const el = container.current;
    if (el) {
      const minStartHourThisWeek =
        min(
          assetDetails.bookableDays.map((bookableDay) =>
            bookableDay.times && bookableDay.times.length > 0 && bookableDay.times[0].startTime
              ? getDateFromTimeString(bookableDay.times[0].startTime).getHours()
              : 0,
          ),
        ) || 0;

      const minStartHour = Math.max(minStartHourThisWeek, 8);

      const scroll = (minStartHour / 24) * el.scrollHeight - el.offsetHeight * 0.25;

      el.scrollTop = scroll;
    }
  }, [assetDetails.bookableDays, today]);

  const hours = Array(24)
    .fill(0)
    .map((_, i) => i);
  const week = Array(7)
    .fill(0)
    .map((_, i) => {
      const date = addDays(startOfWeek, i);
      const isToday = isSameDay(date, today);
      const weekDay = formatDate(i18n, "weekDay", date);

      return { date, dayNumber: date.getDate(), name: weekDay, isToday };
    });

  const { minViewDate, maxViewDate } = getAllowedViewableDateRange(assetDetails);
  const timeslotUnitSize = 5; // 5 minutes
  const timeslotBlockSize = 30; // 30 minutes
  const amountTotalMinutesInDay = 1440; // 1440 minutes
  const sortedBookableDays = sortBy(assetDetails.bookableDays, (x) => daysOptions.indexOf(x.day));

  const partitionedBookings: AssetBookingDto[][] = useMemo(() => {
    return Object.values(
      bookings.reduce(
        (acc, booking) => {
          const key = `${booking.date}-${booking.startTime}-${booking.endTime}`;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(booking);

          return acc;
        },
        {} as Record<string, AssetBookingDto[]>,
      ),
    );
  }, [bookings]);

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="flex w-full flex-col-reverse flex-wrap items-center justify-center gap-4 md:my-0 md:flex-row md:justify-between">
        <div className="flex items-center gap-2">
          <IconButton
            title={t("page.bookings.asset-detail.week-view.previous")}
            onClick={() => onChangeWeek(addDays(startOfWeek, -7))}
            disabled={startOfWeek < minViewDate}
            size="sm"
            icon={iconChevronLeft}
          />
          <span className="min-w-[200px] text-center">
            <DateRange start={startOfWeek} end={addSeconds(endOfWeek, -1)} format="noTime" />
          </span>
          <IconButton
            title={t("page.bookings.asset-detail.week-view.next")}
            onClick={() => onChangeWeek(addDays(startOfWeek, 7))}
            disabled={endOfWeek >= maxViewDate}
            size="sm"
            icon={iconChevronRight}
          />
        </div>
        <div>
          <ToggleCalendarButton isWeek onToggleView={() => onChangeView()} />
        </div>
      </div>
      <div
        ref={container}
        className="relative flex max-h-screen-minus-50 flex-auto flex-col overflow-auto bg-aop-dark-blue-500/5"
      >
        {isLoadingBookings && (
          <div className="absolute inset-0">
            <FullSizeLoader />
          </div>
        )}
        <div className="flex w-[165%] max-w-none flex-none flex-col md:w-full md:max-w-full">
          {/* Weekday labels */}
          <div ref={containerNav} className="sticky top-0 z-30 flex-none bg-white pr-8 ring-1 ring-black/5">
            <div className="-mr-px grid grid-cols-7 divide-x divide-grey-100/50 border-r border-grey-100/50 text-overline leading-old-6 text-grey-600 sm:text-caption">
              <div className="col-end-1 w-12" />
              {week.map((day) => (
                <div key={day.dayNumber} className="flex items-center justify-center py-3">
                  <span className="flex items-baseline">
                    <span className="capitalize">{day.name.substring(0, 1)}</span>
                    <span className="sr-only lg:not-sr-only">{day.name.substring(1, 3)}</span>
                    <span className="sr-only 2xl:not-sr-only">{day.name.substring(3)}</span>
                    <span
                      className={twJoin(
                        "ml-1 flex size-6 items-center justify-center rounded-full font-old-semibold sm:ml-1.5 sm:size-8",
                        day.isToday ? "bg-aop-basic-blue-500 text-white" : "text-black",
                      )}
                    >
                      {day.dayNumber}
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>
          {/* Calendar body */}
          <div className="flex flex-1">
            <div
              className="sticky left-0 z-20 grid w-12 shrink-0 bg-white ring-1 ring-grey-100"
              style={{
                gridTemplateRows: `repeat(${amountTotalMinutesInDay / timeslotBlockSize}, minmax(2.5rem, 1fr))`,
              }}
            >
              {/* Reserved space above 00:00 */}
              <div className="row-end-1 h-6" />
              {hours.map((hour) => (
                <Fragment key={hour}>
                  <div className="sticky z-20 h-fit w-12 -translate-y-1/2 pr-2 text-right text-overline leading-none text-grey-500">
                    {hour}:00
                  </div>
                  <div />
                </Fragment>
              ))}
            </div>
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="z-10 col-start-1 col-end-2 row-start-1 grid divide-y divide-grey-300/30"
                style={{
                  gridTemplateRows: `repeat(${amountTotalMinutesInDay / timeslotBlockSize}, minmax(2.5rem, 1fr))`,
                }}
              >
                {/* Reserved space above 00:00 */}
                <div className="row-end-1 h-6" />
                {hours.map((hour) => (
                  <Fragment key={hour}>
                    <div />
                    <div />
                  </Fragment>
                ))}
              </div>
              {/* Vertical lines */}
              <div className="z-10 col-start-1 col-end-2 row-start-1 grid grid-cols-7 grid-rows-1 divide-x divide-grey-300/30">
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full" />
                {/* Reserved space at end of week */}
                <div className="col-start-8 row-span-full w-8" />
              </div>
              {/* Timeslots */}
              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-7 pr-8 pt-6"
                style={{
                  gridTemplateRows: `repeat(${amountTotalMinutesInDay / timeslotUnitSize}, minmax(0, 1fr)) auto`,
                }}
              >
                {/* Indicator of current time */}
                <li
                  className="relative z-10 col-start-1 col-end-2 border-t-2 border-t-aop-bright-purple-500"
                  style={{
                    gridArea: `${Math.round(differenceInMinutes(today, startOfDay(today)) / timeslotUnitSize) + 1} / 1 / span 1 / span ${week.length}`,
                  }}
                />

                {/* Bookable times */}
                {sortedBookableDays.map((currBookabbleDay, i) => {
                  const currBookableTimes = currBookabbleDay.times;
                  const publishAt = assetDetails.publishAt ? new Date(assetDetails.publishAt) : undefined;
                  const availableFrom = assetDetails.availableFrom ? new Date(assetDetails.availableFrom) : undefined;
                  const unpublishAt = assetDetails.unpublishAt ? new Date(assetDetails.unpublishAt) : undefined;
                  const currDate = week[i].date;

                  const isNotPublished = publishAt && publishAt > currDate;
                  const isNotAvailable = availableFrom && availableFrom > currDate;
                  const isUnpublished = unpublishAt && unpublishAt < currDate;

                  // If bookable day is not available
                  if (!currBookableTimes || currBookableTimes.length === 0 || !currBookabbleDay.enabled) {
                    return null;
                  }

                  // If bookable day is before the publish date day
                  if (isNotPublished && !isSameDay(publishAt, currDate)) {
                    return null;
                  }

                  // If bookable day is before the available date day
                  if (isNotAvailable && !isSameDay(availableFrom, currDate)) {
                    return null;
                  }

                  // If bookable day is after the unpublish date day
                  if (isUnpublished && !isSameDay(unpublishAt, currDate)) {
                    return null;
                  }

                  return currBookableTimes.map((currBookableTime) => {
                    // End time can be 00:00:00 of the next day
                    let endTimeDate = currDate;
                    if (isTimeEndOfDay(currBookableTime.endTime)) {
                      endTimeDate = addDays(currDate, 1);
                    }

                    const startTime = getDateFromTimeString(currBookableTime.startTime, currDate);
                    const endTime = getDateFromTimeString(currBookableTime.endTime, endTimeDate);
                    // Always use `currDate` to calculate availability of that day
                    let startMinutes = differenceInMinutes(startTime, startOfDay(currDate));
                    let endMinutes = differenceInMinutes(endTime, startOfDay(currDate));

                    // If the bookable time contains publish time
                    if (publishAt && isSameDay(publishAt, currDate)) {
                      startMinutes = Math.max(differenceInMinutes(publishAt, startOfDay(publishAt)), startMinutes);

                      if (startMinutes >= endMinutes) {
                        return null;
                      }
                    }

                    // If the bookable time contains available time
                    if (availableFrom && isSameDay(availableFrom, currDate)) {
                      startMinutes = Math.max(
                        differenceInMinutes(availableFrom, startOfDay(availableFrom)),
                        startMinutes,
                      );

                      if (startMinutes >= endMinutes) {
                        return null;
                      }
                    }

                    // If the bookable time contains unpublish time
                    if (unpublishAt && isSameDay(unpublishAt, currDate)) {
                      endMinutes = Math.min(differenceInMinutes(unpublishAt, startOfDay(unpublishAt)), endMinutes);

                      if (startMinutes >= endMinutes) {
                        return null;
                      }
                    }

                    return (
                      <li
                        key={`timeslot-${i}-${currBookableTime.startTime}-${currBookableTime.endTime}`}
                        className="bg-white"
                        style={{
                          gridArea: `${Math.round(startMinutes / timeslotUnitSize) + 1} / ${i + 1} / ${endMinutes / timeslotUnitSize + 1}`,
                        }}
                      />
                    );
                  });
                })}

                {/* Bookings */}
                {partitionedBookings.map((currBookings) => {
                  // Pick any one from the bookings to read the relevant information
                  const sampleBooking = currBookings[0];
                  const date = parse(sampleBooking.date, "yyyy-MM-dd", startOfDay(today));
                  const startDate = parse(sampleBooking.startTime, "HH:mm:ss", date);
                  let endDate = parse(sampleBooking.endTime, "HH:mm:ss", date);
                  if (isTimeEndOfDay(sampleBooking.endTime)) {
                    endDate = addSeconds(addDays(endDate, 1), -1);
                  }
                  const daysSinceStartWeek = differenceInDays(date, startOfWeek);
                  const durationInHours = (endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60;
                  const inPast = endDate < today;
                  const startMinutes = Math.round(
                    differenceInMinutes(startDate, startOfDay(startDate)) / timeslotUnitSize,
                  );
                  const endMinutes = Math.round(differenceInMinutes(endDate, startOfDay(endDate)) / timeslotUnitSize);

                  const startTimeLabel =
                    startDate.getHours() + ":" + startDate.getMinutes().toString().padStart(2, "0");
                  const authorLabel = sampleBooking.author?.fullName ?? t("page.bookings.asset-detail.author-fallback");

                  return (
                    <li
                      key={sampleBooking.id}
                      className={twJoin("relative z-20 flex items-center justify-center p-1", getSmColStart(startDate))}
                      style={{
                        gridArea: `${startMinutes + 1} / ${daysSinceStartWeek + 1} / ${endMinutes + 1}`,
                      }}
                    >
                      <button
                        data-testid="booked-slot-time-btn"
                        type="button"
                        onClick={() => onSelectBookings(currBookings)}
                        className={twJoin(
                          "group top-0 flex size-full rounded-lg transition-colors",
                          inPast
                            ? "bg-aop-dark-blue-500/80 hover:bg-aop-dark-blue-500/70"
                            : "bg-aop-dark-blue-500 hover:bg-aop-dark-blue-500/80",
                          durationInHours <= 0.5 ? "gap-1" : "flex-col",
                          durationInHours <= 0.25
                            ? "inset-y-0.5 overflow-y-visible px-2 py-0.5 leading-none"
                            : "inset-y-1 overflow-y-auto p-2 leading-old-tight",
                        )}
                      >
                        <p className="text-left text-overline text-white">
                          <time dateTime={startDate.toISOString()}>{startTimeLabel}</time>
                        </p>
                        <p className="text-left text-overline-bold text-white">
                          <span className={durationInHours <= 1 ? "line-clamp-1" : undefined}>{authorLabel}</span>
                        </p>
                        {currBookings.length > 1 && (
                          <p
                            className={twJoin(
                              "truncate text-left text-overline text-white",
                              durationInHours <= 0.5 ? "ml-auto" : "mt-auto",
                            )}
                          >
                            {t("page.bookings.asset-detail.more-authors", {
                              count: currBookings.length - 1,
                            })}
                          </p>
                        )}
                      </button>
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// No string interpolation because tailwind would not find the classes
function getSmColStart(day: Date) {
  switch (dayOfWeekIndex(day)) {
    case 0:
      return "sm:col-start-1";
    case 1:
      return "sm:col-start-2";
    case 2:
      return "sm:col-start-3";
    case 3:
      return "sm:col-start-4";
    case 4:
      return "sm:col-start-5";
    case 5:
      return "sm:col-start-6";
    case 6:
      return "sm:col-start-7";
    default:
      throw new Error(`Day of week index is incorrect`);
  }
}
