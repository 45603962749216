import type { MessageStatusChangeRequestV2 } from "api/types";
import { Blockquote } from "components/Blockquote/Blockquote";
import { Button } from "components/Button/Button";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { communityFeedMutations } from "queries/communityFeed";
import type React from "react";
import { useTranslation } from "react-i18next";

import { getClosePostReasonLabel } from "./helpers";

type CommunityPostClosePostConfirmModalProps = ModalBaseProps & {
  messageId: string;
  reason: MessageStatusChangeRequestV2["reason"];
  canArchive: boolean;
  canDelete: boolean;
};

export function CommunityPostClosePostConfirmModal({
  messageId,
  reason,
  canArchive,
  canDelete,
  isOpened,
  onOpenChange,
}: CommunityPostClosePostConfirmModalProps): React.ReactNode {
  const { t } = useTranslation();

  const updateMessageStatus = communityFeedMutations.useUpdateMessageStatus();

  const remark = canArchive
    ? t("component.community-post.close-post-confirm-modal.archive.remark")
    : t("component.community-post.close-post-confirm-modal.delete.remark");

  const handleDelete = async () => {
    await updateMessageStatus.mutateAsync({
      messageId,
      data: {
        newStatus: "deleted",
        reason,
      },
    });

    onOpenChange(false);
  };

  const handleArchive = async () => {
    await updateMessageStatus.mutateAsync({
      messageId,
      data: {
        newStatus: "archived",
        reason,
      },
    });

    onOpenChange(false);
  };

  return (
    <Modal.Root
      title={t("component.community-post.close-post-confirm-modal.title")}
      size="sm"
      {...{ isOpened, onOpenChange }}
    >
      <div className="flex flex-col py-2">
        <h4 className="text-body">{t("component.community-post.close-post-confirm-modal.body")}:</h4>
        <Blockquote> {getClosePostReasonLabel(reason, t)}</Blockquote>{" "}
        <p className="text-caption text-grey-700">{remark}</p>
      </div>
      <Modal.Actions>
        {canDelete && (
          <Button
            data-testid="confirm-deletion-btn"
            styling={canArchive ? "tertiary" : "primary"}
            onClick={handleDelete}
            isLoading={updateMessageStatus.isPending}
          >
            {t("component.community-post.close-post-confirm-modal.btn.delete-post")}
          </Button>
        )}
        {canArchive && (
          <Button
            data-testid="confirm-archiving-btn"
            styling="primary"
            onClick={handleArchive}
            isLoading={updateMessageStatus.isPending}
          >
            {t("component.community-post.close-post-confirm-modal.btn.archive-post")}
          </Button>
        )}
      </Modal.Actions>
    </Modal.Root>
  );
}
