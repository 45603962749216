import { useQuery } from "@tanstack/react-query";
import type { ProjectOverviewItemDto, ProjectStatsDto } from "api/types";
import iconStar01 from "assets/icons/star-01.svg";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Select } from "components/Select/Select";
import { ToggleButtonGroup } from "components/ToggleButtonGroup/ToggleButtonGroup";
import { format } from "date-fns";
import { orderBy } from "lodash-es";
import { usePortfolioQueries } from "queries/portfolio/queryOptions";
import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { PortfolioOverviewChart } from "./PortfolioOverviewChart";

interface Props {
  items: ProjectOverviewItemDto[];
  date: Date;
  showEngagement: boolean;
}

export const GraphView = memo(function GraphView({ items, date, showEngagement }: Props): React.ReactNode {
  const { t } = useTranslation();
  const [view, setView] = useState<"feelAtHome" | "rating" | "adoption" | "engagement">("feelAtHome");
  const [sort, setSort] = useState<"a-z" | "low-high" | "high-low">("a-z");

  const portfolioQueries = usePortfolioQueries();
  const { data: benchmark } = useQuery(portfolioQueries.benchmarkStates({ date: format(date, "yyyy-MM-dd") }));

  const chartData = useMemo(() => {
    const statAccessor = (stats: ProjectStatsDto | undefined) => {
      switch (view) {
        case "adoption":
          return stats?.adoptionPercentage ?? -1;
        case "engagement":
          return stats?.engagedResidentPercentage ?? -1;
        case "rating":
          return stats?.averageTicketRating ?? -1;
        case "feelAtHome":
          return stats?.feelingAtHomeAverageRatingAllTime ?? -1;
        default:
          return -1;
      }
    };

    const orderedItems = orderBy(
      items, // eslint-disable-next-line no-nested-ternary
      sort === "a-z"
        ? (a) => a.name
        : // eslint-disable-next-line no-nested-ternary
          sort === "high-low"
          ? (x) => -statAccessor(x.stats)
          : (x) => statAccessor(x.stats),
    );

    return {
      items: orderedItems.map((x) => ({
        x: x.name,
        y: statAccessor(x.stats) ?? 0,
      })),
      benchmark: orderedItems.map((x) => ({ x: x.name, y: statAccessor(benchmark) ?? 0 })),
    };
  }, [benchmark, items, sort, view]);

  const options = [
    { label: t("page.portfolio.table.header.fah-rating"), value: "feelAtHome" },
    { label: t("page.portfolio.table.header.avg-rating"), value: "rating" },
    { label: t("page.portfolio.table.header.adoption"), value: "adoption" },
    ...(showEngagement ? ([{ label: t("page.portfolio.table.header.engagement"), value: "engagement" }] as const) : []),
  ] as const;

  return (
    <div className="hidden flex-col gap-4 md:flex">
      <div className="flex items-center gap-5">
        <h2 className="mr-auto text-body-bold">{t("page.portfolio.analytics.title")}</h2>
      </div>
      <div className="flex flex-col gap-2 rounded bg-white px-5 py-4">
        <span className="text-caption-bold">
          {
            // eslint-disable-next-line no-nested-ternary
            view === "adoption"
              ? t("page.portfolio.analytics.graph-title.adoption")
              : // eslint-disable-next-line no-nested-ternary
                view === "engagement"
                ? t("page.portfolio.analytics.graph-title.engagement")
                : view === "rating"
                  ? t("page.portfolio.analytics.graph-title.avg-rating")
                  : t("page.portfolio.analytics.graph-title.fah-rating")
          }
        </span>
        <div className="flex flex-col flex-wrap justify-between gap-y-2 md:flex-row md:items-center">
          <div className="flex gap-1">
            <span className="text-caption">{t("page.portfolio.analytics.graph-subtitle")}</span>
            <span className="text-caption">
              <FormattedDate date={date} format="monthYear" />
            </span>
          </div>
          <div className="flex flex-wrap gap-2">
            <ToggleButtonGroup
              options={options}
              selected={options.find((x) => x.value === view)!}
              onChange={(option) => setView(option.value)}
            />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-caption">{t("page.portfolio.analytics.sort")}</span>
            <Select
              items={["a-z", "high-low", "low-high"] as const}
              keySelector={(x) => x}
              renderOption={(x) =>
                // eslint-disable-next-line no-nested-ternary
                x === "a-z"
                  ? t("page.portfolio.analytics.sort.type.a-z")
                  : x === "high-low"
                    ? t("page.portfolio.analytics.sort.type.high-low")
                    : t("page.portfolio.analytics.sort.type.low-high")
              }
              selected={sort}
              onChange={setSort}
            />
          </div>
        </div>
        <div className="relative pl-8">
          <div className="absolute inset-y-0 -left-0 my-auto size-0 -rotate-90">
            <p className="flex items-center justify-center whitespace-nowrap text-caption">
              {view === "feelAtHome" ? <span>{t("page.portfolio.table.header.fah-rating")} %</span> : null}
              {view === "engagement" ? <span>{t("page.portfolio.table.header.engagement")} %</span> : null}
              {view === "adoption" ? <span>{t("page.portfolio.table.header.adoption")} %</span> : null}
              {view === "rating" ? (
                <span className="flex items-center gap-2">
                  <span>{t("page.portfolio.table.header.avg-rating")}</span>
                  <Icon name={iconStar01} className="fill-current stroke-transparent" />
                </span>
              ) : null}
            </p>
          </div>
          <PortfolioOverviewChart
            view={view}
            month={date}
            type="text"
            primaryData={chartData.items}
            benchmarkData={view === "rating" || view === "feelAtHome" ? chartData.benchmark : []}
            benchmarkLabel={t("page.portfolio.analytics.benchmark")}
            formatYTick={(x) => x}
            maxY={view === "rating" || view === "feelAtHome" ? 10 : 100}
            arrowHoverText={t("page.portfolio.analytics.graph-arrow-hover")}
          />
        </div>
        <p className="mt-2 text-center text-caption">{t("page.portfolio.analytics.bottom-title")}</p>
      </div>
      <div className="h-32" />
    </div>
  );
});
