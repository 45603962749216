import { useMutation, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import iconX from "assets/icons/x.svg";
import { Button } from "components/Button/Button";
import { FileUploadButton } from "components/Button/FileUploadButton";
import { IconButton } from "components/Button/IconButton";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { formatAddress } from "helpers/address";
import { parseFileContent } from "helpers/file-parse";
import { isDefined } from "helpers/util";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useOnDropFiles } from "hooks/useOnDropFiles";
import { useOnPaste } from "hooks/useOnPaste";
import { useAddressQueries } from "queries/addresses/queryOptions";
import { useCompanyQueries } from "queries/companies/queryOptions";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { DataTable } from "../components/DataTable";
import type { Column, Data } from "../helpers/parser";
import { parseData } from "../helpers/parser";
import { downloadExcel, parseExcel } from "../helpers/sheet";
import { validate } from "../helpers/validation";

export function CompanyBulkUpload(): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const api = useApi();
  const [isUploading, setIsUploading] = useState(false);
  const [isParsingFile, setIsParsingFile] = useState(false);
  const [data, setData] = useState<Data<NonNullable<typeof columns>>>();
  const { mutateAsync: uploadCompanyAsync } = useMutation({ mutationFn: api.postCompaniesV1 });
  const addressQueries = useAddressQueries();
  const {
    data: addresses,
    isLoading: isLoadingAddresses,
    error: addressesError,
  } = useQuery(addressQueries.getAddresses());
  const companyQueries = useCompanyQueries();
  const {
    data: companies,
    isLoading: isLoadingCompanies,
    error: companiesError,
  } = useQuery({
    ...companyQueries.getCompanies(),
    enabled: sessionUser.project.type === "companyBased",
  });

  const columns = useMemo(() => {
    if (!isDefined(addresses) || !isDefined(companies)) {
      return undefined;
    }

    return [
      {
        name: "Address",
        alias: ["adres", "streetname", "straatnaam", "straat", "street"],
        rules: {
          required: t("page.company-bulk-upload.validation.address.required"),
          oneOf: {
            error: t("page.company-bulk-upload.validation.address.unknown"),
            values: addresses.items.map(formatAddress),
          },
        },
      },
      {
        name: "Company",
        alias: ["bedrijf", "naam", "name"],
        rules: {
          required: t("page.company-bulk-upload.validation.name.required"),
          notOneOf: {
            error: t("page.company-bulk-upload.validation.name.exists"),
            values: companies.items.map((x) => x.name),
          },
        },
      },
      {
        name: "Description",
        alias: ["content", "details", "beschrijving"],
      },
    ] as const satisfies readonly Column[];
  }, [t, addresses, companies]);

  async function upload() {
    if (!data) {
      throw new Error("Data missing");
    }

    if (!dataState?.valid) {
      throw new Error("Data is invalid");
    }

    try {
      setIsUploading(true);

      for (const row of data) {
        const idx = data.indexOf(row);
        updateValue(idx, "uploadStatus", "UPLOADING");
        const rowData = row as { [k in NonNullable<typeof columns>[number]["name"]]: string };
        try {
          const addressId = mapAddressValue(rowData["Address"]);
          if (!addressId) {
            throw new Error("Address not found");
          }

          await uploadCompanyAsync({
            addressId,
            name: rowData["Company"],
            description: rowData["Description"],
          });
          updateValue(idx, "uploadStatus", "SUCCESS");
        } catch (e) {
          updateValue(idx, "uploadStatus", "ERROR");
        }
      }
    } finally {
      setIsUploading(false);
    }
  }

  function mapAddressValue(addressName: string) {
    if (!addresses) {
      throw new Error("No addresses available yet");
    }

    for (const address of addresses.items) {
      if (addressName === formatAddress(address)) {
        return address.id;
      }
    }
  }

  function updateValue(row: number, column: string, value: string) {
    setData((oldData) => {
      if (!oldData) {
        throw new Error("No existing data");
      }

      const newData = [...oldData];
      const rowNumber = Number(row);
      const oldRow = oldData[rowNumber];
      const newRow = { ...oldRow, [column]: value };
      newData.splice(rowNumber, 1, newRow);

      return newData;
    });
  }

  function removeRow(row: number) {
    setData((oldData) => {
      if (!oldData) {
        return;
      }

      const newData = [...oldData];
      newData.splice(row, 1);

      // No more data remaining, reset form
      if (newData.length === 0) {
        return undefined;
      }

      return newData;
    });
  }

  const loadCsv = useCallback(
    async (...args: Parameters<typeof parseExcel>) => {
      try {
        setIsParsingFile(true);
        const rows = await parseExcel(...args);
        setData(parseData(rows as object[], columns!));
      } finally {
        setIsParsingFile(false);
      }
    },
    [columns],
  );

  useOnDropFiles(
    useCallback(
      async (files) => {
        const file = files?.[0].getAsFile();
        if (file) {
          try {
            const content = await parseFileContent(file);
            await loadCsv(content);
          } catch (e) {
            alert(e);
          }
        }
      },
      [loadCsv],
    ),
    !data && !isParsingFile,
  );

  useOnPaste(
    useCallback(
      (file) => {
        void loadCsv(file).catch((e) => alert(`Could not paste data: ${e}`));
      },
      [loadCsv],
    ),
    !data && !isParsingFile,
  );

  const onUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const file = target.files?.[0];

    if (file) {
      try {
        const contents = await parseFileContent(file);
        await loadCsv(contents);
      } catch (e) {
        alert(e);
        target.value = "";
      }
    }
  };

  const dataState = useMemo(() => (data && columns ? validate(columns, data) : undefined), [data, columns]);

  if (sessionUser.project.type === "addressBased") {
    return <ErrorPage error={t("page.company-bulk-upload.error.not-available")} />;
  }

  const isLoading = isLoadingAddresses || isLoadingCompanies;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  const error = addressesError || companiesError;
  if (!columns || error) {
    return <ErrorPage error={error ?? t("page.company-bulk-upload.error.no-columns")} />;
  }

  return (
    <DocumentPaper
      title={t("page.company-bulk-upload.title")}
      theme="minimal"
      actions={
        data ? (
          <IconButton
            disabled={isUploading}
            title={t("page.company-bulk-upload.reset")}
            onClick={() => setData(undefined)}
            icon={iconX}
          />
        ) : (
          <FileUploadButton
            disabled={isUploading}
            id="company_list_btn"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={onUploadFile}
          >
            {t("page.company-bulk-upload.button")}
          </FileUploadButton>
        )
      }
    >
      <div className="flex flex-col gap-4 rounded-lg bg-white p-5">
        {
          // eslint-disable-next-line no-nested-ternary
          data ? (
            <div className="flex flex-col gap-8">
              <DataTable
                data={data}
                columns={columns}
                dataState={dataState!}
                onRemoveRow={removeRow}
                onUpdateValue={updateValue}
                disabled={isUploading}
                data-testid="company-data-table"
              />
              <Button onClick={upload} disabled={!dataState?.valid || data.every((x) => x.uploadStatus === "SUCCESS")}>
                {t("page.company-bulk-upload.submit")}
              </Button>
            </div>
          ) : isParsingFile ? (
            <FullSizeLoader withPadding />
          ) : (
            <>
              <p className="max-w-prose whitespace-pre-wrap">{t("page.company-bulk-upload.prose")}</p>
              <Button
                styling="secondary"
                onClick={() =>
                  downloadExcel(`company-upload-${sessionUser.project.name}`, [
                    columns.map((x) => x.name),
                    ...(addresses?.items.map((x) => [formatAddress(x)]) || []),
                  ])
                }
              >
                {t("page.company-bulk-upload.example-file")}
              </Button>
            </>
          )
        }
      </div>
    </DocumentPaper>
  );
}
