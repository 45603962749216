import { useQuery } from "@tanstack/react-query";
import type { CommentDto, MessageReactionDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import iconCornerDownRight from "assets/icons/corner-down-right.svg";
import { Button } from "components/Button/Button";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { formatDate, FormattedDate } from "components/FormattedDate/FormattedDate";
import { Gallery } from "components/Gallery/Gallery";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Tooltip } from "components/Tooltip/Tooltip";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserDeletedTag } from "components/UserDeletedTag/UserDeletedTag";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { VideoFilePreview } from "components/VideoFilePreview/VideoFilePreview";
import { type FormVideo, isVideoUploaded, useVideoInput } from "components/VideoInput/useVideoInput";
import { formatDistance } from "helpers/date";
import { twResolve } from "helpers/tw-resolve";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { useBool } from "hooks/useBool";
import { motion } from "motion/react";
import { communityFeedMutations, useCommunityFeedQueries } from "queries/communityFeed";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { CommunityPostReactionCountButton } from "./CommunityPost/CommunityPostActivityButtons";
import { CommunityPostReactionButton } from "./CommunityPost/CommunityPostReactionButton";
import { getTotalReactionAmount } from "./CommunityPost/helpers";

export interface CreateCommentPayload {
  parentId?: string;
  comment: string;
  images: FormImage[];
  videos: FormVideo[];
  failureMessage: string;
}

export interface EditCommentPayload {
  commentId: string;
  comment: string;
  images: FormImage[];
  videos: FormVideo[];
  failureMessage: string;
}

interface Props {
  messageId: string;
  comment: CommentDto;
  onEdit: (comment: CommentDto) => void;
  onDelete: (comment: CommentDto) => void;
  onAddComment: (payload: CreateCommentPayload) => Promise<void>;
  onEditComment: (payload: EditCommentPayload) => Promise<void>;
  onViewCommentReactions: (commentId: string) => void;
}

export function Comment({
  messageId,
  comment,
  onAddComment,
  onEdit,
  onEditComment,
  onDelete,
  onViewCommentReactions,
}: Props): React.ReactNode {
  const [hideAllReplies, hideAllRepliesHandler] = useBool(true);
  const [showReplyField, showReplyFieldHandler] = useBool(false);

  const [replyImages, setReplyImages] = useState<FormImage[]>([]);
  const [replyVideos, setReplyVideos] = useState<FormVideo[]>([]);
  const [editingReply, setEditingReply] = useState<string | undefined>(undefined);

  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const sessionUser = useSessionUser();
  const {
    addImages: addReplyImages,
    removeImage: removeReplyImage,
    removeImages: removeReplyImages,
  } = useImageInput({ selectedImages: replyImages, onChange: setReplyImages });
  const { uploadFormVideo } = useUploadVideo({
    onProgress: ({ name, progress }) => {
      setReplyVideos((prevState) =>
        prevState.map((video) => {
          if (!isVideoUploaded(video) && video.file.name === name) {
            return { ...video, uploadProgress: progress };
          } else {
            return video;
          }
        }),
      );
    },
  });
  const {
    addVideos: addReplyVideos,
    removeVideo: removeReplyVideo,
    removeVideos: removeReplyVideos,
  } = useVideoInput({ selectedVideos: replyVideos, onChange: setReplyVideos, uploadFn: uploadFormVideo });

  const { getValues, setValue, watch, register } = useForm<{ replyValue: string }>();

  const communityFeedQueries = useCommunityFeedQueries();
  const {
    data: commentDetails,
    isFetching: isFetchingReplies,
    error: errorReplies,
  } = useQuery({
    ...communityFeedQueries.commentDetails({ messageId, commentId: comment.id }),
    enabled: !hideAllReplies,
  });

  useEffect(() => {
    register("replyValue");
  }, [register]);

  useEffect(() => {
    if (errorReplies) {
      showFlashToast({ type: "error", title: t("component.community-post.comments.reply.show-error") });
    }
  }, [errorReplies, showFlashToast, t]);

  const replyValue = watch("replyValue");

  async function onAddNestedComment() {
    const { replyValue } = getValues();

    if (editingReply) {
      await onEditComment({
        comment: replyValue,
        commentId: editingReply,
        images: replyImages,
        videos: replyVideos,
        failureMessage: t("component.community-post.comments.edit-error"),
      });
      setEditingReply(undefined);
    } else {
      await onAddComment({
        parentId: comment.id,
        comment: replyValue,
        images: replyImages,
        videos: replyVideos,
        failureMessage: t("component.community-post.comments.error"),
      });
    }

    removeReplyImages();
    removeReplyVideos();
    setValue("replyValue", "");
    showReplyFieldHandler.setFalse();
  }

  const handleEditComment = useCallback(
    (comment: CommentDto) => {
      setValue("replyValue", comment.content || "");
      if (comment.image) {
        setReplyImages([comment.image]);
      }

      if (comment.videos && comment.videos.length > 0) {
        setReplyVideos(comment.videos);
      }

      setEditingReply(comment.id);
      showReplyFieldHandler.setTrue();
    },
    [setValue, setReplyImages, setEditingReply, showReplyFieldHandler],
  );

  const handleCancelEdit = useCallback(() => {
    setValue("replyValue", "");
    removeReplyImages();
    setEditingReply(undefined);
    showReplyFieldHandler.setFalse();
  }, [removeReplyImages, setValue, setEditingReply, showReplyFieldHandler]);

  const onChangeReplyContent = useCallback((value: string) => setValue("replyValue", value), [setValue]);

  const handleRemoveCommentImage = useCallback(
    (imageToBeRemoved: FormImage) => {
      removeReplyImage(imageToBeRemoved);
    },
    [removeReplyImage],
  );

  return (
    <div className="flex flex-col gap-4">
      <CommentInternal
        messageId={messageId}
        comment={comment}
        showReplyButton={comment.canReply}
        onAddNestedComment={showReplyFieldHandler.toggle}
        onEdit={onEdit}
        onDelete={onDelete}
        onViewCommentReactions={onViewCommentReactions}
      />
      <div className="ml-10" data-testid="comment-replies-section">
        {hideAllReplies && comment.latestReply ? (
          <>
            {comment.totalRepliesCount > 1 && (
              <Button
                styling="tertiary"
                className="mb-2 self-start text-aop-basic-blue-500 no-underline"
                onClick={hideAllRepliesHandler.setFalse}
              >
                <span>
                  {t("component.community-post.comments.replies.show-replies", {
                    count: comment.totalRepliesCount - 1,
                  })}
                </span>
              </Button>
            )}
            <CommentInternal
              messageId={messageId}
              comment={comment.latestReply}
              onEdit={handleEditComment}
              onDelete={onDelete}
              onViewCommentReactions={onViewCommentReactions}
            />
          </>
        ) : (
          <>
            {isFetchingReplies && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}>
                <LoadingIcon className="mx-auto mb-2 w-6" />
              </motion.div>
            )}
            {commentDetails &&
              (commentDetails.replies || [commentDetails.latestReply]).map((reply) => (
                <CommentInternal
                  key={reply.id}
                  messageId={messageId}
                  comment={reply}
                  onEdit={handleEditComment}
                  onDelete={onDelete}
                  onViewCommentReactions={onViewCommentReactions}
                />
              ))}
            {!hideAllReplies && commentDetails?.replies && (
              <Button
                styling="tertiary"
                className="mb-4 self-start text-aop-basic-blue-500 no-underline"
                onClick={hideAllRepliesHandler.setTrue}
              >
                {t("component.community-post.comments.replies.hide-replies", {
                  count: comment.totalRepliesCount - 1,
                })}
              </Button>
            )}
          </>
        )}
        {showReplyField && (
          <form className="mb-6">
            <div className="flex items-end">
              <CommentFieldWithAvatar
                isReply
                tooltips={{
                  send: editingReply
                    ? t("component.community-post.comments.edit.tooltip")
                    : t("component.community-post.comments.reply.tooltip"),
                }}
                placeholder={
                  editingReply
                    ? t("component.community-post.comments.edit.placeholder")
                    : t("component.community-post.comments.reply.placeholder")
                }
                user={sessionUser}
                value={replyValue}
                onChange={onChangeReplyContent}
                images={replyImages}
                videos={replyVideos}
                onSubmit={onAddNestedComment}
                isEdit={editingReply !== undefined}
                onCancel={handleCancelEdit}
                allowedAttachments={["image", "video"]}
                onAddImages={addReplyImages}
                onRemoveImage={handleRemoveCommentImage}
                onAddVideo={addReplyVideos}
                onRemoveVideo={removeReplyVideo}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

interface InternalProps {
  messageId: string;
  comment: CommentDto;
  showReplyButton?: boolean;
  onAddNestedComment?: () => void;
  onEdit: (comment: CommentDto) => void;
  onDelete: (comment: CommentDto) => void;
  onViewCommentReactions: (commentId: string) => void;
}

function CommentInternal({
  messageId,
  comment,
  showReplyButton,
  onAddNestedComment,
  onEdit,
  onDelete,
  onViewCommentReactions,
}: InternalProps): React.ReactNode {
  const [isTranslated, translationHandler] = useBool(false);

  const { i18n, t } = useTranslation();
  const sessionUser = useSessionUser();

  const communityFeedQueries = useCommunityFeedQueries();
  const { data: translation, isLoading: isLoadingTranslation } = useQuery({
    ...communityFeedQueries.commentTranslation({
      messageId,
      commentId: comment.id,
      languageId: sessionUser.language.id,
    }),
    enabled: isTranslated,
  });
  const updateReactionMutation = communityFeedMutations.useUpdateCommentReaction();

  const handleNewReaction = (reactionType: MessageReactionDto["reactionType"] | undefined) => {
    void updateReactionMutation.mutateAsync({
      messageId: messageId,
      commentId: comment.id,
      data: {
        reactionType,
      },
    });
  };

  const hasTranslation = translation && isTranslated;
  let content = comment.content;
  if (comment.deletedAt && !comment.content) {
    content = t("component.community-post.comments.deleted");
  } else if (hasTranslation) {
    content = translation.data?.content;
  }

  return (
    <div className={twJoin("flex gap-2 rounded-sm", comment.deletedAt && "text-grey-400")} data-testid="comment-root">
      <div className={twJoin("size-8", comment.deletedAt && "opacity-40")}>
        <UserAvatarLink user={comment.author} hideDeletedIcon />
      </div>
      <div className="flex flex-1 flex-col gap-2">
        {/* Comment body */}
        <div
          className={twResolve(
            "flex flex-col gap-1 rounded-lg bg-grey-50 px-3 py-2",
            comment.isSolution && "bg-aop-chat-green-100",
          )}
        >
          <div className={twJoin("flex w-full items-start", comment.deletedAt && "text-grey-400")}>
            <div className="flex w-full flex-wrap items-center justify-between">
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-1">
                  <UserNameLink user={comment.author}>
                    <span
                      className={twJoin("truncate text-body-bold", comment.deletedAt ? "text-grey-400" : "text-black")}
                    >
                      {comment.author.fullName}
                    </span>
                  </UserNameLink>
                  {!!comment.author.deletedAt && <UserDeletedTag />}
                </div>
                <span className="text-caption text-grey-500">
                  {t("component.community-post.comments.posted-at", {
                    dateTime: formatDistance(t, { start: new Date(comment.postedAt) }),
                  })}
                </span>
              </div>

              <div className="flex items-center gap-2">
                {comment.deletedAt && (
                  <Tooltip tooltip={<FormattedDate date={comment.deletedAt} format="datetime" />}>
                    <span className="relative ml-1 cursor-default text-caption italic text-grey-500 after:absolute after:inset-x-0 after:bottom-0 after:hidden after:h-px after:w-full after:bg-grey-500 after:content-[''] hover:after:block">
                      (
                      {t("component.community-post.comments.deleted-at", {
                        time: formatDate(i18n, "datetime", comment.deletedAt),
                      })}
                      )
                    </span>
                  </Tooltip>
                )}
                {!comment.deletedAt && comment.updatedAt && (
                  <span className="text-caption text-grey-500">
                    {t("component.community-post.comments.last-edited-at", {
                      dateTime: formatDate(i18n, "datetime", comment.updatedAt),
                    })}
                  </span>
                )}
                {comment.isSolution && (
                  <span className="rounded-md bg-white p-1">
                    <Icon data-testid="solution-comment-icon" name={iconCheck} className="text-green-600" />
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* Comment content */}
          {content && (
            <div className="flex flex-col gap-2">
              <p
                className={twJoin("flex-1", comment.deletedAt && comment.content ? "line-through" : undefined)}
                data-testid="comment-content"
              >
                <LinkFormatter>{content}</LinkFormatter>
              </p>
              {comment.content && comment.languageIsoCode !== sessionUser.language.id && !comment.deletedAt && (
                <Button styling="ghostPrimary" isLoading={isLoadingTranslation} onClick={translationHandler.toggle}>
                  {hasTranslation
                    ? t("component.community-post.comments.translate.original")
                    : t("component.community-post.comments.translate")}
                </Button>
              )}
            </div>
          )}
          {comment.image && (
            <div className="max-w-full pt-2" data-testid="comment-attachment-image">
              <Gallery images={[comment.image]} isDeleted={!!comment.deletedAt} />
            </div>
          )}
          {comment.videos && comment.videos.length > 0 && (
            <div className="max-w-full pt-2" data-testid="comment-attachment-video">
              <VideoFilePreview video={comment.videos[0]} />
            </div>
          )}
        </div>
        {/* Comment reactions & activities */}
        <div className="flex flex-wrap items-center gap-4 text-grey-500">
          {comment.canLike && (
            <CommunityPostReactionButton
              size="sm"
              reaction={comment.requesterReactionType}
              onClickReaction={handleNewReaction}
            />
          )}
          {getTotalReactionAmount(comment.totalReactionCountByType) > 0 && (
            <CommunityPostReactionCountButton
              size="sm"
              counts={comment.totalReactionCountByType}
              isActive={false}
              isDisabled={false}
              onClick={() => onViewCommentReactions(comment.id)}
            />
          )}
          {showReplyButton && (
            <Button
              styling="ghostSecondary"
              size="sm"
              title={t("component.community-post.comments.reply")}
              data-testid="reply-comment"
              onClick={onAddNestedComment}
              icon={<Icon name={iconCornerDownRight} />}
            >
              {comment.totalRepliesCount ? comment.totalRepliesCount : null}
            </Button>
          )}
          {comment.canEdit && (
            <Button size="sm" styling="ghostSecondary" data-testid="edit-comment" onClick={() => onEdit(comment)}>
              {t("common.action.edit")}
            </Button>
          )}
          {comment.canDelete && (
            <Button size="sm" styling="ghostSecondary" data-testid="delete-comment" onClick={() => onDelete(comment)}>
              {t("common.action.delete")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
