import type { InfiniteData } from "@tanstack/react-query";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import type {
  CommentCreateRequest,
  CommentDto,
  CommentDtoPaginationResultDto,
  CommentUpdateRequest,
  MessageReactionDto,
  MessageV2Dto,
} from "api/types";
import { formatDistance } from "helpers/date";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { useSignalRHub, useSignalRSubscription } from "hooks/useSignalR";
import { communityFeedMutations, useCommunityFeedQueries } from "queries/communityFeed";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";
import type { ApiResponseType } from "types/api-types";

import type { CreateCommentPayload, EditCommentPayload } from "../Comment";
import { CommentList } from "../CommentList";
import { CommentReactionListModal } from "../CommentReactionListModal";
import { ReactionList } from "../ReactionList";
import { CommunityPostCommentButton, CommunityPostReactionCountButton } from "./CommunityPostActivityButtons";
import { CommunityPostReactionButton } from "./CommunityPostReactionButton";
import { getTotalReactionAmount } from "./helpers";

export enum Tabs {
  REACTIONS,
  COMMENTS,
}

interface CommunityPostActivitySectionProps {
  message: MessageV2Dto;
  isExpanded?: boolean;
}

export function CommunityPostActivitySection({
  message,
  isExpanded,
}: CommunityPostActivitySectionProps): React.ReactNode {
  const [activeTab, setActiveTab] = useState<Tabs | undefined>(isExpanded ? Tabs.COMMENTS : undefined);
  const [viewCommentReactionsState, setViewCommentReactionsState] = useState<{
    open: boolean;
    commentId?: string;
    likeCount?: number;
  }>({
    open: false,
  });

  const sessionUser = useSessionUser();
  const { t } = useTranslation();
  const projectId = useProjectId();
  const queryClient = useQueryClient();

  const communityFeedQueries = useCommunityFeedQueries();

  const { refetch: refetchComments, isFetched: isFetcheComments } = useInfiniteQuery({
    ...communityFeedQueries.commentsInfinite(message.id),
    enabled: activeTab === Tabs.COMMENTS,
  });

  const addCommentMutation = communityFeedMutations.useAddComment();
  const deleteCommentMutation = communityFeedMutations.useDeleteComment();
  const updateCommentMutation = communityFeedMutations.useUpdateComment();
  const updateMessageReactionMutation = communityFeedMutations.useUpdateMessageReaction();

  const { signalRConnection } = useSignalRHub("community-feed-detail-hub", {
    query: `userId=${sessionUser.id}&entityId=${message.id}&entityType=Message`,
    disabled: activeTab !== Tabs.COMMENTS,
  });
  const onNewLikesOrCommentsOnPost = useCallback(
    (
      ...args: [
        {
          entityId: string;
          entityType: "message" | "comment" | "poll" | "survey";
          likeTotal: number;
          commentTotal: number;
          authorId: string;
        },
      ]
    ) => {
      if (!isExpanded || args[0].authorId === sessionUser.id) {
        return;
      }

      void queryClient.setQueryData<ApiResponseType<"getMessagesDetailsV2"> | undefined>(
        QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id),
        (oldData) => {
          if (!oldData) {
            return;
          }

          return {
            ...oldData,
            totalLikeCount: args[0].likeTotal,
            totalCommentCount: args[0].commentTotal,
          };
        },
      );
    },
    [isExpanded, sessionUser, queryClient, message.id, projectId],
  );
  const onNewCommentLike = useCallback(
    (...args: [{ entityId: string; likeTotal: number; authorId: string }]) => {
      if (!isExpanded || args[0].authorId === sessionUser.id) {
        return;
      }

      void queryClient.setQueryData<InfiniteData<CommentDtoPaginationResultDto> | undefined>(
        QUERY_KEYS.MESSAGES_COMMENTS(projectId, message.id),
        (oldData) => {
          if (!oldData) {
            return;
          }

          return {
            ...oldData,
            pages: [
              ...oldData.pages.map((page) => {
                const newItems = page.items.map((item: CommentDto) =>
                  item.id === args[0].entityId ? { ...item, totalLikesCount: args[0].likeTotal } : item,
                );

                return {
                  ...page,
                  items: newItems,
                };
              }),
            ],
          };
        },
      );
    },
    [isExpanded, sessionUser, queryClient, message.id, projectId],
  );

  useSignalRSubscription(signalRConnection, "UpdateCommentLikeCount", onNewCommentLike);
  useSignalRSubscription(signalRConnection, "UpdateMessageLikeAndCommentsCount", onNewLikesOrCommentsOnPost);

  const handleChangeTab = (selectedTab: Tabs) => {
    switch (selectedTab) {
      case activeTab:
        setActiveTab(undefined);
        break;
      case Tabs.REACTIONS:
        setActiveTab(Tabs.REACTIONS);
        break;
      case Tabs.COMMENTS:
        setActiveTab((tab) => (tab === Tabs.COMMENTS ? undefined : Tabs.COMMENTS));
        break;
      default:
        setActiveTab(undefined);
        break;
    }
  };

  const handleNewReaction = (reactionType: MessageReactionDto["reactionType"] | undefined) => {
    void updateMessageReactionMutation.mutateAsync({
      messageId: message.id,
      data: {
        reactionType,
      },
    });
  };

  if (isExpanded && !isFetcheComments) {
    void refetchComments();
  }

  const { uploadFormImage } = useUploadImage();
  const { uploadFormVideo } = useUploadVideo({});
  const handleAddComment = async ({ comment, images, videos, failureMessage, parentId }: CreateCommentPayload) => {
    const payload: CommentCreateRequest = {
      content: comment,
      parentId,
      videoIds: [],
    };

    if (images.length > 0) {
      const uploadedImage = await uploadFormImage(images[0]);

      payload.imageId = uploadedImage?.id;
    }

    const uploadedVideoIds: string[] = [];
    if (videos.length > 0) {
      for (const video of videos) {
        const uploadedVideo = await uploadFormVideo(video);

        if (uploadedVideo) {
          uploadedVideoIds.push(uploadedVideo.id);
        }
      }

      payload.videoIds = uploadedVideoIds;
    }

    await addCommentMutation.mutateAsync({
      messageId: message.id,
      payload,
      failureMessage,
    });
  };

  const handleDeleteComment = (commentId: string) => {
    return deleteCommentMutation.mutateAsync({
      messageId: message.id,
      commentId,
    });
  };

  const handleEditPost = async ({ comment, commentId, images, videos, failureMessage }: EditCommentPayload) => {
    const payload: CommentUpdateRequest = {
      content: comment,
    };

    if (images.length > 0) {
      const uploadedImage = await uploadFormImage(images[0]);

      payload.imageId = uploadedImage?.id;
    }

    const uploadedVideoIds: string[] = [];
    if (videos.length > 0) {
      for (const video of videos) {
        const uploadedVideo = await uploadFormVideo(video);

        if (uploadedVideo) {
          uploadedVideoIds.push(uploadedVideo.id);
        }
      }

      payload.videoIds = uploadedVideoIds;
    }

    return await updateCommentMutation.mutateAsync({
      messageId: message.id,
      payload,
      commentId,
      failureMessage,
    });
  };

  return (
    <>
      <div
        className={twJoin(
          "flex flex-col-reverse items-center justify-between gap-4 lg:flex-row",
          activeTab !== undefined && "border-b border-b-grey-300 pb-1",
        )}
      >
        <div className="flex items-center gap-2">
          <CommunityPostReactionButton
            size="md"
            isDisabled={Boolean(message.archivedAt) || Boolean(message.deletedAt)}
            reaction={message.requesterReactionType}
            onClickReaction={handleNewReaction}
          />
          {getTotalReactionAmount(message.totalReactionCountByType) > 0 && (
            <CommunityPostReactionCountButton
              size="md"
              counts={message.totalReactionCountByType}
              isActive={activeTab === Tabs.REACTIONS}
              isDisabled={Boolean(message.deletedAt) && !sessionUser.isSuperAdmin}
              onClick={() => {
                handleChangeTab(Tabs.REACTIONS);
              }}
            />
          )}
          {message.type !== "announcementReadOnly" && (
            <CommunityPostCommentButton
              count={message.totalCommentCount}
              isActive={activeTab === Tabs.COMMENTS}
              isDisabled={Boolean(message.deletedAt) && !sessionUser.isSuperAdmin}
              onClick={() => {
                if (message.canComment || message.totalCommentCount > 0) {
                  handleChangeTab(Tabs.COMMENTS);
                }
              }}
            />
          )}
        </div>
        {message.lastActivityAt && (
          <span className="text-caption text-grey-500">
            {t("component.community-post.last-activity", {
              time: formatDistance(t, { start: new Date(message.lastActivityAt) }),
            })}
          </span>
        )}
      </div>
      {activeTab === Tabs.REACTIONS && <ReactionList messageId={message.id} />}
      {activeTab === Tabs.COMMENTS && (
        <CommentList
          messageId={message.id}
          commentPost={handleAddComment}
          editComment={handleEditPost}
          deleteComment={handleDeleteComment}
          onViewCommentReactions={(x) =>
            setViewCommentReactionsState({ open: true, commentId: x.commentId, likeCount: x.likeCount })
          }
          canComment={message.canComment}
        />
      )}
      <CommentReactionListModal
        isOpened={viewCommentReactionsState.open}
        messageId={message.id}
        onOpenChange={(state) => {
          if (!state) {
            setViewCommentReactionsState(() => ({ open: false }));
          } else {
            setViewCommentReactionsState((x) => ({ ...x, open: state }));
          }
        }}
        commentId={viewCommentReactionsState.commentId}
        initialLikes={viewCommentReactionsState.likeCount}
      />
    </>
  );
}
