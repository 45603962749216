import type { ChatReplyDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Gallery } from "components/Gallery/Gallery";
import { Icon } from "components/Icon/Icon";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { VideoFilePreview } from "components/VideoFilePreview/VideoFilePreview";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface ChatMessageProps {
  message: ChatReplyDto;
  isLastOfMessageGroup: boolean;
}

export function ChatMessage({ message, isLastOfMessageGroup }: ChatMessageProps): React.ReactNode {
  const { i18n } = useTranslation();
  const sessionUser = useSessionUser();

  const isOwnMessage = message.author!.id === sessionUser.id;

  return (
    <div
      className={twJoin("flex gap-2", isOwnMessage ? "self-end" : "self-start", !isLastOfMessageGroup && "pb-6")}
      data-testid="chat-message"
    >
      <div className={twJoin("size-9 self-end", isOwnMessage && "order-last", isLastOfMessageGroup && "invisible")}>
        {!isOwnMessage ? <UserAvatarLink user={message.author!} /> : <UserAvatar img={message.author!.avatar} />}
      </div>
      <div
        className={twJoin(
          "sentry-block relative rounded-lg p-3",
          isOwnMessage ? "bg-aop-chat-green-100" : "bg-grey-50",
        )}
      >
        <div className="flex max-w-[200px] flex-col gap-2 lg:max-w-md">
          {message.text && (
            <span className="max-w-full self-start text-caption">
              <LinkFormatter>{message.text}</LinkFormatter>
            </span>
          )}
          {message.image && (
            <div className="max-w-full self-start" data-testid="chat-message-attachment-image">
              <Gallery images={[message.image]} />
            </div>
          )}
          {message.videos && message.videos.length > 0 && (
            <div className="max-w-full self-start" data-testid="chat-message-attachment-video">
              <VideoFilePreview video={message.videos[0]} />
            </div>
          )}
          <div className="flex items-center gap-2 self-end">
            <span className="self-end text-overline text-grey-600">{formatDate(i18n, "time", message.sentAt)}</span>
            {isOwnMessage && <ChatMessageStatusIndicator status={message.hasBeenSeenByAll ? "seen" : "sent"} />}
          </div>
        </div>
      </div>
    </div>
  );
}

type ChatMessageStatusIndicatorProps = {
  status: "sent" | "seen";
};

const ChatMessageStatusIndicator = ({ status }: ChatMessageStatusIndicatorProps) => {
  return (
    <div
      className={twJoin("flex", status === "seen" && "text-aop-basic-blue-500", status === "sent" && "text-grey-500")}
    >
      <Icon name={iconCheck} size={16} className="-mr-2.5" />
      <Icon name={iconCheck} size={16} />
    </div>
  );
};
