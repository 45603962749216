import { useSuspenseQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { Anchor } from "components/Anchor/Anchor";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { parseISO } from "date-fns";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { Card, CardHeader } from "modules/analytics/components/Card";
import { DonutChart } from "modules/analytics/components/DonutChart";
import { NoData } from "modules/analytics/components/NoData";
import { Stat } from "modules/analytics/components/Stat";
import { ChartLegend } from "modules/analytics/components/utility/ChartLegend";
import { dataColors } from "modules/analytics/theme";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

const CHART_SIZE = 220;

export function EngagedResidentsCard(): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t, i18n } = useTranslation();
  const api = useApi();

  const { data } = useSuspenseQuery({
    queryKey: QUERY_KEYS.ANALYTICS_ENGAGED_RESIDENTS(projectId),
    queryFn: () => api.getAnalyticsResidentEngagementV1(),
    select: (response) => {
      const { engagements } = commonAPIDataSelector(response);

      return engagements;
    },
  });

  const noData =
    !data ||
    data.filter((x) => x.type === "lastMonth").length === 0 ||
    data.filter((x) => x.type === "last24Hours").length === 0 ||
    data.filter((x) => x.type === "last7Days").length === 0;

  return (
    <Card>
      <CardHeader
        title={t("page.analytics-overview.chart.resident-engagement.title")}
        tooltip={t("page.analytics-overview.chart.resident-engagement.title-tooltip")}
      >
        <span className="text-caption">
          <Anchor isBold to={routes.analytics.engagementDetails({ slug })}>
            {t("page.analytics-details.link")}
          </Anchor>
        </span>
      </CardHeader>
      {noData ? (
        <NoData />
      ) : (
        <div className="flex flex-col items-center gap-16 pt-8 @7xl:flex-row @7xl:items-center @7xl:justify-between">
          <div className="flex flex-col items-center gap-4 px-6">
            <DonutChart size={CHART_SIZE} value={data.filter((x) => x.type === "lastMonth")[0].engagementPercentage} />
            <div className="flex items-start gap-2">
              <div className="flex flex-col items-center">
                <ChartLegend
                  items={[
                    {
                      label: t("page.analytics-overview.chart.resident-engagement.chart-legend"),
                      color: dataColors.primary,
                    },
                  ]}
                />
                <span className="text-overline italic text-grey-600">
                  {formatDate(i18n, "dateMonthYearShort", parseISO(data.filter((x) => x.type === "lastMonth")[0].from))}{" "}
                  - {formatDate(i18n, "dateMonthYearShort", parseISO(data.filter((x) => x.type === "lastMonth")[0].to))}
                </span>
              </div>
            </div>
          </div>
          <div className="-mt-14 grid w-full grow grid-cols-2 gap-3">
            <Stat
              title={t("page.analytics-overview.chart.resident-engagement.card.day")}
              value={`${data.filter((x) => x.type === "last24Hours")[0].engagementPercentage}%`}
            />
            <Stat
              title={t("page.analytics-overview.chart.resident-engagement.card.week")}
              value={`${data.filter((x) => x.type === "last7Days")[0].engagementPercentage}%`}
            />
          </div>
        </div>
      )}
    </Card>
  );
}
