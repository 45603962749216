import { keepPreviousData, useQuery } from "@tanstack/react-query";
import type { FeelingAtHomeSurveyQuestionDto } from "api/types";
import iconDownload01 from "assets/icons/download-01.svg";
import { Button } from "components/Button/Button";
import { ContextMenuLegacy } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { MonthPicker } from "components/MonthPicker/MonthPicker";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { FeelingAtHomeSurveyQuestionGroupCard } from "components/SurveyQuestionGroupAnswerCard/FeelingAtHomeSurveyQuestionGroupCard";
import { parseISO, startOfMonth, subMonths } from "date-fns";
import { asUtc, tryParse } from "helpers/date";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useQueryParam } from "hooks/useQueryParam";
import { FeelingAtHomeExportModal } from "modules/surveys/components/FeelAtHomeExportModal";
import { FeelingAtHomeQuetsionDetailsModal } from "modules/surveys/components/FeelingAtHomeQuestionDetailsModal";
import { useAnalyticsQueries } from "queries/analytics/queryOptions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type { ApiQueryParams } from "types/api-types";

export function Layout(): React.ReactNode {
  const [dateQueryParam] = useQueryParam("date");
  const [selectedMonth, setSelectedMonth] = useState<Date>(() =>
    startOfMonth(tryParse("yyyy-MM", dateQueryParam) ?? subMonths(new Date(), 1)),
  );
  const [selectedQuestionData, setSelectedQuestionData] = useState<{
    data: FeelingAtHomeSurveyQuestionDto;
    number: number;
  } | null>(null);
  const [isQuestionModalOpen, questionModalHandler] = useBool(false);
  const [surveyExportModal, setSurveyExportModal] = useState<
    { open: true; month?: Date } | { open: false; month?: undefined }
  >({
    open: false,
  });

  const { t } = useTranslation();
  const sessionUser = useSessionUser();

  const query: ApiQueryParams<"getAnalyticsFeelingAtHomeDetailsV1"> = { month: asUtc(selectedMonth).toISOString() };
  const analyticsQueries = useAnalyticsQueries();
  const {
    data: feelingAtHome,
    isFetching: isReloadingDetails,
    error,
  } = useQuery({
    ...analyticsQueries.getFahDetails(query),
    placeholderData: keepPreviousData,
  });

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (!feelingAtHome) {
    return <FullSizeLoader />;
  }

  function handleOpenQuestionModal(idx: number) {
    setSelectedQuestionData({
      data: feelingAtHome!.questions[idx],
      number: idx + 1,
    });
    questionModalHandler.setTrue();
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.feeling-at-home-details.title")}
      subTitle={t("page.feeling-at-home-details.subtitle")}
      actions={
        <ContextMenuLegacy
          actions={[
            {
              text: t("page.feeling-at-home-details.export.button.monthly"),
              icon: <Icon name={iconDownload01} />,
              callback: () => setSurveyExportModal({ open: true, month: selectedMonth }),
            },
            {
              text: t("page.feeling-at-home-details.export.button.all"),
              icon: <Icon name={iconDownload01} />,
              callback: () => setSurveyExportModal({ open: true }),
            },
          ]}
        >
          {(props) => (
            <Button
              title={t("component.context-menu.action.open")}
              isPressed={props.isOpen}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.openHandlers.toggle();
              }}
            >
              {t("page.feeling-at-home-details.export.button")}
            </Button>
          )}
        </ContextMenuLegacy>
      }
    >
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
          {sessionUser.isSuperAdmin ? (
            <>
              <FeelingAtHomeCard>
                <span className="text-body-bold">{t("page.feeling-at-home-details.total-responses")}</span>
                <span className="text-headline3">{feelingAtHome.totalResponseCount}</span>
              </FeelingAtHomeCard>
              <FeelingAtHomeCard>
                <span className="text-body-bold">{t("page.feeling-at-home-details.total-asked")}</span>
                <span className="text-headline3">{feelingAtHome.totalAskedCount}</span>
              </FeelingAtHomeCard>
            </>
          ) : null}
          {isReloadingDetails ? (
            <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
          ) : (
            <>
              <FeelingAtHomeCard>
                <span className="text-body-bold">{t("page.feeling-at-home-details.selected-month")}</span>
                <MonthPicker
                  minDate={parseISO(feelingAtHome.startedAt)}
                  maxDate={new Date()}
                  value={selectedMonth}
                  onChange={setSelectedMonth}
                  placement="left"
                />
              </FeelingAtHomeCard>
              <FeelingAtHomeCard>
                <span className="text-body-bold">{t("page.feeling-at-home-details.month-responses")}</span>
                <span className="text-headline3 leading-old-headline4">
                  {feelingAtHome.questions[0]?.responseCount ?? 0}
                </span>
              </FeelingAtHomeCard>
            </>
          )}
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          {
            // eslint-disable-next-line no-nested-ternary
            isReloadingDetails ? (
              <LoadingIcon className="inset-0 col-span-2 mx-auto my-4 w-6" />
            ) : feelingAtHome.questions.length ? (
              feelingAtHome.questions.map((question, idx) => (
                <FeelingAtHomeSurveyQuestionGroupCard
                  key={idx}
                  order={idx + 1}
                  question={question}
                  onClickViewDetails={() => handleOpenQuestionModal(idx)}
                />
              ))
            ) : (
              <span className="text-body-bold">{t("page.feeling-at-home-details.no-data")}</span>
            )
          }
        </div>
      </div>
      <FeelingAtHomeQuetsionDetailsModal
        isOpened={isQuestionModalOpen}
        onOpenChange={(state) => {
          if (!state) {
            setSelectedQuestionData(null);
          }
          questionModalHandler.set(state);
        }}
        question={selectedQuestionData}
      />
      <FeelingAtHomeExportModal
        isOpened={surveyExportModal.open}
        month={surveyExportModal.month}
        onOpenChange={(state) => {
          if (!state) {
            setSurveyExportModal({ open: false, month: undefined });
          } else {
            setSurveyExportModal({ open: true });
          }
        }}
      />
    </DocumentPaper>
  );
}

function FeelingAtHomeCard({ children }: { children: React.ReactNode }): React.ReactNode {
  return <div className="flex flex-col gap-4 rounded-lg bg-white p-4">{children}</div>;
}
