import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { MessageStatusChangeRequestV2, MessageV2Dto } from "api/types";
import imgLaugh from "assets/images/reactions/laugh.png";
import imgLike from "assets/images/reactions/like.png";
import imgLove from "assets/images/reactions/love.png";
import imgSad from "assets/images/reactions/sad.png";
import imgWow from "assets/images/reactions/wow.png";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useBool } from "hooks/useBool";
import type { TFunction } from "i18next";
import { communityFeedMutations, useCommunityFeedQueries } from "queries/communityFeed";
import { QUERY_KEYS } from "query-keys";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export enum Tabs {
  LIKES,
  COMMENTS,
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useData = (message: MessageV2Dto) => {
  const projectId = useProjectId();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const [analyze, analyzeHandler] = useBool();

  useEffect(() => {
    queryClient.setQueryData(QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id), message);
  }, [queryClient, message, projectId]);

  const { data: sentiment } = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_AI_SENTIMENT(projectId, message.id),
    queryFn: () => api.postMessagesAiSentimentV1(message.id),
    enabled: analyze,
    select: commonAPIDataSelector,
  });
  const { data: topics } = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_AI_TOPICS(projectId, message.id),
    queryFn: () => api.postMessagesAiTopicsV1(message.id),
    enabled: analyze,
    select: commonAPIDataSelector,
  });

  const communityFeedQueries = useCommunityFeedQueries();
  const { data: messageDetails, error: messageError } = useQuery({
    ...communityFeedQueries.messageDetails(message.id),
    // @ts-expect-error: Ignores for now to keep the same behavior
    initialData: message,
  });

  const createQuickReplyLink = communityFeedMutations.useGetQuickReplyLink();
  const deletePost = communityFeedMutations.useDeleteMessage();
  const archivePost = communityFeedMutations.useArchiveMessage();

  const markAsRead = useMutation({
    mutationFn: () => api.postMessagesReadV1(message.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_FEED_STATS(projectId) });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.mark-as-read.error") });
    },
  });

  return {
    post: messageError ? null : messageDetails,
    isMarkingAsRead: markAsRead.isPending,
    markAsRead: markAsRead.mutateAsync,
    deletePost: deletePost.mutateAsync,
    archivePost: archivePost.mutateAsync,
    copyQuickReply: createQuickReplyLink.mutateAsync,
    analyze,
    onAnalyze: analyzeHandler.setTrue,
    onCloseAnalyze: analyzeHandler.setFalse,
    analysis: {
      topics,
      sentiment,
    },
  };
};

export const getReactionImage = (reactionType: string): string | undefined => {
  switch (reactionType) {
    case "sad":
      return imgSad;
    case "wow":
      return imgWow;
    case "laugh":
      return imgLaugh;
    case "love":
      return imgLove;
    case "like":
      return imgLike;
    default:
      return undefined;
  }
};

export const getReactionLabel = (reactionType: string, t: (key: string) => string): string => {
  switch (reactionType) {
    case "sad":
      return t("component.community-post.reaction.sad.button");
    case "wow":
      return t("component.community-post.reaction.wow.button");
    case "laugh":
      return t("component.community-post.reaction.laugh.button");
    case "love":
      return t("component.community-post.reaction.love.button");
    case "like":
      return t("component.community-post.reaction.like.button");
    default:
      return "";
  }
};

export const getTotalReactionAmount = (reactionCountByType: MessageV2Dto["totalReactionCountByType"]): number => {
  return Object.values(reactionCountByType).reduce((acc, curr) => acc + curr, 0);
};

export const getClosePostReasonLabel = (reasonType: MessageStatusChangeRequestV2["reason"], t: TFunction): string => {
  switch (reasonType) {
    case "solvedNotDetailed":
      return t("component.community-post.actions.close.solved-not-detailed");
    case "mistake":
      return t("component.community-post.actions.close.mistake");
    case "noResponse":
      return t("component.community-post.actions.close.no-response");
    case "irrelevant":
      return t("component.community-post.actions.close.irrelevant");
    default:
      return "";
  }
};
