import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { QuestionRequest } from "api/types";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { LazyMarkdownRenderer } from "components/MarkdownRenderer/LazyMarkdownRenderer";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { useBool } from "hooks/useBool";
import type React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type AskAiModalProps = ModalBaseProps;

interface AskAiModalFormValues {
  question: string;
}

export function AskAiModal({ isOpened, onOpenChange }: AskAiModalProps): React.ReactNode {
  const [currResponse, setCurrResponse] = useState<string | undefined>(undefined);
  const [isFeatureAvailable, featureAvailabilityHandlers] = useBool(true);

  const { t } = useTranslation();
  const formMethods = useForm<AskAiModalFormValues>({
    defaultValues: {
      question: "",
    },
  });
  const api = useApi();

  const {
    mutate: postQuestion,
    isPending: isPendingPostQuestion,
    isError: isErrorPostQuestion,
  } = useMutation({
    mutationFn: (data: QuestionRequest) => api.postProjectSummaryQuestionV1(data),
    onSuccess: (response) => {
      if (response.data === null) {
        featureAvailabilityHandlers.setFalse();
      } else {
        setCurrResponse(response.data);
      }
    },
  });

  useEffect(() => {
    if (!isOpened) {
      formMethods.reset();
      setCurrResponse(undefined);
    }
  }, [formMethods, isOpened]);

  const onSubmit = (data: AskAiModalFormValues) => {
    postQuestion({ ...data, markdown: true });
  };

  return (
    <Modal.Root title={t("component.ask-ai-modal.title")} {...{ isOpened, onOpenChange }} isScrollable>
      <Form className="relative flex flex-col items-center" {...{ formMethods, onSubmit }}>
        <div className="flex w-full items-center justify-center gap-2">
          <FormField label={t("component.ask-ai-modal.question-input.label")} htmlFor="question" hideLabel>
            <FormInput
              className="w-full"
              id="question"
              name="question"
              placeholder={t("component.ask-ai-modal.question-input.placeholder")}
              rules={{
                required: t("components.form.error.required", {
                  inputName: t("component.ask-ai-modal.question-input.label"),
                }),
              }}
            />
          </FormField>
          <Button type="submit" disabled={isPendingPostQuestion}>
            {t("component.ask-ai-modal.ask.btn")}
          </Button>
        </div>
        <div className="flex min-h-20 w-full flex-1 items-center justify-center">
          {isPendingPostQuestion && <FullSizeLoader />}
          {!isPendingPostQuestion && !isErrorPostQuestion && currResponse && (
            <div className="flex w-full flex-col gap-2 pt-4">
              <LazyMarkdownRenderer markdown={currResponse} />
            </div>
          )}
          {!isPendingPostQuestion && isFeatureAvailable && !currResponse && (
            <p className="text-grey-600">{t("component.ask-ai-modal.description")}</p>
          )}
          {!isPendingPostQuestion && !isFeatureAvailable && (
            <p className="text-red-600">{t("component.ask-ai-modal.no-data.notification")}</p>
          )}
        </div>
      </Form>
    </Modal.Root>
  );
}
