import { useQuery, useQueryClient } from "@tanstack/react-query";
import type { MessageV2Dto } from "api/types";
import { Button } from "components/Button/Button";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Pdf } from "components/Pdf/Pdf";
import { isLongerThanCharLimit, isLongerThanWordLimit, limitTextByChar, limitTextByWord } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useSlug } from "hooks/useSlug";
import { useCommunityFeedQueries } from "queries/communityFeed";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import { CommunityItemGallery } from "../CommunityItemMedia/CommunityItemGallery";
import { CommunityItemVideo } from "../CommunityItemMedia/CommunityItemVideo";
import { PromotedGroupCard } from "../PromotedGroupCard";

const CHAR_LIMIT = 180;
const WORD_LIMIT = 500;

enum ReadMoreState {
  NONE,
  EXPAND,
  OPEN_DETAILS,
}

interface CommunityPostContentProps {
  message: MessageV2Dto;
  isEditing: boolean;
  isExpanded?: boolean;
  shouldLoad?: boolean;
  onJoinGroup?: ({ groupId }: { groupId: string }) => Promise<void>;
}

export function CommunityPostContent({
  message,
  isEditing,
  isExpanded,
  shouldLoad,
  onJoinGroup,
}: CommunityPostContentProps): React.ReactNode {
  const [isTranslated, translationHandler] = useBool();

  let defaultReadMoreState = ReadMoreState.EXPAND;
  if (message.content && (isExpanded || !isLongerThanCharLimit(message.content, CHAR_LIMIT))) {
    defaultReadMoreState = ReadMoreState.NONE;
  }
  const [readMoreState, setReadMoreState] = useState<ReadMoreState>(defaultReadMoreState);

  const { t } = useTranslation();
  const projectId = useProjectId();
  const slug = useSlug();
  const sessionUser = useSessionUser();
  const queryClient = useQueryClient();

  const communityFeedQueries = useCommunityFeedQueries();
  const { data: translation, isLoading: isLoadingTranslation } = useQuery({
    ...communityFeedQueries.messageTranslation({
      messageId: message.id,
      languageId: sessionUser.language.id,
    }),
    enabled: isTranslated,
  });

  const onClickReadMore = useCallback(() => {
    if (!message?.content) return;

    if (isLongerThanWordLimit(message.content, WORD_LIMIT)) {
      setReadMoreState(ReadMoreState.OPEN_DETAILS);
    } else {
      setReadMoreState(ReadMoreState.NONE);
    }
  }, [message]);

  const onClickTranslate = () => {
    translationHandler.toggle();
  };

  const onGroupFollowChange = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
  }, [message.id, projectId, queryClient]);

  const onToggleFollowGroup = useCallback(
    (id: string, action: "join" | "leave") => {
      onGroupFollowChange();
      if (action === "join") {
        void onJoinGroup?.({ groupId: id });
      }
    },
    [onGroupFollowChange, onJoinGroup],
  );

  const postContent = useMemo(() => {
    if (!message.content) {
      return;
    }

    if (readMoreState === ReadMoreState.EXPAND) {
      return limitTextByChar(message.content, CHAR_LIMIT);
    }

    if (readMoreState === ReadMoreState.OPEN_DETAILS) {
      return limitTextByWord(message.content, WORD_LIMIT);
    }

    return message.content;
  }, [message?.content, readMoreState]);

  const content = isTranslated && translation ? translation?.content : postContent;

  return (
    <>
      <p
        className={twJoin("whitespace-pre-line text-body-bold", message.deletedAt && "text-grey-500 line-through")}
        data-testid="post-title"
      >
        {translation?.title || message.title}
      </p>
      {content && (
        <p className={message.deletedAt ? "text-grey-500 line-through" : "text-grey-900"} data-testid="post-content">
          <LinkFormatter>{content}</LinkFormatter>
        </p>
      )}
      <div className="flex flex-col gap-2">
        {!translation && readMoreState === ReadMoreState.EXPAND && (
          <Button styling="ghostPrimary" onClick={onClickReadMore}>
            {t("component.community-post.content.read-more")}
          </Button>
        )}
        {!translation && readMoreState === ReadMoreState.OPEN_DETAILS && (
          <Button type="link" styling="ghostPrimary" href={routes.messageFeed.details({ slug, id: message.id })}>
            {t("component.community-post.content.read-more")}
          </Button>
        )}

        {!isEditing && message.languageIsoCode !== sessionUser.language.id && (
          <Button
            styling="ghostPrimary"
            data-testid="translate-btn"
            isLoading={isLoadingTranslation}
            onClick={onClickTranslate}
          >
            {isTranslated
              ? t("component.community-post.content.translate.original")
              : t("component.community-post.content.translate")}
          </Button>
        )}
      </div>
      {message.relatedGroup && (
        <PromotedGroupCard
          id={message.relatedGroup.id}
          name={message.relatedGroup.name}
          description={message.relatedGroup.description}
          image={message.relatedGroup.image}
          isResidentGroup={message.relatedGroup.isResidentGroup}
          isOwner={message.relatedGroup.isOwner}
          isMember={message.relatedGroup.isMember}
          followCallback={onToggleFollowGroup}
        />
      )}
      {message.images.length > 0 && (
        <div className="w-full lg:w-2/3" data-testid="attachment-image">
          <CommunityItemGallery images={message.images} shouldLoad={shouldLoad} />
        </div>
      )}
      {message.videos && message.videos.length > 0 && (
        <div className="w-full lg:w-2/3" data-testid="attachment-video">
          <CommunityItemVideo video={message.videos[0]} shouldLoad={shouldLoad} />
        </div>
      )}
      {message.documents && message.documents.length > 0 && (
        <div className="w-full lg:w-2/3" data-testid="attachment-document">
          <Pdf
            key={message.documents[0].id}
            previewImage={message.documents[0].previewImage}
            fileName={message.documents[0].fileName}
            fileSize={message.documents[0].fileSize}
            onClick={() => window.open(message.documents![0].url, "_blank")}
          />
        </div>
      )}
    </>
  );
}
