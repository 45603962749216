import { useQueryClient } from "@tanstack/react-query";
import type { AdminTicketActivityDto, AppTicketDto, TicketCommentDto } from "api/types";
import iconCalendar from "assets/icons/calendar.svg";
import iconLock01 from "assets/icons/lock-01.svg";
import iconStar01 from "assets/icons/star-01.svg";
import iconThumbsUp from "assets/icons/thumbs-up.svg";
import iconUsers01 from "assets/icons/users-01.svg";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { Notice } from "components/Notice/Notice";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import type { ResidentCreateCommentPayload } from "components/Ticket/TicketResidentCommentField";
import { TicketResidentCommentField } from "components/Ticket/TicketResidentCommentField";
import { TicketResidentRateModal } from "components/Ticket/TicketResidentRateModal";
import { TicketResidentRejectModal } from "components/Ticket/TicketResidentRejectModal";
import { TicketStatus } from "components/Ticket/TicketStatus";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useOnIntersection } from "hooks/useOnIntersection";
import { useSlug } from "hooks/useSlug";
import { TicketActivitySection } from "modules/tickets/components/TicketActivitySection";
import { TicketContent } from "modules/tickets/components/TicketDetails/TicketContent";
import { useTicketQueries } from "queries/tickets";
import { useCallback, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { routes } from "routes";

export interface LayoutProps {
  ticket: AppTicketDto;
  comments: TicketCommentDto[];
  isFetchingMoreComments: boolean;
  hasMoreComments: boolean;
  fetchMoreComments: () => unknown;
  onCreateComment: (payload: ResidentCreateCommentPayload) => Promise<void>;
}

export function Layout({
  ticket,
  comments,
  isFetchingMoreComments,
  hasMoreComments,
  fetchMoreComments,
  onCreateComment,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const sessionUser = useSessionUser();
  const { i18n, t } = useTranslation();
  const [isRejectModalOpened, rejectModalOpenHandlers] = useBool(false);
  const [isRateModalOpened, rateModalOpenHandlers] = useBool(false);

  const canRateTicket = ticket.status.type !== "rated" && ticket.author.id === sessionUser.id;
  const ticketQueries = useTicketQueries();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (canRateTicket) {
      void queryClient.prefetchQuery(ticketQueries.ratingFeedbackOptions());
    }
  }, [canRateTicket, queryClient, ticketQueries]);

  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isFetchingMoreComments && hasMoreComments) {
        fetchMoreComments();
      }
    }, [fetchMoreComments, hasMoreComments, isFetchingMoreComments]),
  });

  const activities = useMemo(() => {
    return comments.map(
      (comment) =>
        ({
          id: comment.id,
          postedAt: comment.postedAt,
          author: comment.author,
          comment: {
            ...comment,
            internal: false,
            images: comment.image ? [comment.image] : [],
            canEdit: false,
            canDelete: false,
          },
        }) satisfies AdminTicketActivityDto,
    );
  }, [comments]);

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.tickets.details.title")}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.tickets.title"),
              to: routes.tickets.list({ slug }),
            },
            {
              name: t("page.tickets.details.title"),
            },
          ]}
        />
      }
      actions={
        canRateTicket ? (
          <Button
            styling="secondary"
            icon={<Icon name={iconThumbsUp} />}
            onClick={rateModalOpenHandlers.setTrue}
            data-testid="rate-close-ticket"
          >
            {t("page.tickets.details.actions.rate-or-close")}
          </Button>
        ) : null
      }
    >
      <div className="flex flex-col gap-4 @6xl:flex-row">
        <div className="flex max-w-6xl flex-1 flex-col gap-4">
          <div>
            <div className="flex flex-col gap-6 rounded-t-lg border-b border-grey-100 bg-white p-4">
              {ticket.closedAt && (ticket.status.type === "rated" || ticket.status.type === "autoClosed") && (
                <Notice
                  icon={iconLock01}
                  type="info"
                  message={t("page.tickets.details.info.closed-at", {
                    date: formatDate(i18n, "date", ticket.closedAt),
                  })}
                />
              )}
              <div className="flex flex-wrap gap-x-6 gap-y-2">
                {ticket.visibility !== "private" && (
                  <DetailInfo title={t("page.tickets.details.info.type")}>
                    <div className="flex items-center gap-1 rounded bg-grey-100 p-2">
                      <Icon name={iconUsers01} className="text-aop-basic-blue-500" />
                      <span className="text-caption-bold text-blue-600">
                        {t("page.tickets.details.info.type.collective")}
                      </span>
                    </div>
                  </DetailInfo>
                )}
                <DetailInfo title={t("page.tickets.details.info.status")}>
                  <div className="flex items-center gap-1 px-2 py-1.5">
                    <TicketStatus description={ticket.status.name} labelColor={ticket.status.color} />
                  </div>
                </DetailInfo>
                <DetailInfo title={t("page.tickets.details.info.category")}>
                  <div className="gap-1 rounded bg-grey-100 px-2 py-1.5">
                    <span className="text-caption-bold">{ticket.category.name}</span>
                  </div>
                </DetailInfo>
                {ticket.rating ? (
                  <DetailInfo title={t("page.tickets.details.info.rating")}>
                    <span>
                      <span
                        data-testid="rating-number"
                        className="inline-flex items-center gap-1 rounded bg-yellow-100 px-2 py-1.5"
                      >
                        <span className="flex gap-0.5">
                          <Icon name={iconStar01} size={16} className="fill-current text-yellow-500" />
                        </span>
                        <span className="text-body-bold">{ticket.rating ?? "-"}</span>
                      </span>
                    </span>
                  </DetailInfo>
                ) : null}
              </div>
              <div className="flex items-center gap-2">
                <span className="shrink-0">
                  <Icon name={iconCalendar} size={16} />
                </span>
                <span className="text-caption">
                  <Trans
                    i18nKey="page.tickets.details.created-at"
                    values={{
                      date: formatDate(i18n, "datetime", ticket.postedAt),
                      name: ticket.author.fullName,
                    }}
                    components={{
                      bold: <b />,
                    }}
                  />
                </span>
              </div>
            </div>
            <TicketContent ticket={ticket} />
          </div>

          <div className="rounded-lg bg-white p-4">
            <div className="flex items-center justify-between">
              <div className="flex w-full flex-col gap-2">
                <div className="flex justify-between">
                  <span className="text-headline3 leading-old-headline4">
                    {t("page.tickets.details.activities.title")}
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-4 flex flex-col gap-2">
              <div>
                <TicketResidentCommentField
                  canComment={ticket.canComment}
                  onComment={onCreateComment}
                  canRateOrReject={
                    ticket.canComment && ticket.status.type === "closed" && ticket.author.id === sessionUser.id
                  }
                  onReject={rejectModalOpenHandlers.setTrue}
                  onRate={rateModalOpenHandlers.setTrue}
                  statusName={ticket.status.name}
                  user={sessionUser}
                  showOtherUserCanNotCommentMessage={
                    ticket.author.id !== sessionUser.id && ticket.status.type !== "rated"
                  }
                  wasTicketCreatedInWeb={ticket.requestOrigin === "web"}
                />
                <TicketActivitySection
                  ticket={ticket}
                  isForResident
                  activities={
                    !ticket.rating && !ticket.remark
                      ? activities
                      : [
                          {
                            author: ticket.author,
                            id: "rating",
                            postedAt: ticket.closedAt || new Date().toISOString(),
                            rating: {
                              rating: ticket.rating,
                              ratingRemark: ticket.remark,
                              ratingFeedback: ticket.ratingFeedbacks,
                            },
                          },
                          ...activities,
                        ]
                  }
                />
                {hasMoreComments ? (
                  <div className="p-4" ref={ref}>
                    <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <TicketResidentRejectModal
        isOpened={isRejectModalOpened}
        onOpenChange={rejectModalOpenHandlers.set}
        onSubmit={async (payload) => {
          try {
            await onCreateComment(payload);
          } finally {
            rejectModalOpenHandlers.setFalse();
          }
        }}
      />
      <TicketResidentRateModal isOpened={isRateModalOpened} onOpenChange={rateModalOpenHandlers.set} ticket={ticket} />
    </DocumentPaper>
  );
}

function DetailInfo({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <div className="flex flex-col gap-2">
      <span className="text-overline font-old-semibold text-grey-700">{title}</span>
      <div className="flex">{children}</div>
    </div>
  );
}
