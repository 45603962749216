import type { HomeDnaStatusDto } from "api/types";
import checkIcon from "assets/icons/check.svg";
import slashIcon from "assets/icons/slash-circle-01.svg";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { ticketMutations } from "queries/tickets";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import homeDnaPng from "../../assets/home-dna.png";

export function TicketHomeDnaCard({
  ticketId,
  homeDna,
  withOutline,
}: {
  ticketId: string;
  homeDna: HomeDnaStatusDto;
  withOutline?: boolean;
}): React.ReactNode {
  const { t } = useTranslation();

  const uploadTicketMutation = ticketMutations.useSendTicketToHomeDna(ticketId);

  return (
    <div
      className={twJoin(
        "flex flex-col gap-4 rounded-lg bg-white p-4",
        withOutline ? "border border-grey-100" : undefined,
      )}
    >
      <div className="flex justify-center">
        <img src={homeDnaPng} className="aspect-[182/35] h-8" />
      </div>
      {uploadTicketMutation.isPending ? (
        <LoadingIcon className="mx-auto my-5 w-5" />
      ) : (
        <div className="flex flex-col items-center gap-4">
          {homeDna.state === "notStarted" && (
            <p className="text-grey-600">{t("page.tickets.homedna.state.not-uploaded")}</p>
          )}
          {homeDna.state === "failed" && (
            <div className="flex flex-col gap-1 text-red-600">
              <p className="flex items-center gap-1">
                <Icon name={slashIcon} className="w-4" />
                {t("page.tickets.homedna.state.error") + (homeDna.errorDetails ? ":" : "")}
              </p>
              {homeDna.errorDetails ? <p className="text-caption text-red-700">{homeDna.errorDetails}</p> : null}
            </div>
          )}
          {homeDna.state === "sent" && (
            <p className="flex items-center gap-1 font-old-semibold text-green-600">
              <Icon name={checkIcon} className="w-4" />
              {t("page.tickets.homedna.state.success")}
            </p>
          )}

          {homeDna.state !== "sent" && (
            <div>
              <Button onClick={() => uploadTicketMutation.mutate()}>
                {t("page.tickets.homedna.actions.send-ticket")}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
