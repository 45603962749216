import { Carousel as ArkCarousel } from "@ark-ui/react";
import type { ImageDto } from "api/types";
import IconChevronLeft from "assets/icons/chevron-left.svg";
import IconChevronRight from "assets/icons/chevron-right.svg";
import { IconButton } from "components/Button/IconButton";
import type { FormImage } from "components/ImageInput/useImageInput";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { preloadImage } from "helpers/image";
import { isDefined } from "helpers/util";
import { useBool } from "hooks/useBool";
import { useHasMounted } from "hooks/useHasMounted";
import { useKey } from "hooks/useKey";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

type CarouselImage = {
  // Use type to guard in case of type mutation
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  url: ImageDto["url"] | FormImage["url"];
  description?: ImageDto["description"];
};

type CarouselProps = {
  images: CarouselImage[];
  defaultPage?: number;
  objectFit?: React.CSSProperties["objectFit"];
} & (
  | {
      styling: "overlay";
      allowZoom?: never;
    }
  | {
      styling?: "default";
      allowZoom?: boolean;
    }
);

export function Carousel({
  images,
  defaultPage,
  objectFit = "cover",
  allowZoom,
  styling = "default",
}: CarouselProps): React.ReactNode {
  const [isZoomModalOpened, zoomModalHandler] = useBool();
  const [activePage, setActivePage] = useState<number>();

  const hasMounted = useHasMounted();

  // The ark UI carousel doesn't handle initial page well on PRODUCTION BUILDS ONLY, so we need to set it after mount
  useEffect(() => {
    if (!activePage) {
      setTimeout(() => {
        setActivePage((x) => x ?? defaultPage);
      }, 10);
    }
  }, [defaultPage, activePage]);

  const { t } = useTranslation();

  // Preload next slide
  useEffect(() => {
    const nextImage = images[(activePage || 0) + 1];

    if (nextImage) {
      preloadImage(nextImage.url);
    }
  }, [activePage, images]);

  useKey("Escape", zoomModalHandler.setFalse);

  // Arrow key navigation only enabled for "overlay" styling
  useKey(
    "ArrowLeft",
    () => {
      setActivePage((activePage || 0) - 1);
    },
    activePage != null && activePage > 0 && isZoomModalOpened && styling === "overlay",
  );
  useKey(
    "ArrowRight",
    () => {
      setActivePage((activePage || 0) + 1);
    },
    activePage != null && activePage < images.length - 1 && isZoomModalOpened && styling === "overlay",
  );

  return (
    <>
      <ArkCarousel.Root
        className="relative size-full overflow-hidden"
        page={activePage}
        onPageChange={(details) => {
          // Check mounted because this fires on first render as 0 and we don't want to set activePage to 0
          if (details.page >= 0 && hasMounted) {
            setActivePage(details.page);
          }
        }}
      >
        <ArkCarousel.ItemGroup className="h-full">
          {images.map((image, index) => (
            <ArkCarousel.Item
              key={image.url}
              onClick={allowZoom ? zoomModalHandler.setTrue : undefined}
              className={twJoin("relative bg-black", allowZoom && "cursor-zoom-in")}
              {...{ index }}
            >
              <img
                className="absolute inset-y-0 size-full"
                style={{ objectFit }}
                src={image.url}
                alt={image.description}
              />
            </ArkCarousel.Item>
          ))}
        </ArkCarousel.ItemGroup>
        <span className="pointer-events-none absolute bottom-0 left-0 z-0 h-16 w-full bg-gradient-to-t from-black/60 to-black/0" />

        <ArkCarousel.Control className="absolute left-0 top-1/2 flex w-full -translate-y-1/2 items-center justify-between px-3">
          <ArkCarousel.PrevTrigger asChild>
            <IconButton
              title={t("common.action.previous")}
              size="sm"
              icon={IconChevronLeft}
              withTooltip={false}
              styling="primary"
              isCircular
            />
          </ArkCarousel.PrevTrigger>
          <ArkCarousel.NextTrigger asChild>
            <IconButton
              title={t("common.action.next")}
              icon={IconChevronRight}
              size="sm"
              withTooltip={false}
              styling="primary"
              isCircular
            />
          </ArkCarousel.NextTrigger>
        </ArkCarousel.Control>

        <ArkCarousel.IndicatorGroup className="absolute bottom-0 left-1/2 z-10 flex -translate-x-1/2 items-center gap-2 px-2 py-3">
          {images.map((_, index) => (
            <ArkCarousel.Indicator onClick={() => setActivePage(index)} key={index} asChild {...{ index }}>
              <button
                type="button"
                className={twJoin(
                  "size-[10px] rounded-full transition",
                  index === activePage ? "bg-white" : "bg-black/60",
                )}
              />
            </ArkCarousel.Indicator>
          ))}
        </ArkCarousel.IndicatorGroup>
      </ArkCarousel.Root>
      {allowZoom && (
        <ImageModal
          images={images as FormImage[]}
          zoomedImageIndex={activePage}
          isOpened={isZoomModalOpened}
          onOpenChange={zoomModalHandler.set}
        />
      )}
    </>
  );
}

// In the same file, because it's a circular dependency (Carousel -> ImageModal -> Carousel)
type ImageModalProps = ModalBaseProps & {
  images: FormImage[];
  zoomedImageIndex: number | undefined;
};

export function ImageModal({ isOpened, onOpenChange, images, zoomedImageIndex }: ImageModalProps): React.ReactNode {
  const zoomedImage = isDefined(zoomedImageIndex) ? images[zoomedImageIndex] : undefined;

  return (
    <Modal.Root isNested {...{ isOpened, onOpenChange }}>
      <div className="aspect-[3/4] w-full overflow-hidden md:aspect-square">
        {images.length === 1 && zoomedImage && (
          <div className="size-full overflow-hidden rounded-lg bg-black">
            <img
              className="size-full object-contain"
              src={zoomedImage.url}
              alt={("description" in zoomedImage && zoomedImage.description) || ""}
            />
          </div>
        )}
        {images.length > 1 && (
          <div className="size-full overflow-hidden rounded-lg">
            <Carousel defaultPage={zoomedImageIndex} styling="overlay" objectFit="contain" {...{ images }} />
          </div>
        )}
      </div>
    </Modal.Root>
  );
}
