import type { MessageV2Dto } from "api/types";
import iconMessageSquare02 from "assets/icons/message-square-02.svg";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { twResolve } from "helpers/tw-resolve";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { getReactionImage, getReactionLabel, getTotalReactionAmount } from "./helpers";

interface CommunityPostReactionCountButtonProps {
  size: "sm" | "md";
  counts: MessageV2Dto["totalReactionCountByType"];
  isActive?: boolean;
  isDisabled: boolean;
  onClick?: () => void;
}

export function CommunityPostReactionCountButton({
  size,
  counts,
  isActive,
  isDisabled,
  onClick,
}: CommunityPostReactionCountButtonProps): React.ReactNode {
  const { t } = useTranslation();

  // Onlly show top 3 reactions by occurrence
  const topReactions = Object.entries(counts)
    .filter(([, count]) => count > 0)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 3)
    .map((entry) => entry[0]);

  return (
    <CommunityPostActivityButtonWrapper {...{ isActive }}>
      <Button
        styling="tertiary"
        onClick={onClick}
        disabled={isDisabled}
        size={size}
        className={twResolve("rounded-md bg-grey-100 px-2 text-black", isActive && "bg-aop-basic-blue-100")}
        data-testid={size === "md" ? "reaction-count-btn" : "comment-reaction-count-btn"}
      >
        <div className="flex items-center">
          {topReactions.map((reactionType) => (
            <img
              key={reactionType}
              src={getReactionImage(reactionType)}
              alt={getReactionLabel(reactionType, t)}
              className={twJoin(size === "md" && "h-5", size === "sm" && "h-4")}
            />
          ))}
        </div>
        {getTotalReactionAmount(counts)}
      </Button>
    </CommunityPostActivityButtonWrapper>
  );
}

interface CommunityPostCommentButtonProps {
  count: number;
  isActive: boolean;
  isDisabled: boolean;
  onClick: () => void;
}

export function CommunityPostCommentButton({
  count,
  isActive,
  isDisabled,
  onClick,
}: CommunityPostCommentButtonProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <CommunityPostActivityButtonWrapper {...{ isActive }}>
      <Button
        styling="tertiary"
        disabled={isDisabled}
        data-testid="comment-btn"
        icon={<Icon name={iconMessageSquare02} />}
        isCircular
        {...{ onClick }}
      >
        {t("component.community-post.comments.total", { count: count })}
      </Button>
    </CommunityPostActivityButtonWrapper>
  );
}

interface CommunityPostActivityButtonWrapperProps {
  children: React.ReactNode;
  isActive?: boolean;
}

function CommunityPostActivityButtonWrapper({ children, isActive }: CommunityPostActivityButtonWrapperProps) {
  return (
    <div
      className={twJoin(
        "relative",
        isActive &&
          "after:absolute after:-bottom-1 after:left-0 after:h-px after:w-full after:translate-y-full after:bg-aop-basic-blue-500 after:content-['']",
      )}
    >
      {children}
    </div>
  );
}
