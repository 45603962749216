import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import type { ApiQueryParams } from "types/api-types";

export const useCommunityFeedQueries = () => {
  const projectId = useProjectId();
  const api = useApi();

  return {
    messages: (query: ApiQueryParams<"getMessagesV2">) =>
      queryOptions({
        queryKey: QUERY_KEYS.MESSAGES_FEED_OLD(projectId, query),
        queryFn: () => api.getMessagesV2(query),
        select: commonAPIDataSelector,
      }),
    audience: (query: { IncludeAnnouncements: boolean }) =>
      queryOptions({
        queryKey: QUERY_KEYS.MESSAGES_AUDIENCE(projectId, query),
        queryFn: () => api.getCommunityFeedAudienceV1(query),
        select: commonAPIDataSelector,
      }),
    messageDetails: (messageId: string) =>
      queryOptions({
        queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, messageId),
        queryFn: () => api.getMessagesDetailsV2(messageId).then((res) => res.data),
        staleTime: Infinity,
      }),
    commentDetails: ({ messageId, commentId }: { messageId: string; commentId: string }) =>
      queryOptions({
        queryKey: QUERY_KEYS.MESSAGE_COMMENT_DETAILS(projectId, messageId, commentId),
        queryFn: () => api.getMessagesCommentsDetailsV1(messageId, commentId),
        select: commonAPIDataSelector,
        staleTime: Infinity,
      }),
    reactionsInfinite: (messageId: string) => {
      const limit = 5;

      return infiniteQueryOptions({
        queryKey: QUERY_KEYS.MESSAGES_REACTIONS(projectId, messageId),
        queryFn: async ({ pageParam = 0 }) => {
          const { data } = await api.getMessagesReactV1(messageId, {
            Offset: pageParam * limit,
            Limit: limit,
          });

          return data;
        },
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => {
          if (!lastPage.hasMore) {
            return undefined;
          }

          return pages.length;
        },
      });
    },
    commentReactionsInfinite: ({ messageId, commentId }: { messageId: string; commentId: string }) => {
      const limit = 10;

      return infiniteQueryOptions({
        queryKey: QUERY_KEYS.MESSAGES_COMMENT_LIKES(projectId, messageId, commentId),
        queryFn: async ({ pageParam = 0 }) => {
          const { data } = await api.getMessagesCommentsReactV1(messageId, commentId, {
            Offset: pageParam * limit,
            Limit: limit,
          });

          return data;
        },
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => {
          if (!lastPage.hasMore) {
            return undefined;
          }

          return pages.length;
        },
      });
    },
    commentsInfinite: (messageId: string) => {
      const limit = 5;

      return infiniteQueryOptions({
        queryKey: QUERY_KEYS.MESSAGES_COMMENTS(projectId, messageId),
        queryFn: async ({ pageParam = 0 }) => {
          const { data } = await api.getMessagesCommentsV1(messageId, {
            Offset: pageParam * limit,
            Limit: limit,
          });

          return data;
        },
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => {
          if (!lastPage.hasMore) {
            return undefined;
          }

          return pages.length;
        },
        staleTime: 15 * 60 * 1000, // 15 minutes
        refetchOnWindowFocus: true,
      });
    },
    messageTranslation: ({ messageId, languageId }: { messageId: string; languageId: string }) =>
      queryOptions({
        queryKey: QUERY_KEYS.MESSAGE_TRANSLATION(projectId, messageId, languageId),
        queryFn: () => api.getMessagesTranslationsDetailsV2(messageId, languageId),
        select: commonAPIDataSelector,
        retry: false,
      }),
    commentTranslation: ({
      messageId,
      commentId,
      languageId,
    }: {
      messageId: string;
      commentId: string;
      languageId: string;
    }) =>
      queryOptions({
        queryKey: QUERY_KEYS.MESSAGES_COMMENT_TRANSLATION(projectId, messageId, commentId, languageId),
        queryFn: () => api.getMessagesCommentsTranslationsDetailsV2(messageId, commentId, languageId),
        retry: false,
      }),
  };
};
