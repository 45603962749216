import { useSuspenseQuery } from "@tanstack/react-query";
import { Anchor } from "components/Anchor/Anchor";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { GrowLabel } from "components/GrowLabel/GrowLabel";
import { isDefined } from "helpers/util";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { Card, CardHeader } from "modules/analytics/components/Card";
import { NoData } from "modules/analytics/components/NoData";
import { SpeedometerLegacy } from "modules/home/components/SpeedometerLegacy";
import { canListAnySurvey } from "modules/surveys/permissions";
import { useAnalyticsQueries } from "queries/analytics/queryOptions";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

export function FeelingAtHomeSurveyChart(): React.ReactNode {
  const slug = useSlug();
  const { t, i18n } = useTranslation();
  const hasPermission = usePermission();

  const analyticsQueries = useAnalyticsQueries();
  const { data } = useSuspenseQuery(analyticsQueries.getFahStats());

  const noData = data?.totalFeelingAtHomeScore?.rating === undefined;

  return (
    <Card>
      <CardHeader
        title={t("page.analytics-overview.chart.feeling-at-home.title")}
        tooltip={t("page.analytics-overview.chart.feeling-at-home.new-tooltip")}
      />
      {noData ? (
        <NoData />
      ) : (
        <div className="grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] items-center gap-12">
          <div className="flex w-full flex-col items-center gap-10">
            <div className="flex items-center gap-5">
              <div className="-mb-2 w-24 xs:w-32">
                <SpeedometerLegacy score={(data?.totalFeelingAtHomeScore?.rating || 0) / 10} />
              </div>
              <div className="text-[80px] font-old-semibold">
                <span>{data.totalFeelingAtHomeScore?.rating.toFixed(1) || "-"}</span>
                <span className="text-[30px] font-old-normal">/10</span>
              </div>
            </div>

            <div className="flex w-full gap-3">
              <div className="flex min-w-[120px] flex-1 flex-col gap-2 rounded-lg border border-grey-100 px-3 py-2">
                <span className="text-caption text-grey-700">
                  {data?.latestMonthlyFeelingAtHomeScore
                    ? t("page.analytics-overview.chart.feeling-at-home.last-month", {
                        month: formatDate(i18n, "month", data.latestMonthlyFeelingAtHomeScore.month),
                      })
                    : "-"}
                </span>
                <div className="flex items-center gap-2">
                  <span className="text-[40px] font-old-semibold leading-none">
                    {data.latestMonthlyFeelingAtHomeScore?.rating.toFixed(1) || "-"}
                  </span>
                  {isDefined(data.latestMonthlyFeelingAtHomeScore) && isDefined(data?.totalFeelingAtHomeScore) && (
                    <GrowLabel
                      newValue={data.latestMonthlyFeelingAtHomeScore.rating || 0}
                      oldValue={data.totalFeelingAtHomeScore.rating}
                      type="number"
                      decimalPlaces={1}
                    />
                  )}
                </div>
                {data.hasFeelAtHomeDetailsLink && hasPermission(canListAnySurvey) && (
                  <span className="text-caption">
                    <Anchor
                      isBold
                      to={{
                        pathname: routes.surveys.fah({ slug }),
                        search: `?date=${data?.latestMonthlyFeelingAtHomeScore?.month.substring(0, 7)}`,
                      }}
                    >
                      {t("page.analytics-overview.chart.feeling-at-home.view-details")}
                    </Anchor>
                  </span>
                )}
              </div>
              <div className="flex min-w-[120px] flex-1 flex-col gap-2 rounded-lg border border-grey-100 px-3 py-2">
                <span className="text-caption text-grey-700">
                  {t("page.analytics-overview.chart.feeling-at-home.benchmark")}
                </span>
                <div className="flex items-center gap-2">
                  <span className="text-[40px] font-old-semibold leading-none">
                    {data.benchmarkFeelingAtHomeScore?.rating.toFixed(1) || "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col gap-4">
            <span className="text-caption-bold">{t("page.analytics-overview.chart.feeling-at-home.range.title")}</span>
            <div className="flex items-center gap-2">
              <span className="min-w-fit rounded-md bg-red-500 px-2 py-1 font-old-semibold text-white">0-2</span>
              <span className="text-caption">{t("page.analytics-overview.chart.feeling-at-home.range.1")}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="min-w-fit rounded-md bg-orange px-2 py-1 font-old-semibold text-white">2-4</span>
              <span className="text-caption">{t("page.analytics-overview.chart.feeling-at-home.range.2")}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="min-w-fit rounded-md bg-yellow-500 px-2 py-1 font-old-semibold text-white">4-6</span>
              <span className="text-caption">{t("page.analytics-overview.chart.feeling-at-home.range.3")}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="min-w-fit rounded-md bg-green-500 px-2 py-1 font-old-semibold text-white">6-8</span>
              <span className="text-caption">{t("page.analytics-overview.chart.feeling-at-home.range.4")}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="min-w-fit rounded-md bg-green-600 px-2 py-1 font-old-semibold text-white">8-10</span>
              <span className="text-caption">{t("page.analytics-overview.chart.feeling-at-home.range.5")}</span>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}
