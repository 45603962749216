import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { ProjectConnectionDetailsDto } from "api/types";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconPlus from "assets/icons/plus.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import type { ContextMenuLegacyAction } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { ContextMenuLegacy } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { Icon } from "components/Icon/Icon";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { PillList } from "components/PillList/PillList";
import { Table } from "components/Table/Table";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSystemSettingBreadcrumbs } from "modules/system-settings/util/useSystemSettingBreadcrumbs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

export interface LayoutProps {
  connections: ProjectConnectionDetailsDto[];
  onDelete: (connection: ProjectConnectionDetailsDto) => Promise<unknown>;
}

export function Layout({ connections, onDelete }: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const navigate = useNavigate();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<ProjectConnectionDetailsDto>(
    "project-connection-delete-modal",
  );

  const columns = useMemo(() => {
    const helper = createColumnHelper<ProjectConnectionDetailsDto>();

    return [
      helper.accessor("name", {
        header: t("page.project-connections.list.name"),
        cell: (cell) => (
          <Anchor to={routes.projectConnections.edit({ id: cell.row.original.id })}>{cell.getValue()}</Anchor>
        ),
      }),
      helper.accessor("projects", {
        header: t("page.project-connections.list.projects"),
        cell: (cell) => <PillList data={cell.getValue()} />,
      }),
      helper.accessor("groups", {
        header: t("page.project-connections.list.groups"),
        cell: (cell) =>
          cell.getValue()?.items ? (
            <PillList
              total={cell.getValue()!.total}
              limit={3}
              data={cell.getValue()!.items.map((x) => ({
                id: x.id,
                name: x.translations ? x.translations.find((l) => l.languageId === sessionUser.language.id)!.name : "",
                callback: () =>
                  void navigate(routes.projectConnections.editGroup({ id: cell.row.original.id, gid: x.id })),
              }))}
              overLimitPill={{
                id: `pc_${cell.row.original.id}_groups_see_all`,
                name: t("page.project-connections.list.groups.see-all"),
                callback: () => void navigate(routes.projectConnections.listGroups({ id: cell.row.original.id })),
              }}
            />
          ) : null,
      }),
      helper.accessor("internalDescription", {
        header: t("page.project-connections.list.description"),
        cell: (cell) => <div>{cell.getValue() || ""}</div>,
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          const actions: ContextMenuLegacyAction[] = [];

          actions.push({
            text: t("common.action.edit"),
            icon: <Icon name={iconEdit05} />,
            callback: () => void navigate(routes.projectConnections.edit({ id: cell.row.original.id })),
          });

          actions.push({
            text: t("common.action.delete"),
            icon: <Icon name={iconTrash02} />,
            callback: () => openDeleteModal(cell.row.original),
          });

          actions.push({
            text: t("page.project-connections.actions.create-group"),
            icon: <Icon name={iconPlus} />,

            callback: () => void navigate(routes.projectConnections.createGroup({ id: cell.row.original.id })),
          });

          return (
            <div className="flex justify-end px-2">
              <ContextMenuLegacy actions={actions} />
            </div>
          );
        },
      }),
    ];
  }, [navigate, openDeleteModal, t, sessionUser]);

  const tableInstance = useReactTable<ProjectConnectionDetailsDto>({
    columns,
    data: connections,
    getCoreRowModel: getCoreRowModel(),
  });

  const breadcrumbs = useSystemSettingBreadcrumbs({ module: "project-connections", status: "list" });

  return (
    <DocumentPaper
      title={t("page.project-connections.title")}
      theme="minimal"
      subTitle={<Breadcrumbs pages={breadcrumbs} />}
      actions={
        <Button type="link" data-testid="project-connection-action-create" href={routes.projectConnections.create()}>
          {t("page.project-connections.actions.create")}
        </Button>
      }
    >
      {connections.length === 0 ? (
        <div className="px-2">
          <p>{t("page.project-connections.list.empty")}</p>
        </div>
      ) : (
        <Table table={tableInstance} />
      )}
      <DeleteModal
        title={t("page.project-connections.actions.delete.title")}
        description={t("page.project-connections.actions.delete.description")}
        onDelete={onDelete}
        deleteBtnProps={{
          "data-testid": "project-connection-action-delete",
        }}
        {...deleteModalProps}
      />
    </DocumentPaper>
  );
}
