import { useQuery } from "@tanstack/react-query";
import { getPreprIntegrationDetails } from "api/prepr";
import messagesIcon from "assets/icons/message-square-02.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { PageGrid } from "components/PageGrid/PageGrid";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { integrationIsNew } from "modules/portfolio/helpers/integration";
import { useConfig } from "providers/ConfigProvider";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { routes } from "routes";

import bgPlaceholder from "./header-placeholder.jpg";

export function AvailableIntegrationDetailPage(): React.ReactNode {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const sessionUser = useSessionUser();
  const preprUrl = useConfig("preprDashboardUrl");
  const { data, isLoading, error } = useQuery({
    queryKey: QUERY_KEYS.AVAILABLE_INTEGRATIONS_DETAIL(id!, sessionUser.language.id),
    queryFn: () => getPreprIntegrationDetails(preprUrl, id!, sessionUser.language.id),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  const integration = data?.data.Integration;

  return (
    <DocumentPaper
      theme="minimal"
      title={integration?.name || ""}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.portfolio.title"),
              to: routes.portfolio.overview(),
            },
            {
              name: t("page.available-integrations.title"),
              to: routes.portfolio.integrations(),
            },
            {
              name: integration?.name || "",
            },
          ]}
        />
      }
    >
      <PageGrid.Grid>
        <PageGrid.Item size="50%">
          <div className="flex flex-col rounded-lg bg-white shadow-md">
            <div className="relative">
              <div
                className="h-64 rounded-t-lg bg-grey-400 bg-cover bg-center p-2"
                style={{
                  backgroundImage: `url(${integration?.header_image ? integration.header_image.url : bgPlaceholder})`,
                }}
              />
              <img
                className="absolute -bottom-12 right-8 size-24 rounded-full bg-white object-cover p-1"
                src={integration?.profile_image?.url}
                alt=""
              />
            </div>
            <div className="flex flex-col gap-4 p-4">
              <span className="text-headline3 leading-old-headline4">
                <span className="flex items-center gap-4">
                  <span>{integration?.name}</span>

                  {integrationIsNew(integration?.created) && (
                    <Label theme="green">{t("page.available-integrations.integration.new")}</Label>
                  )}
                </span>
              </span>
              <div className="my-3 flex w-full items-center justify-between gap-4 px-4">
                <p className="font-old-semibold text-aop-dark-blue-500">
                  {t("page.available-integrations.integration.contact")}
                </p>
                <button
                  className="flex cursor-pointer items-center justify-center rounded-full bg-grey-100 p-4 hover:bg-grey-300 [&>svg]:stroke-aop-dark-blue-500 hover:[&>svg]:stroke-blue-800"
                  onClick={() =>
                    window.Intercom(
                      "showNewMessage",
                      t("page.available-integrations.integration.contact.default-message"),
                    )
                  }
                >
                  <Icon name={messagesIcon} />
                </button>
              </div>
              {integration?.content && <LinkFormatter>{integration?.content}</LinkFormatter>}
              {integration?.external_link ? (
                <p className="py-2 text-caption text-grey-700">
                  <Anchor to={integration.external_link} isExternal style="inherit" withIcon inNewTab>
                    {integration.external_link}
                  </Anchor>
                </p>
              ) : null}
            </div>
          </div>
        </PageGrid.Item>
      </PageGrid.Grid>
    </DocumentPaper>
  );
}
