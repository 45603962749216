import type { ChatDto } from "api/types";
import iconPlus from "assets/icons/plus.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { CreateChatModal } from "modules/chats/components/CreateChatModal";
import { useTranslation } from "react-i18next";

import { ChatListHeader } from "./components/ChatListHeader";
import { ChatListView } from "./components/ChatListView";

export interface ChatsListLayoutProps {
  chats: ChatDto[];
  hasMoreChats: boolean;
  fetchMoreChats: () => void;
  isLoadingChats: boolean;
  isFetchingMoreChats: boolean;
}

export function Layout({
  chats,
  isLoadingChats,
  isFetchingMoreChats,
  hasMoreChats,
  fetchMoreChats,
}: ChatsListLayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const [isCreateChatModalOpened, createChatModalOpenHandler] = useBool(false);
  const hasPermission = usePermission();
  const sessionUser = useSessionUser();

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.chats.title")}
      subTitle={t("page.chats.subtitle")}
      actions={
        <>
          <Button
            className="hidden md:block"
            data-testid="create-chat"
            title={
              !sessionUser.chatEnabled || !hasPermission((x) => x.userManagement.canListUsers)
                ? t("page.chats.new-chat.disabled")
                : undefined
            }
            disabled={!sessionUser.chatEnabled || !hasPermission((x) => x.userManagement.canListUsers)}
            onClick={createChatModalOpenHandler.setTrue}
          >
            {t("page.chats.new-chat")}
          </Button>
          <IconButton
            styling="primary"
            className="md:hidden"
            disabled={!sessionUser.chatEnabled || !hasPermission((x) => x.userManagement.canListUsers)}
            title={
              !sessionUser.chatEnabled || !hasPermission((x) => x.userManagement.canListUsers)
                ? t("page.chats.new-chat.disabled")
                : t("page.chats.new-chat")
            }
            onClick={createChatModalOpenHandler.setTrue}
            isCircular
            icon={iconPlus}
          />
        </>
      }
      header={<ChatListHeader />}
    >
      <ChatListView
        isMoreChatAvailable={hasMoreChats}
        isFetchingInitialChats={isLoadingChats}
        {...{ chats, isFetchingMoreChats, fetchMoreChats }}
      />
      <CreateChatModal isOpened={isCreateChatModalOpened} onOpenChange={createChatModalOpenHandler.set} />
    </DocumentPaper>
  );
}
