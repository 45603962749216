import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

const useDeleteSurvey = () => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const navigate = useNavigate();
  const slug = useSlug();

  return useMutation({
    mutationFn: ({ surveyId }: { surveyId: string }) => api.deleteSurveysByIdV1(surveyId),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.surveys.actions.delete.notifications.success") });

      void navigate(routes.surveys.list({ slug }));
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.surveys.actions.delete.notifications.failed") });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SURVEYS(projectId) });
    },
  });
};

const useAddFollowUpDate = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const projectId = useProjectId();

  return useMutation({
    mutationFn: ({ surveyId }: { surveyId: string }) => api.postSurveysFollowUpV1(surveyId),
    onSuccess: (_, payload) => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SURVEY_DETAILS(projectId, payload.surveyId) });
    },
  });
};

export const surveyMutations = { useDeleteSurvey, useAddFollowUpDate };
