import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { isDocumentUploaded } from "components/DocumentInput/useDocumentFile";
import type { FormImage } from "components/ImageInput/useImageInput";
import { isImageUploaded } from "components/ImageInput/useImageInput";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { isVideoUploaded } from "components/VideoInput/useVideoInput";
import type { TFuncKey, TFunction } from "i18next";
import type { Validate } from "react-hook-form";

import {
  MAXIMUM_DOCUMENT_FILE_SIZE_IN_MEGA_BYTES,
  MAXIMUM_IMAGE_FILE_SIZE_IN_MEGA_BYTES,
  MAXIMUM_VIDEO_FILE_SIZE_IN_MEGA_BYTES,
  validateSize,
} from "./file-size";
import { ALLOWED_FILE_TYPES } from "./file-types";
import { isValidString } from "./validation";

export function createRequiredStringRule(t: TFunction, fieldName: TFuncKey, interpolation?: object): Validate<any, {}> {
  return (value: string | undefined) => {
    if (value == null || !isValidString(value)) {
      return t("components.form.error.required", {
        inputName: interpolation ? t(fieldName, interpolation as any) : t(fieldName),
      });
    }
  };
}

export function createTrimmedMinLengthStringRule({
  t,
  fieldName,
  minLength,
}: {
  t: TFunction;
  fieldName: TFuncKey;
  minLength: number;
}): Validate<any, {}> {
  return (value: string | undefined) => {
    if (value == null || value.trim().length < minLength) {
      return t("components.form.error.min-length", {
        inputName: t(fieldName),
        length: minLength,
      });
    }
  };
}

export function createTrimmedMaxLengthStringRule({
  t,
  fieldName,
  maxLength,
}: {
  t: TFunction;
  fieldName: TFuncKey;
  maxLength: number;
}): Validate<any, {}> {
  return (value: string | undefined) => {
    if (value == null || value.trim().length > maxLength) {
      return t("components.form.error.max-length", {
        inputName: t(fieldName),
        length: maxLength,
      });
    }
  };
}

// Image input
export function createImageSizeRule({ t, onError }: { t: TFunction; onError?: () => void }): Validate<any, {}> {
  return (images: FormImage[]) => {
    const isSizeExceeded = validateSize(t, images, MAXIMUM_IMAGE_FILE_SIZE_IN_MEGA_BYTES);

    if (isSizeExceeded && onError) {
      onError();
    }

    return isSizeExceeded;
  };
}

export function createImageTypeRule({ t, onError }: { t: TFunction; onError?: () => void }): Validate<any, {}> {
  return (images: FormImage[]) => {
    const isTypeInvalid = images.some(
      (image) => !isImageUploaded(image) && !ALLOWED_FILE_TYPES.IMAGE.includes(image.file.type),
    );

    if (isTypeInvalid) {
      if (onError) {
        onError();
      }

      return t("components.form.error.file-type-not-allowed");
    }
  };
}

// Video input
export function createVideoSizeRule({ t, onError }: { t: TFunction; onError?: () => void }): Validate<any, {}> {
  return (videos: FormVideo[]) => {
    const isSizeExceeded = validateSize(t, videos, MAXIMUM_VIDEO_FILE_SIZE_IN_MEGA_BYTES);

    if (isSizeExceeded && onError) {
      onError();
    }

    return isSizeExceeded;
  };
}

export function createVideoTypeRule({ t, onError }: { t: TFunction; onError?: () => void }): Validate<any, {}> {
  return (videos: FormVideo[]) => {
    const isTypeInvalid = videos.some(
      (video) => !isVideoUploaded(video) && !ALLOWED_FILE_TYPES.VIDEO.includes(video.file.type),
    );

    if (isTypeInvalid) {
      if (onError) {
        onError();
      }

      return t("components.form.error.file-type-not-allowed");
    }
  };
}

// Document input
export function createDocumentSizeRule({ t, onError }: { t: TFunction; onError?: () => void }): Validate<any, {}> {
  return (documents: FormDocument[]) => {
    const isSizeExceeded = validateSize(t, documents, MAXIMUM_DOCUMENT_FILE_SIZE_IN_MEGA_BYTES);

    if (isSizeExceeded && onError) {
      onError();
    }

    return isSizeExceeded;
  };
}

export function createDocumentTypeRule({ t, onError }: { t: TFunction; onError?: () => void }): Validate<any, {}> {
  return (documents: FormDocument[]) => {
    const isTypeInvalid = documents.some(
      (document) => !isDocumentUploaded(document) && !ALLOWED_FILE_TYPES.DOCUMENT.includes(document.file.type),
    );

    if (isTypeInvalid) {
      if (onError) {
        onError();
      }

      return t("components.form.error.file-type-not-allowed");
    }
  };
}
