import { useQuery } from "@tanstack/react-query";
import type { AdminTicketDetailsDto, AppTicketDto } from "api/types";
import { Button } from "components/Button/Button";
import { Gallery } from "components/Gallery/Gallery";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { t } from "i18next";
import { useTicketQueries } from "queries/tickets";

interface TicketContentProps {
  ticket: AdminTicketDetailsDto | AppTicketDto;
}

export function TicketContent({ ticket }: TicketContentProps): React.ReactNode {
  const [isTranslated, translationHandler] = useBool(false);

  const sessionUser = useSessionUser();
  const ticketQueries = useTicketQueries();
  const { data: translation, isLoading: isLoadingTranslation } = useQuery({
    ...ticketQueries.ticketTranslation(ticket.id, sessionUser.language.id),
    enabled: isTranslated,
  });

  const title = isTranslated && translation ? translation.title : ticket.title;
  const content = isTranslated && translation ? translation.content : ticket.content;

  return (
    <div className="rounded-lg bg-white p-4">
      <div className="flex flex-col gap-2">
        <span data-testid="ticket-title" className="text-headline2">
          {title}
        </span>
        {content && (
          <p data-testid="ticket-content">
            <LinkFormatter>{content}</LinkFormatter>
          </p>
        )}
        {ticket.languageIsoCode !== sessionUser.language.id && (
          <span className="mt-2 block">
            <Button styling="ghostPrimary" isLoading={isLoadingTranslation} onClick={translationHandler.toggle}>
              {translation
                ? t("page.tickets.details.activities.comment.translate.original")
                : t("page.tickets.details.activities.comment.translate")}
            </Button>
          </span>
        )}
        {ticket.images && ticket.images.length > 0 && <Gallery images={ticket.images} />}
      </div>
    </div>
  );
}
