import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { UserRoleDto } from "api/types";
import iconCopy01 from "assets/icons/copy-01.svg";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { ContextMenuLegacy, type ContextMenuLegacyAction } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { Icon } from "components/Icon/Icon";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Table } from "components/Table/Table";
import { useSlug } from "hooks/useSlug";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

export interface LayoutProps {
  roles: UserRoleDto[];
  deleteRole: (roleId: string) => Promise<unknown>;
}

export function Layout(props: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { deleteRole } = props;
  const { t } = useTranslation();
  const { componentProps, openDeleteModal } = useDeleteModal<string>("role-delete-modal");
  const navigate = useNavigate();

  const columns = useMemo(() => {
    function getRoleTypeTranslation(roleType: UserRoleDto["type"]): string {
      switch (roleType) {
        case "resident":
          return t("model.role.type.resident");
        case "projectOwner":
          return t("model.role.type.project-owner");
        case "readOnly":
          return t("model.role.type.readOnly");
        case "maintenance":
          return t("model.role.type.maintenance");
        case "caretaker":
          return t("model.role.type.caretaker");
        case "servicePartner":
          return t("model.role.type.service-partner");
        default:
          console.error(`Unknown user role type "${roleType}"`);

          return "";
      }
    }

    const helper = createColumnHelper<UserRoleDto>();

    return [
      helper.accessor("name", {
        header: t("model.role.name"),
        cell: (cell) => (
          <Anchor data-testid="role-details-page-link" to={routes.roles.details({ slug, id: cell.row.original.id })}>
            {cell.getValue()}
          </Anchor>
        ),
      }),
      helper.accessor("type", {
        header: t("model.role.type"),
        cell: (cell) => <div className="text-grey-600">{getRoleTypeTranslation(cell.getValue())}</div>,
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          let actions: ContextMenuLegacyAction[] = [];
          if (!cell.row.original.locked) {
            actions = [
              {
                text: t("common.action.edit"),
                icon: <Icon name={iconEdit05} />,
                callback: () => {
                  void navigate(routes.roles.edit({ slug, id: cell.getValue() }));
                },
                dataTestId: "context-menu-edit-btn",
              },
              {
                text: t("common.action.duplicate"),
                icon: <Icon name={iconCopy01} />,
                callback: () =>
                  void navigate(routes.roles.create({ slug }), {
                    state: { roleId: cell.getValue() },
                  }),
                dataTestId: "context-menu-duplicate-btn",
              },
            ];
          }

          if (!cell.row.original.default) {
            actions.push({
              text: t("common.action.delete"),
              icon: <Icon name={iconTrash02} />,
              callback: () => openDeleteModal(cell.getValue()),
              dataTestId: "context-menu-delete-btn",
            });
          }

          return (
            <div className="flex justify-end px-2">
              <ContextMenuLegacy {...{ actions }} />
            </div>
          );
        },
        maxSize: 50,
        minSize: 50,
        size: 50,
      }),
    ];
  }, [t, slug, navigate, openDeleteModal]);

  const tableInstance = useReactTable<UserRoleDto>({
    columns,
    data: props.roles,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <DocumentPaper
      title={t("page.role.list.title")}
      theme="minimal"
      actions={
        <Button type="link" href={routes.roles.create({ slug })} data-testid="role-create-page-link">
          {t("model.role.action.create")}
        </Button>
      }
    >
      <Table table={tableInstance} getRowProps={() => ({ "data-testid": "role-list-item" })} data-testid="role-list" />
      <DeleteModal<string>
        title={t("model.role.action.delete.confirmation")}
        description={t("model.role.action.delete.description")}
        onDelete={deleteRole}
        {...componentProps}
      />
    </DocumentPaper>
  );
}
