import type { SelfUpdateRequest } from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useSlug } from "hooks/useSlug";
import { userMutations } from "queries/users/mutations";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router";
import { routes } from "routes";

import type { FormValues, LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: LoaderProps): React.ReactNode {
  const slug = useSlug();
  const sessionUser = useSessionUser();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uploadFormImage, isUploadingImage } = useUploadImage();

  const { mutate, isPending: isSubmitting } = userMutations.useUpdateSelfMutation({
    onError() {
      showFlashToast({ title: t("page.onboarding.user-details.submit.error"), type: "error" });
    },
    onSuccess() {
      void navigate(routes.onboardingFlow.groups({ slug }));
    },
  });

  if (sessionUser.onboardedAt) {
    return <Navigate to="/" />;
  }

  return props.children({
    defaultValues: {
      firstName: sessionUser.firstName,
      lastName: sessionUser.lastName,
      biography: undefined,
      company: sessionUser.company?.name,
      occupation: undefined,
    },
    isCompanyProject: sessionUser.project.type === "companyBased",
    async onSubmit(formValues: FormValues) {
      const payload: SelfUpdateRequest = {
        ...formValues,
        languageId: sessionUser.language.id,
        hideEmail: sessionUser.emailHidden,
        hidePhoneNumber: sessionUser.phoneNumberHidden,
        hideHouseNumber: sessionUser.hideHouseNumber,
        enableChat: sessionUser.chatEnabled,
        phoneNumber: sessionUser.phoneNumber,
      };

      if (formValues.avatar && formValues.avatar.length > 0) {
        const avatarImage = await uploadFormImage(formValues.avatar?.[0]);

        payload.avatarId = avatarImage?.id;
      }

      mutate(payload);
    },
    isSubmitting: isSubmitting || isUploadingImage,
  });
}
