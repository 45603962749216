import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { HttpResponse, RemsStatusDto } from "api/types";
import iconRefreshCw01 from "assets/icons/refresh-cw-01.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { LoadingIcon } from "components/Icons/Icons";
import { Select } from "components/Select/Select";
import { useProjectId } from "hooks/Network/useProjectId";
import { RemsStatus } from "modules/tickets/components/RemsStatus";
import { QUERY_KEYS } from "query-keys";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";
import type { ApiResponseType } from "types/api-types";

export function TicketRemsCard({
  ticketId,
  ticketIsCollective,
  rems,
  withOutline,
}: {
  ticketId: string;
  ticketIsCollective: boolean;
  rems: RemsStatusDto;
  withOutline?: boolean;
}): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();

  const contracts = useMemo(() => {
    if (ticketIsCollective) {
      return [
        {
          id: undefined,
          collective: true,
          description: t("page.tickets.rems.contracts.contract-collective"),
        },
        ...rems.contracts,
      ];
    }

    return rems.contracts;
  }, [rems.contracts, t, ticketIsCollective]);

  const [selectedContract, setSelectedContract] = useState(contracts[0]);
  if (!selectedContract && contracts.length) {
    setSelectedContract(contracts[0]);
  }

  function overwriteRems(response: HttpResponse<RemsStatusDto>) {
    queryClient.setQueryData(
      QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId),
      (oldData: ApiResponseType<"getAdminTicketsDetailsV1"> | undefined) => {
        if (!oldData) {
          return;
        }

        return {
          ...oldData,
          data: {
            ...oldData.data,
            rems: response.data,
          },
        };
      },
    );
  }

  const queryClient = useQueryClient();
  const api = useApi();
  const uploadTicketMutation = useMutation({
    mutationFn: () =>
      api.postAdminTicketsRemsSendTicketV1(
        ticketId,
        "collective" in selectedContract && selectedContract.collective
          ? {
              sendAsCollective: true,
            }
          : {
              contractId: selectedContract.id,
              sendAsCollective: false,
            },
      ),
    onSuccess(response) {
      overwriteRems(response);
    },
  });
  const syncTicketMutation = useMutation({
    mutationFn: () => api.postAdminTicketsRemsSyncV1(ticketId),
    onSuccess(response) {
      overwriteRems(response);
    },
  });

  return (
    <div
      className={twJoin(
        "flex flex-col gap-4 rounded-lg bg-white p-4",
        withOutline ? "border border-grey-100" : undefined,
      )}
    >
      <RemsStatus bigLogo rems={rems} isLoading={uploadTicketMutation.isPending || syncTicketMutation.isPending} />
      {rems.state === "errorGeneric" ? rems.errorDetails : null}
      {!rems.externalId && (rems.state === "ready" || rems.contracts.length > 0) && (
        <>
          {syncTicketMutation.isPending && <LoadingIcon className="mx-auto my-1.5 w-5" />}
          {!syncTicketMutation.isPending && (
            <div className="flex flex-col items-center gap-4">
              <div className="flex w-full flex-col gap-1">
                <div className="flex items-baseline justify-between gap-2">
                  <label htmlFor="rems-contract">
                    <span className="text-caption text-grey-700">{t("page.tickets.rems.contracts.title")}</span>
                  </label>
                  <IconButton
                    title={t("page.tickets.rems.actions.refresh-contracts")}
                    onClick={() => syncTicketMutation.mutate()}
                    disabled={uploadTicketMutation.isPending}
                    icon={iconRefreshCw01}
                  />
                </div>
                <Select
                  id="rems-contract"
                  items={contracts}
                  placeholder={t("page.tickets.rems.actions.no-contracts")}
                  keySelector={(x) => x.id || "collective"}
                  renderOption={(x) => x.description}
                  onChange={setSelectedContract}
                  selected={selectedContract}
                  disabled={uploadTicketMutation.isPending}
                />
              </div>
              <div>
                <Button isLoading={uploadTicketMutation.isPending} onClick={() => uploadTicketMutation.mutate()}>
                  {t("page.tickets.rems.actions.send-ticket")}
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      {rems.state === "errorGeneric" ||
      rems.state === "errorNoUserFound" ||
      rems.state === "errorNoContracts" ||
      rems.state === "notStarted" ? (
        <div className="mx-auto">
          <Button
            styling="secondary"
            isLoading={syncTicketMutation.isPending}
            onClick={() => syncTicketMutation.mutate()}
          >
            {t("page.tickets.rems.actions.sync")}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
