import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  CommentCreateRequest,
  CommentUpdateRequest,
  ManageReactionRequest,
  MessageCreateRequest,
  MessageStatusChangeRequest,
  MessageStatusChangeRequestV2,
  MessageUpdateRequest,
} from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useConfig } from "providers/ConfigProvider";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

const useAddMessage = () => {
  const api = useApi();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();

  return useMutation({
    mutationFn: ({ payload }: { payload: MessageCreateRequest }) => api.postMessagesV1(payload).then((x) => x.data),
    onError() {
      showFlashToast({ type: "error", title: t("page.message-feed.create.notifications.error") });
    },
  });
};

const useGetQuickReplyLink = () => {
  const api = useApi();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const dashboardUrl = useConfig("newDashboardRootUrl");

  return useMutation({
    mutationFn: ({ messageId }: { messageId: string }) =>
      api.postQuickReplyMessageCreateTokenV2({ messageId: messageId }).then((x) => x.data),
    onSuccess: async (token) => {
      const quickReplyMessagePath = routes.quickReply.message({ token: `V2-${token}` });

      await navigator.clipboard.writeText(`${dashboardUrl}${quickReplyMessagePath}`);

      showFlashToast({ type: "success", title: t("component.community-post.quick-reply-link.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.quick-reply-link.error") });
    },
  });
};

const useDeleteMessage = () => {
  const api = useApi();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const sessionUser = useSessionUser();
  const queryClient = useQueryClient();
  const projectId = useProjectId();

  return useMutation({
    mutationFn: ({
      messageId,
      reason,
      details,
    }: { messageId: string } & Omit<MessageStatusChangeRequestV2, "newStatus">) =>
      api.postMessagesChangeStatusV2(messageId, { newStatus: "deleted", reason: reason, details: details }),
    onSuccess: (_, payload) => {
      showFlashToast({ type: "success", title: t("component.community-post.delete.success") });

      if (sessionUser.isSuperAdmin) {
        void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, payload.messageId) });
      } else {
        void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES(projectId) });
      }
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.delete.error") });
    },
  });
};

const useUpdateMessageStatus = () => {
  const api = useApi();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const sessionUser = useSessionUser();
  const queryClient = useQueryClient();
  const projectId = useProjectId();

  return useMutation({
    mutationFn: ({ messageId, data }: { messageId: string; data: MessageStatusChangeRequestV2 }) =>
      api.postMessagesChangeStatusV2(messageId, data),
    onSuccess: (_, payload) => {
      let toastTitle = t("component.community-post.archive.success");
      if (payload.data.newStatus === "deleted") {
        toastTitle = t("component.community-post.delete.success");
      }

      showFlashToast({ type: "success", title: toastTitle });

      if (sessionUser.isSuperAdmin) {
        void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, payload.messageId) });
      } else {
        void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES(projectId) });
      }
    },
    onError: (_, payload) => {
      let toastTitle = t("component.community-post.archive.error");
      if (payload.data.newStatus === "deleted") {
        toastTitle = t("component.community-post.delete.error");
      }

      showFlashToast({ type: "error", title: toastTitle });
    },
  });
};

const useArchiveMessage = () => {
  const api = useApi();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const sessionUser = useSessionUser();
  const queryClient = useQueryClient();
  const projectId = useProjectId();

  return useMutation({
    mutationFn: ({
      messageId,
      reason,
      details,
    }: { messageId: string } & Omit<MessageStatusChangeRequest, "newStatus">) =>
      api.postMessagesChangeStatusV1(messageId, { newStatus: "archived", reason: reason, details: details }),
    onSuccess: (_, payload) => {
      showFlashToast({ type: "success", title: t("component.community-post.archive.success") });

      if (sessionUser.isSuperAdmin) {
        void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, payload.messageId) });
      } else {
        void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES(projectId) });
      }
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.archive.error") });
    },
  });
};

const useUpdateMessage = () => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const projectId = useProjectId();

  return useMutation({
    mutationFn: ({
      messageId,
      payload,
    }: {
      messageId: string;
      payload: MessageUpdateRequest;
      successMessage: string;
      failureMessage: string;
    }) => api.putMessagesV1(messageId, payload),
    onSuccess: (_, payload) => {
      showFlashToast({ type: "success", title: payload.successMessage });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, payload.messageId) });
    },
    onError(_, payload) {
      showFlashToast({ type: "error", title: payload.failureMessage });
    },
  });
};

const useDeleteComment = () => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ messageId, commentId }: { messageId: string; commentId: string }) =>
      api.deleteMessagesCommentsByIdV1(messageId, commentId).then((x) => x.data),
    onSuccess: (_, payload) => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, payload.messageId) });
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.MESSAGE_COMMENTS_DETAILS(projectId, payload.messageId),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_COMMENTS(projectId, payload.messageId) });
    },
    onError() {
      showFlashToast({ type: "error", title: t("component.community-post.comments.delete.error") });
    },
  });
};

const useAddComment = () => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const projectId = useProjectId();

  return useMutation({
    mutationFn: ({
      messageId,
      payload,
    }: {
      messageId: string;
      payload: CommentCreateRequest;
      failureMessage: string;
    }) => api.postMessagesCommentsV1(messageId, payload).then((x) => x.data),
    onSuccess: (_, payload) => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, payload.messageId) });
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.MESSAGE_COMMENTS_DETAILS(projectId, payload.messageId),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_COMMENTS(projectId, payload.messageId) });
    },
    onError(_, { failureMessage }) {
      showFlashToast({ type: "error", title: failureMessage });
    },
  });
};

const useUpdateComment = () => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const projectId = useProjectId();

  return useMutation({
    mutationFn: ({
      messageId,
      payload,
      commentId,
    }: {
      messageId: string;
      payload: CommentUpdateRequest;
      commentId: string;
      failureMessage: string;
    }) => api.putMessagesCommentsV1(messageId, commentId, payload).then((x) => x.data),
    onSuccess: (_, payload) => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, payload.messageId) });
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.MESSAGE_COMMENTS_DETAILS(projectId, payload.messageId),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_COMMENTS(projectId, payload.messageId) });
    },
    onError(_, { failureMessage }) {
      showFlashToast({ type: "error", title: failureMessage });
    },
  });
};

const useUpdateMessageReaction = () => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ messageId, data }: { messageId: string; data: ManageReactionRequest }) =>
      api.putMessagesReactV1(messageId, data),
    onSuccess: (_, payload) => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, payload.messageId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_REACTIONS(projectId, payload.messageId) });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.like.error") });
    },
  });
};

const useUpdateCommentReaction = () => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({
      messageId,
      commentId,
      data,
    }: {
      messageId: string;
      commentId: string;
      data: ManageReactionRequest;
    }) => api.putMessagesCommentsReactV1(messageId, commentId, data),
    onSuccess: (_, payload) => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_COMMENTS(projectId, payload.messageId) });
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.MESSAGE_COMMENTS_DETAILS(projectId, payload.messageId),
      });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.comment.like.error") });
    },
  });
};

export const communityFeedMutations = {
  useAddMessage,
  useUpdateMessage,
  useArchiveMessage,
  useDeleteMessage,
  useUpdateMessageStatus,
  useGetQuickReplyLink,
  useDeleteComment,
  useAddComment,
  useUpdateComment,
  useUpdateMessageReaction,
  useUpdateCommentReaction,
};
