import { useInfiniteQuery } from "@tanstack/react-query";
import type { CommentDto } from "api/types";
import iconArrowDown from "assets/icons/arrow-down.svg";
import { Button } from "components/Button/Button";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { isVideoUploaded, useVideoInput } from "components/VideoInput/useVideoInput";
import { parseISO } from "date-fns";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { useBool } from "hooks/useBool";
import { useSignalRHub, useSignalRSubscription } from "hooks/useSignalR";
import { sortBy } from "lodash-es";
import { useCommunityFeedQueries } from "queries/communityFeed";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { CreateCommentPayload, EditCommentPayload } from "./Comment";
import { Comment } from "./Comment";

interface Props {
  messageId: string;
  commentPost: ({ parentId, comment, images, videos, failureMessage }: CreateCommentPayload) => Promise<void>;
  editComment: ({ commentId, comment, images, videos, failureMessage }: EditCommentPayload) => Promise<void>;
  deleteComment: (commentId: string) => void;
  onViewCommentReactions: (data: { commentId: string; likeCount: number }) => void;
  canComment: boolean;
}

export function CommentList({
  messageId,
  commentPost,
  editComment,
  deleteComment,
  onViewCommentReactions,
  canComment,
}: Props): React.ReactNode {
  const [images, setImages] = useState<FormImage[]>([]);
  const [videos, setVideos] = useState<FormVideo[]>([]);
  const [showNewCommentPill, newCommentPillHandler] = useBool(false);

  const [editingComment, setEditingComment] = useState<string | undefined>(undefined);
  const [deletingComment, setDeletingComment] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const sessionUser = useSessionUser();

  const communityFeedQueries = useCommunityFeedQueries();
  const {
    data: commentsData,
    fetchNextPage: fetchMoreComments,
    refetch: refetchComments,
    isFetching: isFetchingComments,
    hasNextPage: hasMoreComments,
  } = useInfiniteQuery({
    ...communityFeedQueries.commentsInfinite(messageId),
  });

  const { getValues, setValue, control, register } = useForm<{ commentValue: string }>();
  const commentValue = useWatch({ control, name: "commentValue" });
  const { signalRConnection } = useSignalRHub("community-feed-detail-hub", {
    query: `userId=${sessionUser.id}&entityId=${messageId}&entityType=Message`,
  });

  const onNewComment = useCallback(
    (...args: [{ entityId: string; entityType: "message" | "comment" | "poll" | "survey"; authorId: string }]) => {
      if (args[0].authorId === sessionUser.id) {
        return;
      }

      newCommentPillHandler.setTrue();
    },
    [sessionUser, newCommentPillHandler],
  );
  useSignalRSubscription(signalRConnection, "NewCommentOnMessage", onNewComment);

  useEffect(() => {
    register("commentValue");
  }, [register]);

  const { addImages, removeImage, removeImages } = useImageInput({
    selectedImages: images,
    onChange: setImages,
  });
  const { uploadFormVideo } = useUploadVideo({
    onProgress: ({ name, progress }) => {
      setVideos((prevState) =>
        prevState.map((video) => {
          if (!isVideoUploaded(video) && video.file.name === name) {
            return { ...video, uploadProgress: progress };
          } else {
            return video;
          }
        }),
      );
    },
  });
  const { addVideos, removeVideo, removeVideos } = useVideoInput({
    selectedVideos: videos,
    onChange: setVideos,
    uploadFn: uploadFormVideo,
  });

  async function handleAddComment() {
    const { commentValue } = getValues();

    if (editingComment) {
      await editComment({
        comment: commentValue,
        commentId: editingComment,
        images,
        videos,
        failureMessage: t("component.community-post.comments.edit-error"),
      });
      setEditingComment(undefined);
    } else {
      await commentPost({
        comment: commentValue,
        images,
        videos,
        failureMessage: t("component.community-post.comments.error"),
      });
    }

    removeImages();
    removeVideos();
    setValue("commentValue", "");
  }

  const handleEditComment = useCallback(
    (comment: CommentDto) => {
      setValue("commentValue", comment.content || "");
      if (comment.image) {
        setImages([comment.image]);
      }

      if (comment.videos && comment.videos.length > 0) {
        setVideos(comment.videos);
      }

      setEditingComment(comment.id);
    },
    [setValue, setImages, setEditingComment],
  );

  const handleDeleteComment = useCallback((comment: CommentDto) => {
    setDeletingComment(comment.id);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setValue("commentValue", "");
    removeImages();
    removeVideos();
    setEditingComment(undefined);
  }, [setValue, removeImages, removeVideos]);

  const onChangeContent = useCallback((value: string) => setValue("commentValue", value), [setValue]);

  async function handleLoadMoreComments() {
    newCommentPillHandler.setFalse();

    await refetchComments();
  }

  const allComments = useMemo(() => {
    return commentsData?.pages.flatMap((x) => x.items ?? []) ?? [];
  }, [commentsData?.pages]);
  const commentsByDate = useMemo(() => sortBy(allComments, (x) => parseISO(x.postedAt).valueOf()), [allComments]);

  return (
    <>
      <div className="relative flex flex-col gap-4">
        {hasMoreComments && (
          <Button styling="ghostPrimary" data-testid="comments-load-more" onClick={fetchMoreComments}>
            <span className="text-caption">{t("component.community-post.comments.see-more")}</span>
          </Button>
        )}
        {isFetchingComments && <LoadingIcon className="inset-0 mx-auto my-4 w-6" />}
        <div className="flex flex-col gap-6">
          {commentsByDate.map((comment) => (
            <Comment
              key={comment.id}
              comment={comment}
              onEdit={handleEditComment}
              onDelete={handleDeleteComment}
              messageId={messageId}
              onAddComment={commentPost}
              onEditComment={editComment}
              onViewCommentReactions={() =>
                onViewCommentReactions({ commentId: comment.id, likeCount: comment.totalLikesCount })
              }
            />
          ))}
        </div>
        {(canComment || editingComment) && !isFetchingComments && (
          <form>
            <div className="flex items-end">
              <CommentFieldWithAvatar
                user={sessionUser}
                value={commentValue}
                onChange={onChangeContent}
                images={images}
                videos={videos}
                onSubmit={handleAddComment}
                isEdit={editingComment !== undefined}
                onCancel={handleCancelEdit}
                allowedAttachments={["image", "video"]}
                onRemoveVideo={removeVideo}
                onAddVideo={addVideos}
                onRemoveImage={removeImage}
                onAddImages={addImages}
              />
            </div>
          </form>
        )}
        {showNewCommentPill && (
          <Button
            icon={<Icon name={iconArrowDown} />}
            className="absolute bottom-12 left-1/2"
            onClick={handleLoadMoreComments}
            isCircular
          >
            {t("component.community-post.comments.load-new")}
          </Button>
        )}
      </div>
      <ConfirmModal
        isOpened={!!deletingComment}
        title={t("component.community-post.comments.delete.modal.title")}
        description={t("component.community-post.comments.delete.modal.description")}
        isLoading={false}
        theme="danger"
        onReject={() => setDeletingComment(undefined)}
        onOpenChange={(state) => {
          if (!state) {
            setDeletingComment(undefined);
          }
        }}
        rejectBtnProps={{
          "data-testid": "delete-comment-modal-cancel",
        }}
        onResolve={() => {
          deleteComment(deletingComment!);
          setDeletingComment(undefined);
        }}
        resolveBtnProps={{
          text: t("common.action.delete"),
          "data-testid": "delete-comment-modal-confirm",
        }}
        shouldCloseOnEsc
        data-testid="cancel-edit-modal"
      />
    </>
  );
}
