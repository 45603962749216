import type { ServicePartnerPageDto } from "api/types";
import iconEdit05 from "assets/icons/edit-05.svg";
import emailIcon from "assets/icons/mail-01.svg";
import connectionIcon from "assets/icons/marker-pin-01.svg";
import phoneIcon from "assets/icons/phone.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import iconUsersCheck from "assets/icons/users-check.svg";
import { Card, CardContent, CardDescription, CardHeader, CardImage } from "components/Card/Card";
import type { ContextMenuItem } from "components/ContextMenu/ContextMenu";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

interface ServiceCardProps {
  service: ServicePartnerPageDto;
  "data-testid"?: string;
  onEdit: () => void;
  onDelete: () => void;
}

export function ServiceCard({
  service,
  "data-testid": dataTestId,
  onEdit,
  onDelete,
}: ServiceCardProps): React.ReactNode {
  const slug = useSlug();
  const sessionUser = useSessionUser();
  const { t } = useTranslation();

  const menuActions: ContextMenuItem[] = [
    {
      labels: {
        default: t("common.action.edit"),
      },
      value: "edit",
      icon: iconEdit05,
      "data-testid": "context-menu-edit-btn",
      type: "button",
      isHidden: !service.canEdit,
      callbackFn: onEdit,
    },
    {
      labels: {
        default: t("common.action.delete"),
      },
      value: "delete",
      icon: iconTrash02,
      "data-testid": "context-menu-delete-btn",
      type: "button",
      isHidden: !service.canDelete,
      callbackFn: onDelete,
    },
  ];

  const image = service.headerImage || sessionUser.project.backgroundImage || null;

  return (
    <Card data-testid={dataTestId} link={routes.servicePartners.details({ slug, id: service.id })}>
      <CardImage
        image={
          // eslint-disable-next-line no-nested-ternary
          image ? image : sessionUser.project.backgroundImage ? sessionUser.project.backgroundImage : null
        }
        labels={[
          <Label key="service-type" theme="blue">
            {service.type}
          </Label>,
          service.connection && (
            <Label key="connection-name" icon={connectionIcon} theme="gray">
              {service.connection.name}
            </Label>
          ),
          service.hasAudience && sessionUser.isAdmin && <Label theme="blue" icon={iconUsersCheck} />,
        ]}
      />
      <CardContent>
        <CardHeader
          title={service.title}
          titleLink={routes.servicePartners.details({ slug, id: service.id })}
          menuActions={menuActions}
        />

        <div className="flex flex-col gap-2">
          {service.phoneNumber && (
            <div className="flex w-fit items-center gap-1 rounded-lg border border-grey-100 px-1 py-0.5 text-grey-600">
              <Icon name={phoneIcon} />
              <span>{service.phoneNumber}</span>
            </div>
          )}
          {service.email && (
            <div className="flex w-fit items-center gap-1 rounded-lg border border-grey-100 px-1 py-0.5 text-grey-600">
              <Icon name={emailIcon} />
              <span>{service.email}</span>
            </div>
          )}
        </div>

        {service.subtitle && <CardDescription description={service.subtitle} />}
      </CardContent>
    </Card>
  );
}
