import { useInfiniteQuery } from "@tanstack/react-query";
import { LoadingIcon } from "components/Icons/Icons";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { useOnIntersection } from "hooks/useOnIntersection";
import { useCommunityFeedQueries } from "queries/communityFeed";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ReactionListItem } from "./ReactionListItem";

type CommentReactionListModalProps = ModalBaseProps & {
  messageId: string;
  commentId?: string;
  /** Likes gotten from already available data, so we don't show 0 likes during loading */
  initialLikes?: number;
};

export function CommentReactionListModal({
  isOpened,
  onOpenChange,
  messageId,
  commentId,
  initialLikes,
}: CommentReactionListModalProps): React.ReactNode {
  const { t } = useTranslation();

  const communityFeedQueries = useCommunityFeedQueries();
  const {
    data: commentReactionsData,
    isFetching: isFetchingCommentReactions,
    hasNextPage: hasMoreCommentReactions,
    isFetchingNextPage: isFetchingMoreCommentReactions,
    fetchNextPage: fetchMoreCommentReactions,
    error: commentReactionsError,
  } = useInfiniteQuery({
    ...communityFeedQueries.commentReactionsInfinite({
      messageId,
      commentId: commentId!,
    }),
    enabled: Boolean(commentId),
  });

  const commentReactions = useMemo(
    () => commentReactionsData?.pages.flatMap((x) => x.items ?? []) ?? [],
    [commentReactionsData],
  );
  const amountCommentReactions = commentReactionsData?.pages[0].total ?? initialLikes ?? 0;

  const loaderRef = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isFetchingMoreCommentReactions && hasMoreCommentReactions) {
        void fetchMoreCommentReactions();
      }
    }, [fetchMoreCommentReactions, hasMoreCommentReactions, isFetchingMoreCommentReactions]),
  });

  return (
    <Modal.Root
      size="sm"
      title={t("component.community-post.comment.likes.modal.title")}
      description={t("component.community-post.comment.likes.modal.description-total", {
        count: amountCommentReactions,
      })}
      data-testid="comment-reactions-modal"
      {...{ isOpened, onOpenChange }}
    >
      <div className="flex flex-col gap-4" data-testid="comment-likes-modal-content">
        {isFetchingCommentReactions && <LoadingIcon className="inset-0 mx-auto my-4 w-6" />}
        {!isFetchingCommentReactions &&
          (commentReactionsError || !commentReactions.length || commentReactions.length === 0) && (
            <span className="text-caption">{t("component.community-post.comment.likes.modal.no-data")}</span>
          )}
        {!isFetchingCommentReactions && (
          <div className="max-h-64 overflow-y-auto">
            <ul className="flex flex-col gap-4">
              {commentReactions.map((commentReaction) => (
                <ReactionListItem key={commentReaction.author.id} reaction={commentReaction} />
              ))}
            </ul>
            {hasMoreCommentReactions && (
              <div className="p-4" ref={loaderRef}>
                <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
              </div>
            )}
          </div>
        )}
      </div>
    </Modal.Root>
  );
}
