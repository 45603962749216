import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { BuildingDto } from "api/types";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { ContextMenu, type ContextMenuItem } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Table } from "components/Table/Table";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { canManageBuildings } from "modules/buildings/permissions";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

export interface LayoutProps {
  buildings: BuildingDto[];
  onDelete: (building: BuildingDto) => Promise<unknown>;
}

export function Layout({ buildings, onDelete }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<BuildingDto>("buildings-delete-modal");
  const hasPermission = usePermission();

  const columns = useMemo(() => {
    const helper = createColumnHelper<BuildingDto>();

    return [
      helper.accessor("name", {
        header: t("page.buildings.list.name"),
        cell: (cell) =>
          hasPermission(canManageBuildings) ? (
            <Anchor to={routes.buildings.edit({ slug, id: cell.row.original.id })}>{cell.getValue()}</Anchor>
          ) : (
            <span>{cell.getValue()}</span>
          ),
      }),

      helper.accessor("addressCount", {
        header: t("page.buildings.list.address-count"),
        cell: (cell) => <div>{t("page.buildings.list.address-item", { count: cell.getValue() })}</div>,
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          const menuActions: ContextMenuItem[] = [
            {
              labels: {
                default: t("common.action.edit"),
              },
              value: "edit",
              icon: iconEdit05,
              type: "link",
              href: routes.buildings.edit({ slug, id: cell.getValue() }),
              "data-testid": "context-menu-edit-btn",
              isHidden: !hasPermission(canManageBuildings),
            },
            {
              labels: {
                default: t("common.action.delete"),
              },
              value: "delete",
              icon: iconTrash02,
              type: "button",
              callbackFn: () => openDeleteModal(cell.row.original),
              "data-testid": "context-menu-delete-btn",
              isHidden: !hasPermission(canManageBuildings) || cell.row.original.addressCount > 0,
            },
          ];

          if (menuActions.length > 0) {
            return (
              <div className="flex justify-end px-2">
                <ContextMenu items={menuActions} />
              </div>
            );
          }
        },
        maxSize: 50,
        minSize: 50,
        size: 50,
      }),
    ];
  }, [t, hasPermission, slug, openDeleteModal]);

  const tableInstance = useReactTable<BuildingDto>({
    columns,
    data: buildings,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.buildings.title")}
      subTitle={t("page.buildings.subtitle")}
      actions={
        hasPermission(canManageBuildings) && (
          <Button type="link" href={routes.buildings.new({ slug })} data-testid="buildings-create-link">
            {t("page.buildings.actions.create")}
          </Button>
        )
      }
    >
      <DeleteModal
        title={t("page.buildings.actions.delete.title")}
        description={t("page.buildings.actions.delete.description")}
        onDelete={onDelete}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
      <div className="overflow-auto">
        {buildings.length === 0 ? (
          <div className="rounded-lg bg-white p-5">
            <p>{t("page.buildings.list.empty")}</p>
          </div>
        ) : (
          <Table<BuildingDto>
            table={tableInstance}
            data-testid="buildings-list"
            getRowProps={() => ({
              "data-testid": "buildings-item",
            })}
          />
        )}
      </div>
    </DocumentPaper>
  );
}
