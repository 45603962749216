import type { CommunityGroupV2Dto, ImageDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import iconCopy01 from "assets/icons/copy-01.svg";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconPower01 from "assets/icons/power-01.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import iconUsersCheck from "assets/icons/users-check.svg";
import award from "assets/images/award.svg";
import { StaticButton } from "components/Button/StaticButton";
import { ToggleButton } from "components/Button/ToggleButton";
import { Card, CardActions, CardContent, CardDescription, CardHeader, CardImage } from "components/Card/Card";
import type { ContextMenuItem } from "components/ContextMenu/ContextMenu";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { canClone } from "modules/community-groups/permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

interface GroupCardProps {
  group: CommunityGroupV2Dto;
  followButtonLabel: string;
  unfollowButtonLabel: string;
  onFollow: (id: string) => void;
  onUnfollow: (id: string) => void;
  onEnableGroup: (id: string) => void;
  onDisableGroup: (id: string) => void;
  onDeleteGroup: (id: string) => void;
}

export function GroupCard({
  group,
  followButtonLabel,
  unfollowButtonLabel,
  onFollow,
  onUnfollow,
  onEnableGroup,
  onDisableGroup,
  onDeleteGroup,
}: GroupCardProps): React.ReactNode {
  const { t } = useTranslation();
  const user = useSessionUser();
  const slug = useSlug();
  const hasPermission = usePermission();
  const navigate = useNavigate();

  const onClickToggleFollow = () => {
    if (group.isMember) onUnfollow(group.id);
    else onFollow(group.id);
  };

  const getImage = (): ImageDto | null => {
    if (group.image && !group.isResidentGroup) {
      return group.image;
    }

    return user.project.backgroundImage ?? null;
  };

  const getGroupType = (): string => {
    if (group.type === "realEstate") {
      return "realEstate";
    }
    if (group.projectConnectionGroupId) {
      return "projectConnection";
    }
    if (group.systemGroupId) {
      return "system";
    }

    return "regular";
  };

  const menuActions: ContextMenuItem[] = [
    {
      labels: {
        default: t("common.action.edit"),
      },
      value: "edit",
      icon: iconEdit05,
      "data-testid": "context-menu-edit-btn",
      type: "link",
      isHidden: !group.canEdit,
      // eslint-disable-next-line no-nested-ternary
      href: group.isResidentGroup
        ? routes.interestGroups.editResidentGroup({ slug, id: group.id })
        : group.type === "helpCategory"
          ? routes.helpCategories.edit({ slug, id: group.id })
          : routes.interestGroups.edit({ slug, id: group.id }),
    },
    {
      labels: {
        default: t("common.action.duplicate"),
      },
      value: "duplicate",
      icon: iconCopy01,
      "data-testid": "group-list-duplicate-item",
      type: "button",
      isHidden:
        !hasPermission(canClone) ||
        !!group.systemGroupId ||
        !!group.projectConnectionGroupId ||
        !!group.isResidentGroup,
      callbackFn: () => {
        const duplicateRoute =
          group.type === "helpCategory" ? routes.helpCategories.create : routes.interestGroups.create;
        void navigate(duplicateRoute({ slug }), { state: { groupId: group.id } });
      },
    },
    {
      labels: {
        default: t("common.action.enable"),
      },
      value: "enable",
      icon: iconPower01,
      "data-testid": "group-list-item-enable",
      type: "button",
      isHidden: !group.canDelete || !(group.systemGroupId || group.projectConnectionGroupId) || !group.disabled,
      callbackFn: () => onEnableGroup(group.id),
    },
    {
      labels: {
        default: t("common.action.disable"),
      },
      value: "disable",
      icon: iconTrash02,
      "data-testid": "group-list-item-disable",
      type: "button",
      isHidden: !group.canDelete || (!group.systemGroupId && !group.projectConnectionGroupId) || group.disabled,
      callbackFn: () => onDisableGroup(group.id),
    },
    {
      labels: {
        default: t("common.action.delete"),
      },
      value: "delete",
      icon: iconTrash02,
      "data-testid": "context-menu-delete-btn",
      type: "button",
      isHidden: !group.canDelete || !!group.systemGroupId || !!group.projectConnectionGroupId,
      callbackFn: () => onDeleteGroup(group.id),
    },
  ];

  const groupType = getGroupType();
  const detailLink =
    group.type === "helpCategory"
      ? routes.helpCategories.details({ slug, id: group.id })
      : routes.interestGroups.details({ slug, id: group.id });

  return (
    <Card data-testid="group-list-item" link={detailLink} isFaded={group.disabled}>
      <CardImage
        image={getImage()}
        labels={[
          user.isAdmin && groupType !== "regular" && (
            <Label theme="gray">
              {groupType === "realEstate" && t("page.discovery.group-type.real-estate")}
              {groupType === "projectConnection" && t("page.discovery.group-type.project-connection")}
              {groupType === "system" && t("page.discovery.group-type.platform")}
            </Label>
          ),
          user.isAdmin && group.hasAudience && <Label theme="blue" icon={iconUsersCheck} />,
        ].filter(Boolean)}
      />
      <CardContent>
        <CardHeader title={group.name} titleLink={detailLink} menuActions={menuActions} />
        <CardDescription description={group.description} />

        <CardActions>
          {group.isOwner && group.isResidentGroup ? (
            <StaticButton>
              <div className="flex items-center gap-1">
                <img className="size-4" src={award} alt="" />
                <span>{t("model.interest-group.owner")}</span>
              </div>
            </StaticButton>
          ) : (
            <ToggleButton
              className="w-full sm:w-auto sm:max-w-full"
              data-testid="group-action-follow-unfollow"
              config={{
                checked: {
                  label: unfollowButtonLabel,
                  icon: <Icon name={iconCheck} />,
                },
                unchecked: {
                  label: followButtonLabel,
                },
              }}
              isChecked={group.isMember}
              onClick={onClickToggleFollow}
            />
          )}
        </CardActions>
      </CardContent>
    </Card>
  );
}
