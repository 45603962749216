import { useImageResolver } from "api/hooks/useImageResolver";
import type { CommunityFeedItemV2Dto, CommunityGroupV2Dto, MessageFeedStatsDto, SelfDto } from "api/types";
import arrowNarrowUpIcon from "assets/icons/arrow-narrow-up.svg";
import iconBuilding02 from "assets/icons/building-02.svg";
import CheckIcon from "assets/icons/check.svg";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconFilterFunnel01 from "assets/icons/filter-funnel-01.svg";
import iconMessageQuesitonSquare from "assets/icons/message-question-square.svg";
import iconPlus from "assets/icons/plus.svg";
import iconX from "assets/icons/x.svg";
import { AIButton } from "components/Button/AIButton";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { CheckboxMultiSelect } from "components/CheckboxMultiSelect/CheckboxMultiSelect";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { ContentTabs, type Tab } from "components/ContentTabs/ContentTabs";
import type { ContextMenuLegacyAction } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { ContextMenuLegacy } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { DateAndTimePicker } from "components/DateAndTimePicker/DateAndTimePicker";
import { DateRange } from "components/DateRange/DateRange";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Select } from "components/Select/Select";
import { addDays, endOfDay, format, parse, startOfDay } from "date-fns";
import { useAIToolingManager } from "hooks/useAIToolingManager";
import { useBool } from "hooks/useBool";
import { useKey } from "hooks/useKey";
import { useOnIntersection } from "hooks/useOnIntersection";
import { usePermission } from "hooks/usePermission";
import { useScrollToPageTop } from "hooks/useScrollToPageTop";
import { useSignalRHub, useSignalRInvocation, useSignalRSubscription } from "hooks/useSignalR";
import { useSlug } from "hooks/useSlug";
import { debounce } from "lodash-es";
import { GroupIcon } from "modules/community-groups/components/GroupIcons";
import { AskAiModal } from "modules/messages/components/AskAiModal";
import { MessagesSentimentModal } from "modules/messages/components/MessagesSentimentModal";
import { ResidentPostType } from "modules/messages/constants";
import { canCreateMessage, canPostInMarketplace } from "modules/messages/permissions";
import posthog from "posthog-js";
import { usePostHog } from "posthog-js/react";
import type { ChangeEvent } from "react";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import { CommunityPoll } from "./components/CommunityPoll";
import { CommunityPost } from "./components/CommunityPost/CommunityPost";
import { CommunitySurvey } from "./components/CommunitySurvey";
import { ResidentCreatePostModal } from "./components/ResidentCreatePost/ResidentCreatePost";
import type { MessageFeedParams, OnUpdateFilters } from "./Loader";

export interface LayoutProps {
  sessionUser: SelfDto;
  groups: CommunityGroupV2Dto[];
  isLoadingGroups: boolean;
  stats: MessageFeedStatsDto | undefined;
  messages: CommunityFeedItemV2Dto[];
  hasMoreMessages: boolean | undefined;
  totalMessages: number;
  fetchMoreMessages: () => Promise<unknown>;
  refetchMessages: () => void;
  markAllMessagesAsRead: () => void;
  isLoadingMessages: boolean;
  isLoadingMoreMessages: boolean;
  filters: MessageFeedParams;
  onUpdateFilters: OnUpdateFilters;
}

export function Layout({
  sessionUser,
  onUpdateFilters,
  filters,
  groups,
  stats,
  messages,
  totalMessages,
  hasMoreMessages,
  fetchMoreMessages,
  refetchMessages,
  markAllMessagesAsRead,
  isLoadingMessages,
  isLoadingMoreMessages,
}: LayoutProps): React.ReactNode {
  const postHog = usePostHog();
  const slug = useSlug();
  const hasPermission = usePermission();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const scrollToTop = useScrollToPageTop();
  const [residentPostType, setResidentPostType] = useState<ResidentPostType | null>(null);
  const [isFilterOpened, filtersOpenHandlers] = useBool();
  const [isMarkAsReadModalOpen, markAsReadModalOpenHandlers] = useBool();
  const [isNewItemAvailable, newItemAvailabilityHandlers] = useBool(false);
  const [isNewVideoProcessed, newVideoProcessedHandlers] = useBool(false);
  const [isSentimentModalOpen, sentimentModalOpenHandlers] = useBool(false);
  const [isAskAiModalOpened, askAiModalOpenHandlers] = useBool(false);
  const { isAvailable: isAIToolingAvailable } = useAIToolingManager();

  const hubConnectionQuery = {
    query: `userId=${sessionUser.id}`,
  };
  const { signalRConnection: communityFeedHubConnection } = useSignalRHub("community-feed-hub", hubConnectionQuery);
  const { signalRConnection: notificationHubConnection } = useSignalRHub("notification-hub", hubConnectionQuery);
  const { invoke: onJoinGroup } = useSignalRInvocation(communityFeedHubConnection, "JoinGroup");

  const onNewItemOnFeed = useCallback(
    (...args: [{ entityId: string; entityType: "message" | "comment" | "poll" | "survey"; authorId: string }]) => {
      function isFiltersEmpty(): boolean {
        return (
          !filters.Query &&
          filters.Connection === "included" &&
          !filters.StartDate &&
          !filters.EndDate &&
          (!filters.GroupIds || filters.GroupIds?.length === 0)
        );
      }

      if (args[0].authorId === sessionUser.id) {
        return;
      }

      if ((filters.Tab === "myFeed" || filters.Tab === "all") && isFiltersEmpty()) {
        newItemAvailabilityHandlers.setTrue();
      }
    },
    [sessionUser, filters, newItemAvailabilityHandlers],
  );

  const onNewVideoProcessed = useCallback(
    (...args: [{ authorId: string }]) => {
      if (args[0].authorId !== sessionUser.id) {
        return;
      }

      newVideoProcessedHandlers.setTrue();
    },
    [newVideoProcessedHandlers, sessionUser.id],
  );

  useSignalRSubscription(communityFeedHubConnection, "NewItemOnFeed", onNewItemOnFeed);
  useSignalRSubscription(notificationHubConnection, "VideoProcessed", onNewVideoProcessed);
  useKey("Escape", filtersOpenHandlers.setFalse, isFilterOpened);

  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreMessages && hasMoreMessages) {
        void fetchMoreMessages();
      }
    }, [fetchMoreMessages, hasMoreMessages, isLoadingMoreMessages]),
  });

  const resolveImage = useImageResolver();

  const visibleFilters = useMemo(() => {
    const tempFilters = [];

    if (filters.EndDate && filters.StartDate) {
      tempFilters.push({
        key: "daterange",
        value: (
          <span>
            {t("page.message-feed.filter-bar.date")}:{" "}
            <DateRange className="inline-block" start={filters.StartDate} end={filters.EndDate} format="noTime" />
          </span>
        ),
        onRemove: () => {
          onUpdateFilters("EndDate", undefined);
          onUpdateFilters("StartDate", undefined);
        },
      });
    } else if (filters.StartDate) {
      tempFilters.push({
        key: "startdate",
        value: (
          <span>
            {t("page.message-feed.filter-bar.date-from")}:{" "}
            <FormattedDate key="startdate" date={filters.StartDate} format="date" />
          </span>
        ),
        onRemove: () => onUpdateFilters("StartDate", undefined),
      });
    } else if (filters.EndDate) {
      tempFilters.push({
        key: "enddate",
        value: (
          <span>
            {t("page.message-feed.filter-bar.date-to")}:{" "}
            <FormattedDate key="enddate" date={filters.EndDate} format="date" />
          </span>
        ),
        onRemove: () => onUpdateFilters("EndDate", undefined),
      });
    }

    if (filters.GroupIds) {
      for (const id of filters.GroupIds) {
        tempFilters.push({
          key: `group-${id}`,
          value: groups.find((x) => x.id === id)?.name || id,
          onRemove: () =>
            onUpdateFilters(
              "GroupIds",
              filters.GroupIds?.filter((x) => x !== id),
            ),
        });
      }
    }

    if (filters.Connection !== "included") {
      tempFilters.push({
        key: "connection",
        value:
          filters.Connection === "excluded"
            ? t("page.message-feed.filter-bar.only-in-project")
            : t("page.message-feed.filter-bar.only-in-connection"),
        onRemove: () => onUpdateFilters("Connection", "included"),
      });
    }

    if (filters.Query) {
      tempFilters.push({ key: "query", value: filters.Query, onRemove: () => onUpdateFilters("Query", "") });
    }

    return tempFilters;
  }, [
    filters.Connection,
    filters.EndDate,
    filters.GroupIds,
    filters.Query,
    filters.StartDate,
    groups,
    onUpdateFilters,
    t,
  ]);

  const onSearch = useMemo(
    () =>
      debounce((e: ChangeEvent<HTMLInputElement>) => {
        const MIN_SEARCH_CHARS = 2;

        const value = e.target.value.trim();
        if (value.length >= MIN_SEARCH_CHARS) {
          onUpdateFilters("Query", value);
        } else {
          onUpdateFilters("Query", "");
        }
      }, 500),
    [onUpdateFilters],
  );

  const onStartDateChange = useMemo(
    () => (date: string | undefined) => onUpdateFilters("StartDate", date),
    [onUpdateFilters],
  );

  const onEndDateChange = useMemo(
    () => (date: string | undefined) => onUpdateFilters("EndDate", date),
    [onUpdateFilters],
  );

  const onRefreshFeed = () => {
    refetchMessages();
    scrollToTop();
  };

  const residentPostCreationOptions = useMemo(() => {
    const actions: ContextMenuLegacyAction[] = [];

    if (hasPermission(canCreateMessage)) {
      actions.push({
        text: t("page.message-feed.resident-create-post.create-options.inform"),
        icon: <Icon name={iconEdit05} />,
        callback: () => {
          postHog?.capture("clicked_inform");
          setResidentPostType(ResidentPostType.Inform);
        },
      });
      actions.push({
        text: t("page.message-feed.resident-create-post.create-options.help"),
        icon: <Icon name={iconMessageQuesitonSquare} />,
        callback: () => {
          postHog?.capture("clicked_ask_help");
          setResidentPostType(ResidentPostType.AskForHelp);
        },
      });
    }

    if (hasPermission(canPostInMarketplace)) {
      actions.push({
        text: t("page.message-feed.resident-create-post.create-options.marketplace"),
        icon: <Icon name={iconBuilding02} />,
        callback: () => {
          postHog?.capture("clicked_marketplace");
          setResidentPostType(ResidentPostType.SellItem);
        },
      });
    }

    return actions;
  }, [t, postHog, hasPermission]);

  const from = startOfDay(addDays(new Date(), -14)).toISOString();
  const to = endOfDay(new Date()).toISOString();

  const tabs: Tab<Exclude<MessageFeedParams["Tab"], undefined>>[] = useMemo(() => {
    if (sessionUser.isAdmin) {
      return [
        {
          id: "all",
          name: t("page.message-feed.tabs.all"),
          count: stats?.allCount,
        },
        {
          id: "announcements",
          name: t("page.message-feed.tabs.announcements"),
          count: stats?.announcementsCount,
        },
        {
          id: "question",
          name: t("page.message-feed.tabs.questions"),
          count: undefined,
        },
        {
          id: "own",
          name: t("page.message-feed.tabs.mine"),
          count: stats?.myPostsCount,
        },
      ] as const;
    }

    return [
      {
        id: "myFeed",
        name: t("page.message-feed.tabs.my-feed"),
        count: stats?.allCount,
      },
      {
        id: "question",
        name: t("page.message-feed.tabs.questions"),
        count: undefined,
      },
      {
        id: "announcements",
        name: t("page.message-feed.tabs.announcements"),
        count: stats?.announcementsCount,
      },
      {
        id: "all",
        name: t("page.message-feed.tabs.all"),
        count: undefined,
      },
    ] as const;
  }, [sessionUser.isAdmin, stats?.allCount, stats?.announcementsCount, stats?.myPostsCount, t]);

  const onClickAskAI = () => {
    postHog?.capture("clicked_community_feed_ask_ai");

    askAiModalOpenHandlers.setTrue();
  };

  const onClickSentimentAnalysis = () => {
    postHog?.capture("clicked_community_feed_sentiment_analysis");

    sentimentModalOpenHandlers.setTrue();
  };

  const onClickMarkAllAsRead = () => {
    postHog?.capture("clicked_maar_community_feed");

    markAsReadModalOpenHandlers.setTrue();
  };

  const onClearFilter = () => {
    onUpdateFilters("StartDate", undefined);
    onUpdateFilters("EndDate", undefined);
    onUpdateFilters("GroupIds", []);
  };

  const amountActiveFilters = Object.entries(filters).filter(([filterKey, filterValue]) => {
    if (filterValue === undefined) {
      return false;
    }
    if (Array.isArray(filterValue) && filterValue.length === 0) {
      return false;
    }

    if (typeof filterValue === "string" && filterValue.trim() === "") {
      return false;
    }

    // Exclude these filters from the count
    if (filterKey === "Tab" || filterKey === "Connection" || filterKey === "Query") {
      return false;
    }

    return true;
  }).length;

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.message-feed.title")}
      subTitle={t("page.message-feed.subtitle")}
      actions={
        <>
          {sessionUser.isAdmin && isAIToolingAvailable && (
            <>
              <AIButton onClick={onClickAskAI}>{t("page.message-feed.ask-ai.btn")}</AIButton>
              <AIButton onClick={onClickSentimentAnalysis}>{t("page.message-feed.sentiment.btn")}</AIButton>
            </>
          )}
          {hasPermission(canCreateMessage) && sessionUser.isAdmin && (
            <>
              <Button
                type="link"
                className="hidden lg:flex"
                data-testid="create-message"
                href={routes.messageFeed.adminCreatePost({ slug })}
              >
                {t("page.message-feed.button.create.post")}
              </Button>
              <IconButton
                styling="primary"
                className="lg:hidden"
                title={t("page.message-feed.button.create.post")}
                onClick={() => void navigate(routes.messageFeed.adminCreatePost({ slug }))}
                icon={iconPlus}
                isCircular
              />
            </>
          )}
          {hasPermission(canCreateMessage) && !sessionUser.isAdmin && (
            <ContextMenuLegacy actions={residentPostCreationOptions}>
              {(props) => (
                <>
                  <Button
                    className="hidden lg:flex"
                    data-testid="create-message"
                    onClick={props.openHandlers.toggle}
                    isPressed={props.isOpen}
                  >
                    <span>{t("page.message-feed.button.create.post")}</span>
                  </Button>
                  <IconButton
                    styling="primary"
                    className="lg:hidden"
                    title={t("page.message-feed.button.create.post")}
                    onClick={props.openHandlers.toggle}
                    isPressed={props.isOpen}
                    icon={iconPlus}
                    isCircular
                  />
                </>
              )}
            </ContextMenuLegacy>
          )}
        </>
      }
      header={
        <div className="flex w-full flex-col gap-4">
          <div className="flex w-full flex-col flex-wrap items-start justify-between gap-4 md:flex-row">
            <div className="flex flex-1 flex-wrap items-center gap-3">
              <div className="w-full md:max-w-80">
                <SearchInput
                  defaultValue={filters.Query}
                  placeholder={t("page.message-feed.filters.search.label")}
                  onChange={onSearch}
                  onFocusCapture={() => {
                    posthog?.capture("clicked_feed_search_field", {
                      isAdmin: sessionUser.isAdmin,
                    });
                  }}
                />
              </div>
              <Button
                icon={<Icon name={iconFilterFunnel01} />}
                styling="secondary"
                onClick={filtersOpenHandlers.toggle}
              >
                <div className="flex items-center gap-2">
                  {t("page.tickets.header.button.filter")}
                  {amountActiveFilters > 0 && (
                    <span className="flex size-5 items-center justify-center rounded-full bg-aop-basic-blue-500 text-caption leading-none text-white">
                      {amountActiveFilters}
                    </span>
                  )}
                </div>
              </Button>
              {isFilterOpened && amountActiveFilters > 0 && (
                <Button
                  data-testid="portfolio-filter-btn"
                  icon={<Icon name={iconX} />}
                  styling="secondary"
                  onClick={onClearFilter}
                  isPressed={isFilterOpened}
                >
                  {t("common.action.clear")}
                </Button>
              )}
            </div>
            <Button styling="secondary" icon={<Icon name={CheckIcon} size={16} />} onClick={onClickMarkAllAsRead}>
              {t("page.message-feed.mark-all-as-read")}
            </Button>
          </div>

          {isFilterOpened && (
            <div className="flex w-full flex-col border-t border-grey-100">
              <div className="flex w-full flex-col gap-2 py-4">
                <span className="text-caption-bold">{t("page.message-feed.filters.title")}</span>
                <div className="flex flex-wrap items-center gap-3">
                  {sessionUser.connections.length > 0 && hasPermission((x) => x.community.canListGeneralPosts) ? (
                    <Select
                      placeholder={t("page.calendar.tabs.events.filter.type.placeholder")}
                      renderOption={(opt) => {
                        switch (opt) {
                          case "included":
                            return t("page.message-feed.filters.on-project-connection.all");
                          case "only":
                            return t("page.message-feed.filters.on-project-connection.in-your-connection");
                          case "excluded":
                            return t("page.message-feed.filters.on-project-connection.in-your-project");
                        }
                      }}
                      keySelector={(opt) => opt}
                      items={["included", "only", "excluded"]}
                      selected={filters.Connection ?? "included"}
                      onChange={(x) => onUpdateFilters("Connection", x as "included" | "only" | "excluded")}
                    />
                  ) : null}
                  <div className="max-w-96">
                    <DateAndTimePicker
                      placeholder={t("page.message-feed.filters.on-date.start-date")}
                      value={filters.StartDate ? parse(filters.StartDate, "yyyy-MM-dd", new Date()) : ""}
                      onChange={(value) => onStartDateChange(value ? format(value, "yyyy-MM-dd") : undefined)}
                      min={new Date("1753-01-01T12:00:00")}
                      max={
                        filters.EndDate
                          ? parse(filters.EndDate, "yyyy-MM-dd", new Date())
                          : new Date("9999-12-31T11:59:59")
                      }
                    />
                  </div>
                  <div className="max-w-80">
                    <DateAndTimePicker
                      placeholder={t("page.message-feed.filters.on-date.end-date")}
                      value={filters.EndDate ? parse(filters.EndDate, "yyyy-MM-dd", new Date()) : ""}
                      onChange={(value) => onEndDateChange(value ? format(value, "yyyy-MM-dd") : undefined)}
                      min={
                        filters.StartDate
                          ? parse(filters.StartDate, "yyyy-MM-dd", new Date())
                          : new Date("1753-01-01T12:00:00")
                      }
                      max={new Date("9999-12-31T11:59:59")}
                    />
                  </div>
                  <div className="w-80">
                    <CheckboxMultiSelect<CommunityGroupV2Dto>
                      placeholder={t("page.message-feed.filters.on-group")}
                      selected={
                        filters.GroupIds
                          ? groups
                              .filter((x) => x.type === "interest" || x.type === "realEstate")
                              .filter((x) => filters.GroupIds!.includes(x.id))
                          : []
                      }
                      items={groups.filter((x) => x.type === "interest" || x.type === "realEstate")}
                      onChange={(newGroups) =>
                        onUpdateFilters(
                          "GroupIds",
                          filters.GroupIds
                            ? [
                                ...groups
                                  .filter((g) => g.type === "helpCategory")
                                  .filter((g) => filters.GroupIds!.includes(g.id))
                                  .map((g) => g.id),
                                ...newGroups.map((x) => x.id),
                              ]
                            : newGroups.map((x) => x.id),
                        )
                      }
                      keySelector={(x) => x.id}
                      renderOption={(x) => (
                        <div className="flex items-center gap-2">
                          {x.isResidentGroup && x.image ? (
                            <img src={resolveImage(x.image, "sm")} alt={x.name} className="size-6 rounded-full" />
                          ) : (
                            <span className="size-6 p-1">
                              <GroupIcon icon={x.icon} size={16} />
                            </span>
                          )}
                          <span>{x.name}</span>
                        </div>
                      )}
                      renderValue={(value) => (
                        <span className="block max-w-72 truncate pr-5">{value.map((x) => x.name).join(", ")}</span>
                      )}
                    />
                  </div>
                  <div className="w-80">
                    <CheckboxMultiSelect<CommunityGroupV2Dto>
                      placeholder={t("page.message-feed.filters.on-help-category")}
                      selected={
                        filters.GroupIds
                          ? groups
                              .filter((x) => x.type === "helpCategory")
                              .filter((x) => filters.GroupIds!.includes(x.id))
                          : []
                      }
                      items={groups.filter((x) => x.type === "helpCategory")}
                      onChange={(newGroups) =>
                        onUpdateFilters(
                          "GroupIds",
                          filters.GroupIds
                            ? [
                                ...groups
                                  .filter((g) => g.type === "interest" || g.type === "realEstate")
                                  .filter((g) => filters.GroupIds!.includes(g.id))
                                  .map((g) => g.id),
                                ...newGroups.map((x) => x.id),
                              ]
                            : newGroups.map((x) => x.id),
                        )
                      }
                      keySelector={(x) => x.id}
                      renderOption={(x) => (
                        <div className="flex items-center gap-2">
                          {x.isResidentGroup && x.image ? (
                            <img src={resolveImage(x.image, "sm")} alt={x.name} className="size-6 rounded-full" />
                          ) : (
                            <span className="size-6 p-1">
                              <GroupIcon icon={x.icon} size={16} />
                            </span>
                          )}
                          <span>{x.name}</span>
                        </div>
                      )}
                      renderValue={(value) => (
                        <span className="block max-w-72 truncate pr-5">{value.map((x) => x.name).join(", ")}</span>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="self-center justify-self-end">
                <Button
                  icon={<Icon name={iconX} size={16} />}
                  styling="tertiary"
                  onClick={filtersOpenHandlers.setFalse}
                >
                  {t("page.calendar.tabs.events.filter.close")}
                </Button>
              </div>
            </div>
          )}
        </div>
      }
    >
      <div className="flex gap-8">
        <div className="flex w-full flex-col">
          {visibleFilters.length > 0 ? (
            <div className="mb-4 flex flex-wrap gap-4">
              {visibleFilters.map(({ key, value, onRemove }) => (
                <div className="flex items-center gap-2 rounded-full bg-white p-2.5 px-4 text-grey-900" key={key}>
                  <span className="text-caption">{value}</span>
                  <IconButton
                    styling="tertiary"
                    size="sm"
                    className="rounded-full"
                    title={t("page.message-feed.filters.remove")}
                    onClick={onRemove}
                    icon={iconX}
                  />
                </div>
              ))}
            </div>
          ) : null}

          <ContentTabs
            tabs={tabs}
            activeTabId={filters.Tab || "all"}
            onTabChange={(tab) => onUpdateFilters("Tab", tab)}
          >
            {!isLoadingMessages && (
              <div className="flex items-center justify-between p-4">
                <span className="text-caption">{t("page.message-feed.total-messages", { count: totalMessages })}</span>
              </div>
            )}
          </ContentTabs>

          {isLoadingMessages ? (
            <FullSizeLoader withPadding />
          ) : (
            <div className="relative mt-4 max-w-4xl">
              {(isNewItemAvailable || isNewVideoProcessed) && (
                <div className="sticky top-0 z-50 mx-auto w-fit translate-y-2">
                  {isNewItemAvailable && !isNewVideoProcessed && (
                    <Button
                      onClick={() => {
                        newItemAvailabilityHandlers.setFalse();
                        onRefreshFeed();
                      }}
                      icon={<Icon name={arrowNarrowUpIcon} size={16} />}
                      isCircular
                    >
                      {t("page.message-feed.button.new-messages")}
                    </Button>
                  )}
                  {isNewVideoProcessed && (
                    <Button
                      onClick={() => {
                        newVideoProcessedHandlers.setFalse();
                        onRefreshFeed();
                      }}
                      icon={<Icon name={arrowNarrowUpIcon} size={16} />}
                      isCircular
                    >
                      {t("page.message-feed.button.video-processed")}
                    </Button>
                  )}
                </div>
              )}
              <ul
                className={twJoin(
                  "relative flex flex-col gap-5",
                  (isNewItemAvailable || isNewVideoProcessed) && "-mt-10",
                )}
              >
                {messages.map((message) => (
                  <Fragment key={message.type + (message.message?.id || message.poll?.id || message.survey?.id)}>
                    {message.type === "message" && message.message && (
                      <li data-testid="community-post">
                        <CommunityPost message={message.message} onJoinGroup={onJoinGroup} />
                      </li>
                    )}
                    {message.type === "poll" && message.poll && (
                      <li data-testid="poll">
                        <CommunityPoll poll={message.poll} />
                      </li>
                    )}
                    {message.type === "survey" && message.survey && (
                      <li data-testid="survey">
                        <CommunitySurvey survey={message.survey} updatedAt={message.updatedAt} />
                      </li>
                    )}
                  </Fragment>
                ))}
              </ul>
              {hasMoreMessages && (
                <div className="p-4" ref={ref}>
                  <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <ConfirmModal
        title={t("component.community-post.mark-all-as-read.modal.title")}
        description={t("component.community-post.mark-all-as-read.modal.description")}
        isLoading={false}
        onOpenChange={markAsReadModalOpenHandlers.set}
        onReject={markAsReadModalOpenHandlers.setFalse}
        rejectBtnProps={{
          "data-testid": "mark-all-read-modal-cancel",
        }}
        onResolve={() => {
          markAllMessagesAsRead();
          markAsReadModalOpenHandlers.setFalse();
        }}
        resolveBtnProps={{
          text: t("common.action.confirm"),
          "data-testid": "mark-all-read-modal-confirm",
        }}
        isOpened={isMarkAsReadModalOpen}
        shouldCloseOnEsc
        data-testid="mark-all-read-modal"
      />
      {isAIToolingAvailable && (
        <>
          <MessagesSentimentModal
            startDate={from}
            endDate={to}
            isOpened={isSentimentModalOpen}
            onOpenChange={sentimentModalOpenHandlers.setFalse}
          />
          <AskAiModal isOpened={isAskAiModalOpened} onOpenChange={askAiModalOpenHandlers.set} />
        </>
      )}
      <ResidentCreatePostModal
        isOpened={!!residentPostType}
        onOpenChange={(state) => {
          if (!state) {
            setResidentPostType(null);
          }
        }}
        postType={residentPostType}
      />
    </DocumentPaper>
  );
}
