import type { DocumentsRootFolderDto } from "api/types";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import type { ContextMenuLegacyAction } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { ContextMenuLegacy } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { Icon } from "components/Icon/Icon";
import { Icon54 } from "components/Icon54/Icon54";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { canManageAllDocuments } from "modules/documents/permissions";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

interface RootFolderProps {
  folder: DocumentsRootFolderDto;
  onDelete: (id: string) => void;
}

export function RootFolder({ folder, onDelete }: RootFolderProps): React.ReactNode {
  const slug = useSlug();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const hasPermission = usePermission();

  const actions = useMemo(() => {
    const result: ContextMenuLegacyAction[] = [];
    if (hasPermission(canManageAllDocuments)) {
      result.push({
        text: t("common.action.edit"),
        icon: <Icon name={iconEdit05} />,
        callback: () => {
          void navigate(routes.documents.rootEdit({ slug, folderId: folder.id }));
        },
      });
    }
    if (hasPermission(canManageAllDocuments)) {
      result.push({
        text: t("common.action.delete"),
        icon: <Icon name={iconTrash02} />,
        callback: () => onDelete(folder.id),
        status: {
          disabled: !folder.isEmpty,
          disabledText: t("model.document.folder.action.delete.not-allowed"),
        },
      });
    }

    return result;
  }, [hasPermission, folder.id, folder.isEmpty, slug, onDelete, navigate, t]);

  return (
    <article
      className={twJoin(
        "relative flex h-36 items-center gap-4 rounded-lg bg-white py-8 pl-6 pr-2 shadow-sm sm:pr-4",
        folder.isEmpty && !folder.canAddItem ? "opacity-50" : "hover:shadow-md",
      )}
      data-testid="documents-root-item"
    >
      <Link
        className={twJoin("flex gap-2 sm:gap-4", folder.isEmpty && !folder.canAddItem ? "pointer-events-none" : "")}
        to={routes.documents.documentsList({ slug, folderId: folder.id })}
      >
        <div className="flex items-center justify-center rounded-lg p-2.5 ring-1 ring-grey-100" role="presentation">
          <Icon54 className="w-12 text-[3rem]" char={folder.iconChar} />
        </div>

        <div className="flex flex-col gap-1.5">
          <h2 className="line-clamp-1 text-body-bold">{folder.name}</h2>
          {folder.description ? <p className="line-clamp-2 text-caption text-grey-700">{folder.description}</p> : null}
        </div>
      </Link>
      <div className="ml-auto self-start">
        <ContextMenuLegacy actions={actions} />
      </div>
      {folder.isEmpty && folder.canAddItem ? (
        <div
          className="absolute bottom-0 right-0 flex items-center gap-2 overflow-hidden p-4"
          data-testid="documents-root-item-empty"
        >
          <span className="text-overline text-grey-700">{t("page.document.root.list.disabled")}</span>
          <InfoIcon tooltip={t("page.document.root.list.disabled.description")} />
        </div>
      ) : null}
    </article>
  );
}
