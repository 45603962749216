import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type {
  AlertConfigurationsDto,
  KeywordAlertConfigurationCreateRequest,
  KeywordAlertConfigurationDto,
  ThresholdAlertConfigurationCreateRequest,
  ThresholdAlertConfigurationDto,
} from "api/types";
import iconEdit05 from "assets/icons/edit-05.svg";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import type { ContextMenuLegacyAction } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { ContextMenuLegacy } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { Icon } from "components/Icon/Icon";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Radio } from "components/Radio/Radio";
import { Table } from "components/Table/Table";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { ProjectList } from "modules/system-settings/components/ProjectList";
import { useSystemSettingBreadcrumbs } from "modules/system-settings/util/useSystemSettingBreadcrumbs";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import type { AlertDetailFormValues } from "./components/AlertDetailsModal";
import { AlertDetailsModal } from "./components/AlertDetailsModal";

export interface LayoutProps {
  alerts: AlertConfigurationsDto;
  createLikeAlert: (payload: ThresholdAlertConfigurationCreateRequest) => Promise<void>;
  createCommentAlert: (payload: ThresholdAlertConfigurationCreateRequest) => Promise<void>;
  createPostKeywordsAlert: (payload: KeywordAlertConfigurationCreateRequest) => Promise<void>;
  createCommentKeywordsAlert: (payload: KeywordAlertConfigurationCreateRequest) => Promise<void>;
  editLikeAlert: ({ id, payload }: { id: string; payload: ThresholdAlertConfigurationCreateRequest }) => Promise<void>;
  editCommentAlert: ({
    id,
    payload,
  }: {
    id: string;
    payload: ThresholdAlertConfigurationCreateRequest;
  }) => Promise<void>;
  editPostKeywordsAlert: ({
    id,
    payload,
  }: {
    id: string;
    payload: KeywordAlertConfigurationCreateRequest;
  }) => Promise<void>;
  editCommentKeywordsAlert: ({
    id,
    payload,
  }: {
    id: string;
    payload: KeywordAlertConfigurationCreateRequest;
  }) => Promise<void>;
}

export interface SaveRequest {
  enabled: boolean;
  name: string;
  email: string;
  projectIds: string[];
  threshold?: number;
  keywords?: string[];
  enableEmail?: boolean;
}

export type AlertType = "reactions" | "comments" | "keywords_post" | "keywords_comments";

export function Layout({
  alerts,
  createLikeAlert,
  createCommentAlert,
  createPostKeywordsAlert,
  createCommentKeywordsAlert,
  editCommentAlert,
  editLikeAlert,
  editPostKeywordsAlert,
  editCommentKeywordsAlert,
}: LayoutProps): React.ReactNode {
  const sessionUser = useSessionUser();
  const defaultFormValues = {
    id: "",
    type: "reactions",
    enabled: true,
    emailEnabled: true,
    name: "",
    email: sessionUser.email,
    projects: [],
    threshold: "0",
    keywords: "",
  } satisfies AlertDetailFormValues;

  const { t } = useTranslation();
  const [isModalOpened, modalOpenHandlers] = useBool(false);
  const [alertValues, setAlertValues] = useState<AlertDetailFormValues>(defaultFormValues);

  const actions = useMemo(() => {
    const actions = [];

    actions.push({
      text: t("page.alerts.actions.n-of-reactions"),
      callback: () => {
        setAlertValues({ ...alertValues, type: "reactions" });
        modalOpenHandlers.setTrue();
      },
    });

    actions.push({
      text: t("page.alerts.actions.n-of-comments"),
      callback: () => {
        setAlertValues({ ...alertValues, type: "comments" });
        modalOpenHandlers.setTrue();
      },
    });

    actions.push({
      text: t("page.alerts.actions.post-keywords"),
      callback: () => {
        setAlertValues({ ...alertValues, type: "keywords_post" });
        modalOpenHandlers.setTrue();
      },
    });

    actions.push({
      text: t("page.alerts.actions.comment-keywords"),
      callback: () => {
        setAlertValues({ ...alertValues, type: "keywords_comments" });
        modalOpenHandlers.setTrue();
      },
    });

    return actions;
  }, [t, modalOpenHandlers, alertValues, setAlertValues]);

  const breadcrumbs = useSystemSettingBreadcrumbs({ module: "alerts", status: "edit" });

  const onSaveAlert = useCallback(
    async ({ id, type, payload }: { id: string; type: AlertType; payload: SaveRequest }) => {
      const alertNotificationType = payload.enableEmail ? "dashboardAndEmail" : "dashboardOnly";
      if (type === "reactions") {
        if (id) {
          await editLikeAlert({
            id,
            payload: {
              enabled: payload.enabled,
              name: payload.name,
              email: payload.email,
              projectIds: payload.projectIds,
              percentageThreshold: payload.threshold!,
              alertNotificationType,
            },
          });
        } else {
          await createLikeAlert({
            enabled: payload.enabled,
            name: payload.name,
            email: payload.email,
            projectIds: payload.projectIds,
            percentageThreshold: payload.threshold!,
            alertNotificationType,
          });
        }
      } else if (type === "comments") {
        if (id) {
          await editCommentAlert({
            id,
            payload: {
              enabled: payload.enabled,
              name: payload.name,
              email: payload.email,
              projectIds: payload.projectIds,
              percentageThreshold: payload.threshold!,
              alertNotificationType,
            },
          });
        } else {
          await createCommentAlert({
            enabled: payload.enabled,
            name: payload.name,
            email: payload.email,
            projectIds: payload.projectIds,
            percentageThreshold: payload.threshold!,
            alertNotificationType,
          });
        }
      } else if (type === "keywords_post") {
        if (id) {
          await editPostKeywordsAlert({
            id,
            payload: {
              enabled: payload.enabled,
              name: payload.name,
              email: payload.email,
              projectIds: payload.projectIds,
              keywords: payload.keywords!,
              alertNotificationType,
            },
          });
        } else {
          await createPostKeywordsAlert({
            enabled: payload.enabled,
            name: payload.name,
            email: payload.email,
            projectIds: payload.projectIds,
            keywords: payload.keywords!,
            alertNotificationType,
          });
        }
      } else if (type === "keywords_comments") {
        if (id) {
          await editCommentKeywordsAlert({
            id,
            payload: {
              enabled: payload.enabled,
              name: payload.name,
              email: payload.email,
              projectIds: payload.projectIds,
              keywords: payload.keywords!,
              alertNotificationType,
            },
          });
        } else {
          await createCommentKeywordsAlert({
            enabled: payload.enabled,
            name: payload.name,
            email: payload.email,
            projectIds: payload.projectIds,
            keywords: payload.keywords!,
            alertNotificationType,
          });
        }
      }
    },
    [
      editLikeAlert,
      createLikeAlert,
      createPostKeywordsAlert,
      createCommentKeywordsAlert,
      editCommentAlert,
      createCommentAlert,
      editPostKeywordsAlert,
      editCommentKeywordsAlert,
    ],
  );

  const likesAndCommentsColumns = useMemo(() => {
    const helper = createColumnHelper<ThresholdAlertConfigurationDto>();

    return [
      helper.accessor("name", {
        header: t("page.alerts.table.type"),
        cell: (cell) => <span className="block w-[250px] max-w-full truncate">{cell.getValue()}</span>,
      }),
      helper.accessor("projects", {
        header: t("page.alerts.table.projects"),
        cell: (cell) => (
          <div className="py-2">
            <ProjectList projects={cell.getValue()} limit={3} canWrap />
          </div>
        ),
      }),
      helper.accessor("enabled", {
        header: t("page.alerts.table.state"),
        cell: (cell) => (
          <StateToggle
            rowId={cell.row.id}
            enabled={cell.getValue()}
            emailEnabled={cell.row.original.alertNotificationType === "dashboardAndEmail"}
            onChange={(enabled, enableEmail) =>
              onSaveAlert({
                id: cell.row.original.id,
                type: cell.row.id.includes("reactions") ? "reactions" : "comments",
                payload: {
                  enabled,
                  enableEmail,
                  name: cell.row.original.name,
                  email: cell.row.original.email,
                  projectIds: cell.row.original.projects.map((project) => project.id),
                  threshold: cell.row.original.percentageThreshold,
                },
              })
            }
          />
        ),
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          const actions: ContextMenuLegacyAction[] = [
            {
              text: t("page.alerts.table.actions.edit"),
              icon: <Icon name={iconEdit05} />,
              callback: () => {
                setAlertValues({
                  id: cell.row.original.id,
                  type: cell.row.id.includes("reactions") ? "reactions" : "comments",
                  enabled: cell.row.original.enabled,
                  name: cell.row.original.name,
                  projects: cell.row.original.projects,
                  email: cell.row.original.email,
                  threshold: cell.row.original.percentageThreshold?.toString(),
                  emailEnabled: cell.row.original.alertNotificationType === "dashboardAndEmail",
                });
                modalOpenHandlers.setTrue();
              },
            },
          ];

          return (
            <div className="flex justify-end">
              <ContextMenuLegacy actions={actions} />
            </div>
          );
        },
      }),
    ];
  }, [t, modalOpenHandlers, onSaveAlert]);

  const keywordsColumns = useMemo(() => {
    const helper = createColumnHelper<KeywordAlertConfigurationDto>();

    return [
      helper.accessor("name", {
        header: t("page.alerts.table.type"),
        cell: (cell) => <span className="block w-[250px] max-w-full truncate">{cell.getValue()}</span>,
      }),
      helper.accessor("projects", {
        header: t("page.alerts.table.projects"),
        cell: (cell) => (
          <div className="py-2">
            <ProjectList projects={cell.getValue()} limit={3} canWrap />
          </div>
        ),
      }),
      helper.accessor("enabled", {
        header: t("page.alerts.table.state"),
        cell: (cell) => (
          <StateToggle
            rowId={cell.row.id}
            enabled={cell.getValue()}
            emailEnabled={cell.row.original.alertNotificationType === "dashboardAndEmail"}
            onChange={(enabled, enableEmail) =>
              onSaveAlert({
                id: cell.row.original.id,
                type: cell.row.id.includes("post") ? "keywords_post" : "keywords_comments",
                payload: {
                  enabled,
                  enableEmail,
                  name: cell.row.original.name,
                  email: cell.row.original.email,
                  projectIds: cell.row.original.projects.map((project) => project.id),
                  keywords: cell.row.original.keywords?.split(","),
                },
              })
            }
          />
        ),
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          const actions: ContextMenuLegacyAction[] = [
            {
              callback: () => {
                setAlertValues({
                  id: cell.row.original.id,
                  type: cell.row.id.includes("post") ? "keywords_post" : "keywords_comments",
                  enabled: cell.row.original.enabled,
                  name: cell.row.original.name,
                  email: cell.row.original.email,
                  projects: cell.row.original.projects,
                  keywords: cell.row.original.keywords,
                  emailEnabled: cell.row.original.alertNotificationType === "dashboardAndEmail",
                });
                modalOpenHandlers.setTrue();
              },
              text: t("page.alerts.table.actions.edit"),
              icon: <Icon name={iconEdit05} />,
            },
          ];

          return (
            <div className="flex justify-end">
              <ContextMenuLegacy actions={actions} />
            </div>
          );
        },
      }),
    ];
  }, [t, modalOpenHandlers, onSaveAlert]);

  const numberOfLikesTableInstance = useReactTable<ThresholdAlertConfigurationDto>({
    columns: likesAndCommentsColumns,
    data: alerts.numberOfLikesAlerts,
    getRowId: (row) => `n_of_likes_alert_${row.id}`,
    getCoreRowModel: getCoreRowModel(),
  });

  const numberOfCommentsTableInstance = useReactTable<ThresholdAlertConfigurationDto>({
    columns: likesAndCommentsColumns,
    data: alerts.numberOfCommentsAlerts,
    getRowId: (row) => `n_of_comments_alert_${row.id}`,
    getCoreRowModel: getCoreRowModel(),
  });

  const postKeywordsTableInstance = useReactTable<KeywordAlertConfigurationDto>({
    columns: keywordsColumns,
    data: alerts.postKeywordAlerts,
    getRowId: (row) => `post_keywords_alert_${row.id}`,
    getCoreRowModel: getCoreRowModel(),
  });

  const commentKeywordsTableInstance = useReactTable<KeywordAlertConfigurationDto>({
    columns: keywordsColumns,
    data: alerts.commentKeywordAlerts,
    getRowId: (row) => `comment_keywords_alert_${row.id}`,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.alerts.title")}
      subTitle={<Breadcrumbs pages={breadcrumbs} />}
      actions={
        <ContextMenuLegacy actions={actions}>
          {(props) => (
            <Button
              title={t("component.context-menu.action.open")}
              isPressed={props.isOpen}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.openHandlers.toggle();
              }}
            >
              {t("page.alerts.new")}
            </Button>
          )}
        </ContextMenuLegacy>
      }
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <span className="text-body-bold">{t("page.alerts.table.likes.title")}</span>
          {alerts.numberOfLikesAlerts.length > 0 ? (
            <Table
              table={numberOfLikesTableInstance}
              data-testid="likes-alerts-list"
              getRowProps={() => ({
                "data-testid": "likes-alerts-item",
              })}
            />
          ) : (
            <span className="text-caption">{t("page.alerts.no-data")}</span>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-body-bold">{t("page.alerts.table.comments.title")}</span>
          {alerts.numberOfCommentsAlerts.length > 0 ? (
            <Table
              table={numberOfCommentsTableInstance}
              data-testid="comments-alerts-list"
              getRowProps={() => ({
                "data-testid": "comments-alerts-item",
              })}
            />
          ) : (
            <span className="text-caption">{t("page.alerts.no-data")}</span>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-body-bold">{t("page.alerts.table.post-keywords.title")}</span>
          {alerts.postKeywordAlerts.length > 0 ? (
            <Table
              table={postKeywordsTableInstance}
              data-testid="post-keywords-alerts-list"
              getRowProps={() => ({
                "data-testid": "post-keywords-alerts-item",
              })}
            />
          ) : (
            <span className="text-caption">{t("page.alerts.no-data")}</span>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-body-bold">{t("page.alerts.table.comment-keywords.title")}</span>
          {alerts.commentKeywordAlerts.length > 0 ? (
            <Table
              table={commentKeywordsTableInstance}
              data-testid="comment-keywords-alerts-list"
              getRowProps={() => ({
                "data-testid": "comment-keywords-alerts-item",
              })}
            />
          ) : (
            <span className="text-caption">{t("page.alerts.no-data")}</span>
          )}
        </div>
      </div>
      <AlertDetailsModal
        alert={alertValues}
        onSave={onSaveAlert}
        isOpened={isModalOpened}
        onOpenChange={modalOpenHandlers.set}
      />
    </DocumentPaper>
  );
}

function StateToggle({
  rowId,
  enabled,
  emailEnabled,
  onChange,
}: {
  rowId: string;
  enabled: boolean;
  emailEnabled: boolean;
  onChange: (enabled: boolean, email: boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex gap-6">
      <label className="inline-flex flex-row-reverse items-center justify-end gap-2">
        <span className="whitespace-nowrap">{t("page.alerts.table.state.email-and-dashboard")}</span>
        <Radio
          name={`${rowId}_email_and_dashboard`}
          checked={enabled && emailEnabled}
          onChange={() => onChange(true, true)}
        />
      </label>
      <label className="inline-flex flex-row-reverse items-center justify-end gap-2">
        <span className="whitespace-nowrap">{t("page.alerts.table.state.dashboard")}</span>
        <Radio name={`${rowId}_dashboard`} checked={enabled && !emailEnabled} onChange={() => onChange(true, false)} />
      </label>
      <label className="inline-flex flex-row-reverse items-center justify-end gap-2">
        <span className="whitespace-nowrap">{t("page.alerts.table.state.off")}</span>
        <Radio name={`${rowId}_disabled`} checked={!enabled} onChange={() => onChange(false, false)} />
      </label>
    </div>
  );
}
