import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { LazyMarkdownRenderer } from "components/MarkdownRenderer/LazyMarkdownRenderer";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { SpeedometerLegacy } from "modules/home/components/SpeedometerLegacy";
import type React from "react";
import { useTranslation } from "react-i18next";

export type AIAnalysisModalState = "success" | "unavailable" | "error" | "loading";

type AIAnalysisModalConfig = {
  title: string;
  description?: string;
};

type AIAnalysisModalProps = ModalBaseProps & {
  title?: string;
  data: {
    sentiment: number;
    summary: string;
    suggestion?: string;
    hashtags?: string[];
  };
  state: AIAnalysisModalState;
  config: Record<Exclude<AIAnalysisModalState, "loading">, AIAnalysisModalConfig>;
};

export function AIAnalysisModal({
  title,
  data,
  state,
  config,
  isOpened,
  onOpenChange,
}: AIAnalysisModalProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Modal.Root {...{ title, isOpened, onOpenChange }} size="lg">
      <div className="relative flex min-h-[360px] flex-col items-center gap-10 md:flex-row md:gap-16">
        {state === "success" && (
          <>
            <div className="flex flex-col items-center gap-4">
              <span className="text-headline4 leading-none">{t("component.sentiment-modal.sentiment-score")}</span>
              <div className="w-40">
                <SpeedometerLegacy score={data.sentiment / 100} />
              </div>
              <div className="flex w-full flex-col items-center gap-2">
                <h4 className="text-headline3 leading-none">{data.sentiment} / 100</h4>
              </div>
            </div>
            <div className="my-4 flex max-h-full flex-col gap-6 overflow-auto">
              <div className="flex flex-col gap-2">
                <h4 className="text-headline3">{config.success.title}</h4>
                <LazyMarkdownRenderer markdown={data.summary} />
              </div>
              {data.suggestion && (
                <div className="flex flex-col gap-2">
                  <h4 className="text-headline3">{t("component.sentiment-modal.suggestion")}</h4>
                  <LazyMarkdownRenderer markdown={data.suggestion} />
                </div>
              )}
              {data.hashtags && (
                <div className="flex flex-col gap-2">
                  <h4 className="text-headline3">{t("component.sentiment-modal.frequently-used-hashtags")}</h4>
                  <div className="flex flex-wrap gap-1 lowercase text-grey-600">
                    {data.hashtags.map((x) => (
                      <span key={x} className="leading-none">
                        {x.startsWith("#") ? x : "#" + x}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {(state === "unavailable" || state === "error") && (
          <div className="flex w-full flex-col items-center gap-2">
            <h4 className="text-headline3">{config[state].title}</h4>
            {config[state].description && <p className="whitespace-pre-wrap">{config[state].description}</p>}
          </div>
        )}

        {state === "loading" && (
          <div className="h-[360px] w-full">
            <FullSizeLoader />
          </div>
        )}
      </div>
    </Modal.Root>
  );
}
