import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";

const useDeleteBooking = () => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const projectId = useProjectId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ bookingId, assetId, reason }: { bookingId: string; assetId: string; reason: string }) =>
      api.deleteBookableAssetsBookingsByIdV1(bookingId, assetId, { reason }).then((x) => x.data),
    onSuccess: (_, payload) => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.asset-detail.decline-booking.success"),
      });

      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BOOKINGS_ASSET_SLOTS(projectId, payload.assetId) });
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.BOOKINGS_ASSET_BOOKINGS(projectId, payload.assetId),
      });

      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.BOOKINGS_USER_RESERVATIONS(projectId),
      });

      queryClient.removeQueries({
        queryKey: QUERY_KEYS.BOOKINGS_RESERVATION_DETAILS(projectId, payload.assetId, payload.bookingId),
      });
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.asset-detail.decline-booking.error"),
      }),
  });
};

export const bookableAssetMutations = {
  useDeleteBooking,
};
