import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  EditTicketCommentRequest,
  HomeDnaStatusDto,
  HttpResponse,
  ResponsibilitySuggestionRatingRequest,
  TicketAssigneeChangeRequest,
  TicketCommentRequest,
  TicketRateRequest,
  TicketStatusChangeRequest,
} from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { isHttpError } from "helpers/Network/errors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useConfig } from "providers/ConfigProvider";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import type { ApiResponseType } from "types/api-types";

const useAddComment = () => {
  const api = useApi();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ ticketId, data }: { ticketId: string; data: TicketCommentRequest; fetchAllActivities?: boolean }) =>
      api.postTicketsCommentsV1(ticketId, data),
    onSuccess: (_, payload) => {
      queryClient.setQueryData(
        QUERY_KEYS.TICKETS_DETAILS(projectId, payload.ticketId),
        (oldData: ApiResponseType<"getAdminTicketsDetailsV1"> | undefined) => {
          if (!oldData) {
            return;
          }

          return {
            ...oldData,
            data: {
              ...oldData.data,
              activityCount: (oldData.data.activityCount || 0) + 1,
            },
          };
        },
      );
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.TICKETS_DETAILS_ACTIVITIES(projectId, payload.ticketId, {
          sortDescending: payload.fetchAllActivities,
        }),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, payload.ticketId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
    onError: (_, payload) => {
      showFlashToast({
        type: "error",
        title:
          payload.data.accessType === "internal"
            ? t("page.tickets.details.note.error")
            : t("page.tickets.details.reply.error"),
      });

      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, payload.ticketId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
  });
};

const useUpdateComment = () => {
  const api = useApi();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();

  return useMutation({
    mutationFn: ({
      ticketId,
      commentId,
      data,
    }: {
      ticketId: string;
      commentId: string;
      data: EditTicketCommentRequest;
    }) => api.putTicketsCommentsV1(ticketId, commentId, data),
    onSuccess: (_, payload) => {
      showFlashToast({ type: "success", title: t("page.tickets.details.edit-note.success") });

      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, payload.ticketId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.TICKETS_DETAILS_ACTIVITIES(projectId, payload.ticketId),
      });
    },
    onError: () => {
      showFlashToast({ type: "success", title: t("page.tickets.details.edit-note.error") });
    },
  });
};

const useUpdateAssignee = () => {
  const api = useApi();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();

  return useMutation({
    mutationFn: ({ ticketId, data }: { ticketId: string; data: TicketAssigneeChangeRequest }) =>
      api.putAdminTicketsAssigneeV1(ticketId, data),
    onMutate(payload) {
      // Optimistic update
      queryClient.setQueryData(
        QUERY_KEYS.TICKETS_DETAILS(projectId, payload.ticketId),
        (oldData: ApiResponseType<"getAdminTicketsDetailsV1"> | undefined) => {
          if (!oldData) {
            return;
          }

          return {
            ...oldData,
            data: {
              ...oldData.data,
              assignee: oldData.data.possibleAssignees.find((x) => x.id === payload.data.assigneeId)!,
            },
          };
        },
      );
    },
    onSuccess: (_, payload) => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, payload.ticketId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
    onError: (_, payload) => {
      showFlashToast({
        type: "error",
        title: t("page.tickets.details.assignee-change.error"),
      });

      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, payload.ticketId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
  });
};

const useUpdateStatus = () => {
  const api = useApi();
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();

  return useMutation({
    mutationFn: ({ ticketId, data }: { ticketId: string; data: TicketStatusChangeRequest }) =>
      api.putAdminTicketsStatusV1(ticketId, data),
    onMutate: (payload) => {
      // Optimistic update
      queryClient.setQueryData(
        QUERY_KEYS.TICKETS_DETAILS(projectId, payload.ticketId),
        (oldData: ApiResponseType<"getAdminTicketsDetailsV1"> | undefined) => {
          if (!oldData) {
            return;
          }

          return {
            ...oldData,
            data: {
              ...oldData.data,
              status: oldData.data.possibleStatuses.find((x) => x.id === payload.data.statusId)!,
            },
          };
        },
      );
    },
    onSuccess: (_, payload) => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, payload.ticketId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
    onError: (_, payload) => {
      showFlashToast({
        type: "error",
        title: t("page.tickets.details.status-change.error"),
      });

      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_DETAILS(projectId, payload.ticketId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId) });
    },
  });
};

const useUpdateResponsibilitySuggestion = (ticketId: string) => {
  const api = useApi();
  const projectId = useProjectId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ResponsibilitySuggestionRatingRequest) =>
      api.postAdminTicketsResponsibilitySuggestionV1(ticketId, data),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.TICKETS_RESPONSIBILITY_SUGGESTION(projectId, ticketId),
      });
    },
  });
};

const useGetTicketCopilotSuggestion = (ticketId: string) => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: { additionalInfo?: string }) =>
      api.postTicketsSuggestionV1(ticketId, data).then((res) => res.data),
    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.details.copilot.generate.error") });
    },
  });
};

const useSendTicketToHomeDna = (ticketId: string) => {
  const projectId = useProjectId();
  const queryClient = useQueryClient();
  const api = useApi();

  function overwriteHomeDna(response: Pick<HttpResponse<HomeDnaStatusDto>, "data">) {
    queryClient.setQueryData(
      QUERY_KEYS.TICKETS_DETAILS(projectId, ticketId),
      (oldData: ApiResponseType<"getAdminTicketsDetailsV1"> | undefined) => {
        if (!oldData) {
          return;
        }

        return {
          ...oldData,
          data: {
            ...oldData.data,
            homeDna: response.data,
          },
        };
      },
    );
  }

  return useMutation({
    mutationFn: () => api.postAdminTicketsHomednaV1(ticketId),
    onSuccess(response) {
      overwriteHomeDna(response);
    },
    onError(error) {
      const errorDetails =
        isHttpError(error) &&
        error.error &&
        typeof error.error === "object" &&
        "errorDetails" in error.error &&
        typeof error.error.errorDetails === "string"
          ? error.error.errorDetails
          : undefined;

      overwriteHomeDna({ data: { state: "failed", errorDetails } });
    },
  });
};

const useUpdateRating = (ticketId: string) => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const projectId = useProjectId();

  return useMutation({
    mutationFn: (payload: TicketRateRequest) =>
      api.putTicketsRateV1(ticketId, {
        remark: payload.remark,
        rating: payload.rating ?? 0,
        closedAt: payload.closedAt,
        feedbackOptions: payload.feedbackOptions,
      }),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_RESIDENT_DETAILS(projectId, ticketId) });
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.TICKETS_RESIDENT_DETAILS_COMMENTS(projectId, ticketId),
      });
    },
    async onError() {
      showFlashToast({
        type: "error",
        title: t("page.tickets.details.rate.error"),
      });

      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_RESIDENT_DETAILS(projectId, ticketId) });
    },
  });
};

const useGetQuickReplyLink = () => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const dashboardUrl = useConfig("newDashboardRootUrl");

  return useMutation({
    mutationFn: (ticketId: string) => api.postQuickReplyTicketCreateTokenV2({ ticketId }).then((x) => x.data),
    onSuccess: async (token) => {
      const quickReplyMessagePath = routes.quickReply.ticket({ token: `V2-${token}` });

      await navigator.clipboard.writeText(`${dashboardUrl}${quickReplyMessagePath}`);

      showFlashToast({ type: "success", title: t("page.tickets.copy-quick-reply-link.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.copy-quick-reply-link.error") });
    },
  });
};

export const ticketMutations = {
  useAddComment,
  useUpdateComment,
  useUpdateAssignee,
  useUpdateStatus,
  useUpdateRating,
  useUpdateResponsibilitySuggestion,
  useGetTicketCopilotSuggestion,
  useSendTicketToHomeDna,
  useGetQuickReplyLink,
};
