import { useInfiniteQuery } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import type { CompanyDto } from "api/types";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconRefreshCcw01 from "assets/icons/refresh-ccw-01.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { ContextMenuLegacyAction } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { ContextMenuLegacy } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Table } from "components/Table/Table";
import { useBool } from "hooks/useBool";
import { useDebounce } from "hooks/useDebounce";
import { useOnIntersection } from "hooks/useOnIntersection";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { canManageCompanies } from "modules/companies/permissions";
import { companyMutations } from "queries/companies/mutations";
import { useCompanyQueries } from "queries/companies/queryOptions";
import { useCallback, useDeferredValue, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

const DEBOUNCE_WAIT = 400;

export function CompaniesListPage(): React.ReactNode {
  const { t } = useTranslation();
  const slug = useSlug();
  const navigate = useNavigate();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<CompanyDto>("companies-delete-modal");
  const [locationCodeModalCompanyId, setLocationCodeModalCompanyId] = useState<string>();
  const [isRefreshingLocationCode, refreshingLocationCodeHandlers] = useBool();
  const [query, setQuery] = useState("");
  const deferredQuery = useDeferredValue(useDebounce(query.toLowerCase().trim(), DEBOUNCE_WAIT));
  const hasPermission = usePermission();
  const [sortBy, setSortBy] = useState<"name" | "activatedUsers" | "notActivatedUsers" | undefined>("name");
  const [sortDescending, setSortDescending] = useState(false);

  const companyQueries = useCompanyQueries();
  const {
    data: companiesData,
    isLoading: isLoadingCompanies,
    isFetchingNextPage: isFetchingMoreCompanies,
    isFetching: isFetchingCompanies,
    hasNextPage: hasMoreCompanies,
    fetchNextPage: loadMoreCompanies,
    error: companiesError,
  } = useInfiniteQuery(companyQueries.getCompaniesInfinite(deferredQuery, sortBy, sortDescending));
  const deleteCompany = companyMutations.useDeleteCompany();
  const refreshLocationCode = companyMutations.useRefreshLocationCode();

  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isFetchingMoreCompanies && hasMoreCompanies) {
        void loadMoreCompanies();
      }
    }, [isFetchingMoreCompanies, hasMoreCompanies, loadMoreCompanies]),
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<CompanyDto>();

    return [
      helper.accessor("name", {
        header: t("page.companies.list.name"),
        cell: (cell) =>
          hasPermission(canManageCompanies) ? (
            <Anchor to={routes.companies.edit({ slug, id: cell.row.original.id })}>{cell.getValue()}</Anchor>
          ) : (
            <span>{cell.getValue()}</span>
          ),
      }),
      helper.accessor("address", {
        header: t("page.companies.list.address"),
        cell: (cell) => (
          <span>
            {cell.getValue().locatedAt} ({cell.row.original.buildingName})
          </span>
        ),
        enableSorting: false,
      }),
      ...(hasPermission(canManageCompanies)
        ? [
            helper.accessor("locationCode", {
              header: t("page.companies.list.location-code"),
              cell: (cell) => (
                <div className="flex items-center justify-between gap-2">
                  <span>{cell.getValue()}</span>
                  {hasPermission(canManageCompanies) ? (
                    <IconButton
                      styling="tertiary"
                      title={t("page.companies.list.refresh-location-code")}
                      onClick={() => setLocationCodeModalCompanyId(cell.row.original.id)}
                      icon={iconRefreshCcw01}
                    />
                  ) : null}
                </div>
              ),
              enableSorting: false,
            }),
          ]
        : []),
      helper.accessor("activeUserCount", {
        header: t("page.companies.list.active-user-count"),
        cell: (cell) => <div>{cell.getValue()}</div>,
      }),
      ...(hasPermission(canManageCompanies)
        ? [
            helper.accessor("inactiveUserCount", {
              header: t("page.companies.list.inactive-user-count"),
              cell: (cell) => <div>{cell.getValue()}</div>,
            }),
            helper.accessor("id", {
              header: "",
              cell: (cell) => {
                const actions: ContextMenuLegacyAction[] = [];

                if (hasPermission(canManageCompanies)) {
                  actions.push({
                    text: t("common.action.edit"),
                    icon: <Icon name={iconEdit05} />,
                    callback: () => {
                      void navigate(routes.companies.edit({ slug, id: cell.getValue() }));
                    },
                  });
                }

                if (hasPermission(canManageCompanies)) {
                  actions.push({
                    callback: () => openDeleteModal(cell.row.original),
                    text: t("common.action.delete"),
                    dataTestId: "context-menu-delete-btn",
                    icon: <Icon name={iconTrash02} />,
                    status: {
                      disabled: !cell.row.original.canDelete,
                      disabledText: t("page.companies.list.actions.delete.forbidden"),
                    },
                  });
                }

                return (
                  <div className="flex justify-end px-2">
                    <ContextMenuLegacy actions={actions} />
                  </div>
                );
              },
              enableSorting: false,
            }),
          ]
        : []),
    ];
  }, [hasPermission, slug, navigate, openDeleteModal, t]);

  const companies = useMemo(() => companiesData?.pages.flatMap((x) => x.data.items) || [], [companiesData?.pages]);
  const totalCompanies = companiesData?.pages?.[0]?.data.total;

  const sortMapTableToApi = {
    name: "name",
    activeUserCount: "activatedUsers",
    inactiveUserCount: "notActivatedUsers",
  } as const;

  const sortMapApiToTable = {
    name: "name",
    activatedUsers: "activeUserCount",
    notActivatedUsers: "inactiveUserCount",
  } as const;

  const sorting =
    sortBy && sortBy in sortMapApiToTable
      ? [
          {
            id: sortMapApiToTable[sortBy],
            desc: sortDescending ?? false,
          },
        ]
      : [];

  const tableInstance = useReactTable<CompanyDto>({
    columns,
    data: companies,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange(updater) {
      const newValue = typeof updater === "function" ? updater(sorting) : updater;
      const newSort = newValue?.[0];

      if (newSort && newSort.id in sortMapTableToApi) {
        const sortId = sortMapTableToApi[newSort.id as keyof typeof sortMapTableToApi];
        setSortBy(sortId);
        setSortDescending(newSort.desc);

        return;
      }

      setSortBy(undefined);
      setSortDescending(false);
    },
    manualSorting: true,
    enableSortingRemoval: false,
  });

  if (companiesError) {
    return <ErrorPage error={companiesError} />;
  }

  if (isLoadingCompanies) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.companies.list.title")}
      subTitle={t("page.companies.list.subtitle")}
      actions={
        hasPermission(canManageCompanies) ? (
          <Button type="link" href={routes.companies.new({ slug })} data-testid="companies-create-link">
            {t("page.companies.list.actions.create")}
          </Button>
        ) : null
      }
      header={
        <div className="flex items-center justify-between">
          <SearchInput
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={t("page.companies.list.search.placeholder")}
          />
          {totalCompanies != null && (
            <p className="pl-2 text-right text-grey-700">
              {t("page.companies.list.company-count", { count: totalCompanies })}
            </p>
          )}
        </div>
      }
    >
      <DeleteModal
        title={t("page.companies.list.actions.delete.title")}
        description={t("page.companies.list.actions.delete.description")}
        onDelete={(company) => deleteCompany.mutateAsync(company.id)}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
      <ConfirmModal
        data-testid="location-code-modal"
        title={t("page.companies.list.location-code.refresh.title")}
        description={t("page.companies.list.location-code.refresh.description")}
        isOpened={!!locationCodeModalCompanyId}
        shouldCloseOnEsc
        onReject={() => setLocationCodeModalCompanyId(undefined)}
        onOpenChange={(state) => {
          if (!state) {
            setLocationCodeModalCompanyId(undefined);
          }
        }}
        rejectBtnProps={{
          text: t("page.companies.list.location-code.refresh.actions.cancel"),
        }}
        onResolve={async () => {
          try {
            if (locationCodeModalCompanyId) {
              refreshingLocationCodeHandlers.setTrue();
              await refreshLocationCode.mutateAsync(locationCodeModalCompanyId);
            }
          } finally {
            refreshingLocationCodeHandlers.setFalse();

            setLocationCodeModalCompanyId(undefined);
          }
        }}
        resolveBtnProps={{
          text: t("page.companies.list.location-code.refresh.actions.confirm"),
        }}
        isLoading={isRefreshingLocationCode}
      />
      {isLoadingCompanies && <FullSizeLoader withPadding />}
      {!isLoadingCompanies && (
        <div className="overflow-auto">
          {totalCompanies === 0 && (
            <div className="rounded-lg bg-white p-5">
              <p>{deferredQuery ? t("page.companies.list.no-result") : t("page.companies.list.empty")}</p>
            </div>
          )}
          {totalCompanies != null && totalCompanies > 0 && (
            <Table<CompanyDto>
              table={tableInstance}
              data-testid="companies-list"
              getRowProps={() => ({
                "data-testid": "companies-item",
              })}
              showSorting
              isLoading={isFetchingCompanies && !isFetchingMoreCompanies}
            />
          )}
          {hasMoreCompanies && (
            <div className="p-4" ref={ref}>
              <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
            </div>
          )}
        </div>
      )}
    </DocumentPaper>
  );
}
