import { useQuery, useQueryClient } from "@tanstack/react-query";
import type { AdminTicketCommentDto, UserDto } from "api/types";
import iconAnnotationInfo from "assets/icons/annotation-info.svg";
import { AIButton } from "components/Button/AIButton";
import { Notice } from "components/Notice/Notice";
import { useBool } from "hooks/useBool";
import { AnimatePresence, motion } from "motion/react";
import { usePostHog } from "posthog-js/react";
import { useConfig } from "providers/ConfigProvider";
import { ContentFieldProvider, useContentFieldManager } from "providers/ContentFieldProvider";
import { useTicketQueries } from "queries/tickets";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { TicketAIQuickDenialModal } from "./TicketAIQuickDenialModal";
import { TicketCommentFieldAIActions } from "./TicketCommentFieldAIActions";
import type { CommentPayload, CommentType } from "./TicketCommentFieldPure";
import { TicketCommentFieldPure } from "./TicketCommentFieldPure";

const MIN_TICKET_RESPONSIBILITY_SUGGESTION_CONFIDENCE = 40;

type TicketCommentFieldProps = {
  allowedCommentTypes: CommentType[];
  allowedAIActions?: AIAction[];
  user: UserDto;
  editingComment?: AdminTicketCommentDto;
  ticketId: string;
  isAttachmentAllowed?: boolean;
  onComment: (payload: CommentPayload) => Promise<unknown>;
  onEditComment?: (payload: CommentPayload & { commentId: string }) => Promise<void>;
  onCancelEditComment?: () => void;
};

type AIAction = "responsibilitySuggestion" | "generation";

enum ResponsibilitySuggestionState {
  UNKNOWN,
  AGREED,
  DISAGREED,
}

function TicketCommentFieldInternal({
  allowedCommentTypes,
  allowedAIActions,
  user,
  ticketId,
  editingComment,
  isAttachmentAllowed = true,
  onComment,
  onEditComment,
  onCancelEditComment,
}: TicketCommentFieldProps): React.ReactNode {
  // Quick denial
  const [isQuickDenialModalOpened, quickDenialModalHandlers] = useBool(false);
  const [responsibilitySuggestionState, setResponsibilitySuggestionState] = useState<ResponsibilitySuggestionState>(
    ResponsibilitySuggestionState.UNKNOWN,
  );

  const { token } = useParams<{ token?: string }>();
  const { onReset } = useContentFieldManager();

  const { t } = useTranslation();
  const postHog = usePostHog();
  const isAiTicketResponsibilitySuggestionAvailable = useConfig("isAiTicketResponsibilitySuggestionAvailable");

  const queryClient = useQueryClient();
  const ticketQueries = useTicketQueries();
  const { data: ticket } = useQuery({
    ...ticketQueries.details(ticketId),
  });
  const { data: responsibilitySuggestion } = useQuery({
    ...ticketQueries.responsibilitySuggestion(ticketId),
    enabled:
      Boolean(ticketId) && Boolean(ticket?.responsibilityAssignee) && isAiTicketResponsibilitySuggestionAvailable,
  });

  useEffect(() => {
    if (responsibilitySuggestion) {
      void queryClient.prefetchQuery(
        ticketQueries.denialReplySuggestion(ticketId, {
          additionalInfo: responsibilitySuggestion.description,
        }),
      );
    }
  }, [queryClient, ticketQueries, responsibilitySuggestion, ticketId, token]);

  async function onSend({ content, type, images, videos, documents }: CommentPayload) {
    if (editingComment && onEditComment) {
      await onEditComment({
        commentId: editingComment.id,
        content,
        images,
        videos,
        documents,
        type,
      });
    } else {
      await onComment({
        content,
        images,
        videos,
        documents,
        type,
      });
    }

    onReset();

    if (editingComment && onCancelEditComment) {
      onCancelEditComment();
    }
  }

  function onExpandSuggestion() {
    quickDenialModalHandlers.setTrue();

    postHog.capture("clicked_expand_ai_responsibility_suggestion", {
      ticketId,
    });
  }

  function onDisagreeSuggestion() {
    quickDenialModalHandlers.setFalse();
    setResponsibilitySuggestionState(ResponsibilitySuggestionState.DISAGREED);
  }

  // Use has just disgreed with the suggestion
  const hasUserDisagreedWithSuggestion =
    responsibilitySuggestionState === ResponsibilitySuggestionState.DISAGREED &&
    !!responsibilitySuggestion &&
    responsibilitySuggestion.rating === "bad";
  // The suggestion is relevant if it's for the tenant, not rated and has a high enough confidence
  const isResponsibilitySuggestionRelevant =
    !!responsibilitySuggestion &&
    responsibilitySuggestion.assignee === "tenant" &&
    responsibilitySuggestion.rating === "notRated" &&
    responsibilitySuggestion.confidence >= MIN_TICKET_RESPONSIBILITY_SUGGESTION_CONFIDENCE;
  // The suggestion is useful if the ticket is of status `new` and has no comments
  const isResponsibilitySuggestionUseful = ticket?.commentCount === 0 && ticket?.status.type === "new";
  // The suggestion is useful if the ticket is of status `new` and has no comments
  const isResponsibilitySuggestionShown =
    !!ticketId &&
    !!allowedAIActions?.includes("responsibilitySuggestion") &&
    isResponsibilitySuggestionUseful &&
    (isResponsibilitySuggestionRelevant || hasUserDisagreedWithSuggestion) &&
    isAiTicketResponsibilitySuggestionAvailable;

  return (
    <>
      <div className="flex flex-col gap-4">
        <AnimatePresence>
          {isResponsibilitySuggestionShown && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, delay: 0.6 }}
            >
              <Notice
                data-testid="ai-responsibility-suggestion-notice"
                type={hasUserDisagreedWithSuggestion ? "positive" : "info"}
                message={
                  hasUserDisagreedWithSuggestion
                    ? t("page.tickets.details.responsibility-suggestion-feedback")
                    : t("page.tickets.details.responsibility-suggestion-tenant")
                }
                icon={iconAnnotationInfo}
                onExpand={hasUserDisagreedWithSuggestion ? undefined : onExpandSuggestion}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <div className="flex flex-col gap-2">
          <TicketCommentFieldPure
            user={user}
            allowedCommentTypes={allowedCommentTypes}
            onCancelEdit={onCancelEditComment}
            commentInEditing={editingComment}
            onSend={onSend}
            actionComponent={
              allowedAIActions &&
              allowedAIActions.length > 0 && (
                <div className="flex w-full items-center justify-start gap-2">
                  {allowedAIActions.includes("generation") && <TicketCommentFieldAIActions {...{ ticketId }} />}
                  {allowedAIActions.includes("responsibilitySuggestion") &&
                    isResponsibilitySuggestionShown &&
                    !hasUserDisagreedWithSuggestion && (
                      <AIButton onClick={quickDenialModalHandlers.setTrue}>
                        {t("page.tickets.detials.quick-denial.button")}
                      </AIButton>
                    )}
                </div>
              )
            }
            isAttachmentAllowed={isAttachmentAllowed}
          />
        </div>
      </div>
      {ticket && responsibilitySuggestion && (
        <TicketAIQuickDenialModal
          isOpened={isQuickDenialModalOpened}
          onOpenChange={quickDenialModalHandlers.set}
          description={responsibilitySuggestion.description}
          onDisagree={onDisagreeSuggestion}
          ticket={ticket}
        />
      )}
    </>
  );
}

export const TicketCommentField = (props: TicketCommentFieldProps): React.ReactNode => {
  return (
    <ContentFieldProvider>
      <TicketCommentFieldInternal {...props} />
    </ContentFieldProvider>
  );
};
