import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { DocumentsItemDto, DocumentsListDto } from "api/types";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconMove from "assets/icons/move.svg";
import iconPlus from "assets/icons/plus.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import documentEmpty from "assets/images/document-empty.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import type { ContextMenuLegacyAction } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { ContextMenuLegacy } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Pagination } from "components/Pagination/Pagination";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Table } from "components/Table/Table";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { useSlug } from "hooks/useSlug";
import { DocumentsFileIcon } from "modules/documents/components/DocumentsFileIcon";
import { DocumentsMoveItemModal } from "modules/documents/components/DocumentsMoveItemModal";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

export interface LayoutProps {
  folderId: string;
  documentList?: DocumentsListDto;
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  deleteFolderItem: (props: { id: string; isFolder: boolean }) => Promise<void>;
}

const ROOT_FOLDER_ID = "root";

export function Layout(props: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { componentProps, openDeleteModal } = useDeleteModal<FolderItemDeleteParam>("document-delete-modal");
  const [moveItem, setMoveItem] = useState<DocumentsItemDto | undefined>(undefined);
  const canAddItems = props.documentList?.canAddNew || false;

  const columns = useMemo(() => {
    const helper = createColumnHelper<Omit<DocumentsItemDto, "parent">>();

    return [
      helper.accessor("name", {
        header: t("page.document.list.columns.name.title"),
        cell: (cell) => {
          const itemName = (
            <div className="inline-flex max-w-[500px] items-center gap-2">
              <span className="shrink-0">
                <DocumentsFileIcon type={cell.row.original.type} />
              </span>
              <span className="truncate pr-0.5 text-body-bold">{cell.getValue()}</span>
            </div>
          );

          return cell.row.original.type === "folder" ? (
            <Anchor
              isBold
              style="inherit"
              data-testid={`folder_${cell.getValue()}`}
              to={routes.documents.documentsList({ slug, folderId: cell.row.original.id })}
            >
              {itemName}
            </Anchor>
          ) : (
            <span>{cell.getValue()}</span>
          );
        },
      }),
      helper.accessor("createdAt", {
        header: t("page.document.list.columns.created.title"),
        cell: (cell) => <FormattedDate date={cell.getValue()} format="dateRelative" />,
      }),
      helper.accessor("document", {
        header: t("page.document.list.columns.file.title"),
        cell: (cell) => {
          const value = cell.getValue();

          return value ? (
            <a
              className="break-all text-aop-basic-blue-500 hover:underline"
              data-testid={`file_${cell.row.original.name}`}
              href={value?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {value?.fileName || value.url}
            </a>
          ) : (
            <span>-</span>
          );
        },
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          const id = cell.getValue();

          const actions: ContextMenuLegacyAction[] = [];

          actions.push({
            text: t("page.document.list.action.edit"),
            icon: <Icon name={iconEdit05} />,
            callback: () => {
              if (cell.row.original.type === "folder") {
                void navigate(routes.documents.folderEdit({ slug, folderId: id }));
              } else {
                void navigate(routes.documents.fileEdit({ slug, folderId: props.folderId, fileId: id }));
              }
            },
            status: {
              disabled: !cell.row.original.canEdit,
              disabledText: t("model.document.folder.action.edit.not-allowed"),
            },
          });

          actions.push({
            text: t("page.document.list.action.move"),
            icon: <Icon name={iconMove} />,
            callback: () => setMoveItem(cell.row.original as DocumentsItemDto),
            status: {
              disabled: !cell.row.original.canMove,
              disabledText: t("model.document.folder.action.move.not-allowed"),
            },
          });

          actions.push({
            text: t("common.action.delete"),
            icon: <Icon name={iconTrash02} />,
            callback: () => {
              openDeleteModal({ id, isFolder: cell.row.original.type === "folder" });
            },
            status: {
              disabled: !cell.row.original.canDelete,
              disabledText: t("model.document.folder.action.delete.not-allowed"),
            },
          });

          if (!actions.length) {
            return null;
          }

          return (
            <div className="flex justify-end">
              <ContextMenuLegacy actions={actions} />
            </div>
          );
        },
      }),
    ];
  }, [openDeleteModal, props.folderId, slug, navigate, t]);

  const screenIsBiggerThanMd = useScreenIsBiggerThan("md");
  const hiddenColumns = { createdAt: screenIsBiggerThanMd };

  const folderTable = useReactTable({
    columns,
    data: props.documentList?.items ?? [],
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      columnVisibility: hiddenColumns,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  if (props.documentList == null) {
    return null;
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={props.documentList.name}
      subTitle={
        <Breadcrumbs
          pages={[
            { name: t("model.document.breadcrumbs.root"), id: ROOT_FOLDER_ID },
            ...props.documentList.ancestors,
            { name: props.documentList.name, id: props.folderId },
          ].map(({ name, id: folderId }) => ({
            to:
              folderId === ROOT_FOLDER_ID
                ? routes.documents.rootList({ slug })
                : routes.documents.documentsList({ slug, folderId }),
            name,
          }))}
        />
      }
      actions={
        canAddItems ? (
          <div className="flex gap-4">
            <Button
              type="link"
              data-testid="documents-folder-create"
              styling="secondary"
              icon={<Icon name={iconPlus} />}
              href={routes.documents.folderCreate({ slug, folderId: props.folderId })}
            >
              {t("page.document.list.action.new-folder")}
            </Button>
            <Button
              type="link"
              data-testid="documents-file-upload"
              href={routes.documents.fileCreate({ slug, folderId: props.folderId })}
            >
              {t("page.document.list.action.new-item")}
            </Button>
          </div>
        ) : null
      }
    >
      <div className="flex flex-col">
        {props.documentList.items.length ? (
          <div>
            <Table table={folderTable} getRowProps={() => ({ "data-testid": "documents-folder-list-item" })} />
            <Pagination count={props.totalPages} currentIndex={props.page} onChange={props.onPageChange} />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center overflow-hidden rounded-lg bg-white py-24 text-center">
            <img className="mb-6 w-80 max-w-full" src={documentEmpty} alt="" role="presentation" />
            <h2 className="text-body-bold">{t("page.document.root.list.empty.title")}</h2>
            <p className="text-grey-700">{t("page.document.root.list.empty.description")}</p>
          </div>
        )}
        <DocumentsMoveItemModal
          item={moveItem}
          parentFolderId={props.folderId}
          onClose={() => setMoveItem(undefined)}
        />
        <DeleteModal<FolderItemDeleteParam>
          title={
            componentProps.modalContext?.isFolder
              ? t("model.document.folder.action.delete.confirmation")
              : t("model.document.file.action.delete.confirmation")
          }
          description={
            componentProps.modalContext?.isFolder
              ? t("model.document.folder.action.delete.description")
              : t("model.document.file.action.delete.description")
          }
          onDelete={props.deleteFolderItem}
          {...componentProps}
        />
      </div>
    </DocumentPaper>
  );
}

type FolderItemDeleteParam = { id: string; isFolder: boolean };
