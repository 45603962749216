import type { ChatDto, ChatReplyDto } from "api/types";
import iconLogOut01 from "assets/icons/log-out-01.svg";
import iconPaperclip from "assets/icons/paperclip.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import users01Icon from "assets/icons/users-01.svg";
import type { ContextMenuLegacyAction } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { ContextMenuLegacy } from "components/ContextMenuLegacy/ContextMenuLegacy";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { UserDeletedTag } from "components/UserDeletedTag/UserDeletedTag";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { chatMutations } from "queries/chats";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import { SystemChatMessage } from "./SystemChatMessage";

interface ChatListItemProps {
  chatDetails: ChatDto;
  index: number;
  isLast: boolean;
}

export function ChatListItem({ chatDetails, index, isLast }: ChatListItemProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();

  const mutationDeleteChat = chatMutations.useDeleteChat();
  const mutationLeaveGroupChat = chatMutations.useLeaveGroupChat({});

  const getChatName = (chat: ChatDto) => {
    if (chat.chatType === "single") {
      return chat.privateChatInfo!.receiver.fullName;
    } else {
      return chat.groupChatInfo!.name;
    }
  };

  const getChatPath = (chat: ChatDto) => {
    if (chat.chatType === "single") {
      return routes.users.details({ slug, id: chat.privateChatInfo!.receiver.id });
    } else {
      return routes.chats.details({ slug, id: chat.id });
    }
  };

  const isChatGroupChat = chatDetails.chatType === "group";
  const isLeaveActionAvailable =
    isChatGroupChat && chatDetails.groupChatInfo?.isMember && chatDetails.groupChatInfo?.canLeave;
  const isDeleteActionAvailable = !isChatGroupChat || !chatDetails.groupChatInfo?.isMember;

  const menuActions: ContextMenuLegacyAction[] = [];
  if (isLeaveActionAvailable) {
    menuActions.push({
      text: t("common.action.leave"),
      icon: <Icon name={iconLogOut01} />,
      callback: () => mutationLeaveGroupChat.mutate({ chatId: chatDetails.id }),
    });
  }
  if (isDeleteActionAvailable) {
    menuActions.push({
      icon: <Icon name={iconTrash02} />,
      text: t("common.action.delete"),
      callback: () => mutationDeleteChat.mutate({ chatId: chatDetails.id }),
    });
  }

  return (
    <li data-testid="conversation-row">
      <Link
        className={twJoin(
          "relative grid w-full cursor-pointer grid-cols-2 items-center gap-2 border-b border-b-grey-100 p-4 focus:outline-none focus-visible:cursor-pointer focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-aop-basic-blue-500",
          index % 2 === 0 ? "bg-white" : "bg-grey-50",
          index === 0 && "rounded-t-md",
          isLast && "rounded-b-md",
        )}
        to={getChatPath(chatDetails)}
      >
        <div className="flex items-center gap-2">
          {/* Chat avatar */}
          <div className="size-10 shrink-0">
            {chatDetails.chatType === "single" && (
              <UserAvatar
                img={chatDetails.privateChatInfo!.receiver.avatar}
                isUserDeleted={!!chatDetails.privateChatInfo!.receiver.deletedAt}
                hideDeletedIcon
              />
            )}
            {chatDetails.chatType === "group" && chatDetails.groupChatInfo?.image && (
              <UserAvatar img={chatDetails.groupChatInfo.image} hideDeletedIcon />
            )}
            {chatDetails.chatType === "group" && !chatDetails.groupChatInfo?.image && (
              <div className="flex size-full items-center justify-center rounded-full border border-grey-100 p-1 text-grey-400">
                <Icon name={users01Icon} size={24} />
              </div>
            )}
          </div>
          <div className="flex flex-col">
            {/* Chat name */}
            <div className="flex items-center gap-2">
              <span
                className={twJoin(
                  "max-w-32 truncate whitespace-nowrap text-body-bold lg:max-w-72",
                  chatDetails.chatType === "single" && chatDetails.privateChatInfo!.receiver.deletedAt
                    ? "text-grey-500"
                    : "text-black",
                )}
              >
                {getChatName(chatDetails)}
              </span>
              {chatDetails.chatType === "single" && !!chatDetails.privateChatInfo!.receiver.deletedAt && (
                <UserDeletedTag />
              )}
            </div>
            {/* Chat latest reply */}
            {chatDetails.latestReply && (
              <div className="w-20 lg:w-fit lg:max-w-72">
                <ChatListItemLatestReplyPreview
                  chatName={chatDetails.groupChatInfo?.name}
                  chatType={chatDetails.chatType}
                  latestReply={chatDetails.latestReply}
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center gap-2 justify-self-end">
          <div className="flex flex-col items-end justify-start gap-2">
            {chatDetails.latestReply?.sentAt && (
              <p
                className={twJoin(
                  "w-20 truncate text-caption lg:w-48",
                  chatDetails.hasUnreadReply ? "text-aop-basic-blue-500" : "text-grey-700",
                )}
              >
                <FormattedDate format="datetime" date={chatDetails.latestReply.sentAt} />
              </p>
            )}
            {chatDetails.unreadReplies > 0 && (
              <div className="flex size-5 items-center justify-center rounded-full bg-aop-basic-blue-500 p-1 text-white">
                <span className="text-overline">{chatDetails.unreadReplies}</span>
              </div>
            )}
          </div>
          {menuActions.length > 0 && <ContextMenuLegacy actions={menuActions} />}
        </div>
      </Link>
    </li>
  );
}

type ChatListItemLatestReplyPreviewProps = {
  chatType: ChatDto["chatType"];
  chatName: string | undefined;
  latestReply: ChatReplyDto;
};

const ChatListItemLatestReplyPreview = ({ chatName, chatType, latestReply }: ChatListItemLatestReplyPreviewProps) => {
  const sessionUser = useSessionUser();
  const { t } = useTranslation();

  const isSystemReply = latestReply.replyType?.includes("system");
  const isMemberReply = latestReply.replyType?.includes("member");
  const isAttachmentOnlyReply = !latestReply.text && (latestReply.image || latestReply.videos);

  return (
    <div className="flex h-5 w-full items-center">
      {isSystemReply && (
        <SystemChatMessage chatName={chatName} chatMessage={latestReply} className="w-full truncate text-grey-700" />
      )}
      {isMemberReply && latestReply.text && (
        <span className="sentry-block w-full truncate text-caption text-grey-700">
          {chatType === "group" && (
            <span className="font-old-semibold">{`${latestReply.author?.id === sessionUser.id ? t("page.chats.list.self-message") : latestReply.author?.fullName}: `}</span>
          )}
          {latestReply.text}
        </span>
      )}
      {isMemberReply && isAttachmentOnlyReply && (
        <span className="flex w-full items-center gap-1 truncate text-caption text-grey-700">
          {chatType === "group" && (
            <span className="text-overline-bold">{`${latestReply.author?.id === sessionUser.id ? t("page.chats.list.self-message") : latestReply.author?.fullName}: `}</span>
          )}
          <Icon name={iconPaperclip} className="text-grey-400" />
          <span className="text-caption">{t("page.chats.attachment")}</span>
        </span>
      )}
    </div>
  );
};
