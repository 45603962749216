import { Checkbox } from "antd";
import { Button } from "components/Button/Button";
import { Label } from "components/Label/Label";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { USER_BULK_UPLOAD_FORM_ID } from "../Layout";

type UserBulkUploadConfirmModalProps = ModalBaseProps;

export function UserBulkUploadConfirmModal({
  isOpened,
  onOpenChange,
}: UserBulkUploadConfirmModalProps): React.ReactNode {
  const [isConfirmed, setIsConfirmed] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setIsConfirmed(false);
  }, [isOpened]);

  return (
    <Modal.Root
      title={t("page.user-bulk-upload.confirm-modal.title")}
      labelComponent={<Label theme="yellow">Warning</Label>}
      description={t("page.user-bulk-upload.confirm-modal.description")}
      {...{ isOpened, onOpenChange }}
    >
      <label className="inline-flex gap-2 py-4" htmlFor="confirm-checkbox">
        <Checkbox
          id="confirm-checkbox"
          name="isConfirmed"
          checked={isConfirmed}
          onChange={(e) => setIsConfirmed(e.target.checked)}
        />
        <span>{t("page.user-bulk-upload.form.confirm-checkbox.label")}</span>
      </label>
      <Modal.Actions>
        <Modal.Close>
          <Button styling="secondary">{t("common.action.cancel")}</Button>
        </Modal.Close>
        <Modal.Close>
          <Button disabled={!isConfirmed} type="submit" form={USER_BULK_UPLOAD_FORM_ID}>
            {t("common.action.confirm")}
          </Button>
        </Modal.Close>
      </Modal.Actions>
    </Modal.Root>
  );
}
