import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CommunityFeedSurveyDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import iconClock from "assets/icons/clock.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { ContextMenuItem } from "components/ContextMenu/ContextMenu";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { parseISO } from "date-fns";
import { formatDistance } from "helpers/date";
import { getDeepLinkUrl } from "helpers/deep-link";
import { useProject } from "hooks/Network/useProject";
import { useBool } from "hooks/useBool";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";

import { CommunityItem } from "./CommunityItem";

interface CommunitySurveyProps {
  survey: CommunityFeedSurveyDto;
  updatedAt?: string;
}

export function CommunitySurvey({ survey, updatedAt }: CommunitySurveyProps): React.ReactNode {
  const project = useProject();
  const api = useApi();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();

  const [isAppSurveyModalOpen, appSurveyModalHandler] = useBool(false);

  const { mutateAsync: markAsRead } = useMutation({
    mutationFn: () => api.postSurveysReadV1(survey.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [...QUERY_KEYS.MESSAGES(project.id), "FEED"],
      });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_FEED_STATS(project.id) });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-survey.mark-as-read.error") });
    },
  });

  const menuActions: ContextMenuItem[] = [
    {
      labels: {
        default: t("component.community-post.actions.mark-as-read"),
      },
      "data-testid": "post-context-menu-mark-read",
      value: "markAsRead",
      icon: iconCheck,
      type: "button",
      callbackFn: markAsRead,
      isHidden: !survey.isUnread,
    },
  ];

  if (!survey) {
    return null;
  }

  return (
    <>
      <CommunityItem
        ring={survey.isUnread ? "unread" : undefined}
        user={survey.createdBy}
        group={survey.group}
        actions={menuActions}
        dateSubtitle={survey.startsAt && <FormattedDate format="datetime" date={survey.startsAt} />}
      >
        <div className="flex flex-col gap-2 md:flex-row">
          <div className="flex flex-1 flex-col gap-2">
            <div className="flex max-w-[500px] flex-col gap-4 rounded-md p-2 shadow-sm">
              <div className="flex w-fit items-center gap-1 rounded-md bg-aop-basic-blue-100 px-2 py-1 text-aop-basic-blue-500">
                <Icon name={iconClock} size={16} />
                {survey.endsAt && new Date(survey.endsAt) > new Date() ? (
                  <span className="text-caption">
                    {formatDistance(t, { start: new Date(), end: parseISO(survey.endsAt) })}
                  </span>
                ) : (
                  <span className="text-caption">{t("component.community-post.survey-ended")}</span>
                )}
              </div>
              <span className="break-words text-headline3 leading-old-headline4 text-grey-900">{survey.title}</span>
              <p className="text-grey-900">
                <LinkFormatter>{survey.description}</LinkFormatter>
              </p>
              <Button
                className="w-full text-caption-bold"
                styling="primary"
                type="link"
                href={getDeepLinkUrl(project.appBranding, "/answer-survey")}
                onClick={() => void markAsRead()}
                target="_blank"
                isExternal
              >
                {t("component.community-survey.button")}
              </Button>
            </div>
          </div>
        </div>
        <div className="-my-1 flex flex-row-reverse items-center justify-between gap-4 pb-2">
          {updatedAt && (
            <span className="text-caption text-grey-500">
              {t("component.community-post.last-activity", {
                time: formatDistance(t, { start: new Date(updatedAt) }),
              })}
            </span>
          )}
        </div>
      </CommunityItem>
      <ConfirmModal
        title={t("component.community-post.app-survey.modal.title")}
        description={t("component.community-post.app-survey.modal.description")}
        renderDescription={(description) => (
          <div className="flex flex-col items-center">
            <span>{description}</span>
            <Anchor to={t("component.community-post.app-survey.modal.link")}>
              {t("component.community-post.app-survey.modal.link")}
            </Anchor>
          </div>
        )}
        isLoading={false}
        theme="info"
        onResolve={appSurveyModalHandler.setFalse}
        onReject={appSurveyModalHandler.setFalse}
        rejectBtnProps={{ hide: true }}
        resolveBtnProps={{
          "data-testid": "app-survey-modal-confirm",
          text: t("common.action.ok"),
        }}
        isOpened={isAppSurveyModalOpen}
        shouldCloseOnEsc
        data-testid="cancel-app-survey-modal"
        onOpenChange={appSurveyModalHandler.set}
      />
    </>
  );
}
