import { Popover as ArkPopover } from "@ark-ui/react";
import type { MessageReactionDto, MessageV2Dto } from "api/types";
import iconThumbsUp from "assets/icons/thumbs-up.svg";
import imgLaugh from "assets/images/reactions/laugh.png";
import imgLike from "assets/images/reactions/like.png";
import imgLove from "assets/images/reactions/love.png";
import imgSad from "assets/images/reactions/sad.png";
import imgWow from "assets/images/reactions/wow.png";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { twResolve } from "helpers/tw-resolve";
import { useBool } from "hooks/useBool";
import { useKey } from "hooks/useKey";
import { motion } from "motion/react";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { getReactionImage, getReactionLabel } from "./helpers";

type CommunityPostReactionButtonProps = {
  size?: "sm" | "md";
  reaction: MessageV2Dto["requesterReactionType"];
  isDisabled?: boolean;
  onClickReaction: (reaction: MessageV2Dto["requesterReactionType"]) => void;
};

export function CommunityPostReactionButton({
  size = "md",
  reaction,
  isDisabled,
  onClickReaction,
}: CommunityPostReactionButtonProps): React.ReactNode {
  const [isMenuOpened, menuOpenHandlers] = useBool(false);

  const { t } = useTranslation();
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  useKey("Escape", menuOpenHandlers.setFalse);

  const handleHover = () => {
    if (timer) {
      clearTimeout(timer);
      setTimer(undefined);
    }

    menuOpenHandlers.setTrue();
  };

  const handleUnhover = () => {
    const newTimer = setTimeout(menuOpenHandlers.setFalse, 500);
    setTimer(newTimer);
  };

  const handleClickReaction = (newReaction: MessageReactionDto["reactionType"] | undefined) => {
    if (newReaction === reaction) {
      onClickReaction(undefined);
    } else {
      onClickReaction(newReaction);
    }

    menuOpenHandlers.setFalse();
  };

  const availableReactions: { img: string; label: string; value: MessageReactionDto["reactionType"] }[] = [
    {
      img: imgLike,
      label: t("component.community-post.reaction.like.button"),
      value: "like",
    },
    {
      img: imgLove,
      label: t("component.community-post.reaction.love.button"),
      value: "love",
    },
    {
      img: imgLaugh,
      label: t("component.community-post.reaction.laugh.button"),
      value: "laugh",
    },
    {
      img: imgSad,
      label: t("component.community-post.reaction.sad.button"),
      value: "sad",
    },
    {
      img: imgWow,
      label: t("component.community-post.reaction.wow.button"),
      value: "wow",
    },
  ];

  return (
    <div
      className="relative"
      onMouseEnter={isDisabled ? undefined : handleHover}
      onMouseLeave={isDisabled ? undefined : handleUnhover}
    >
      <Button
        size={size === "md" ? "md" : "sm"}
        styling={size === "md" ? "tertiary" : "ghostSecondary"}
        data-testid={size === "md" ? "react-btn" : "comment-react-btn"}
        className={twJoin(
          size === "md" && reaction && "text-body-bold",
          size === "sm" && reaction && "text-caption-bold",
          isMenuOpened && size === "md" && "bg-aop-basic-blue-100",
          isMenuOpened && size === "sm" && "text-aop-basic-blue-500",
          reaction && "text-aop-basic-blue-500",
        )}
        aria-label={reaction && getReactionLabel(reaction, t)}
        icon={!reaction && <Icon name={iconThumbsUp} />}
        onFocus={handleHover}
        onClick={() => handleClickReaction(reaction ?? "like")}
        disabled={isDisabled}
        isCircular={size === "md"}
      >
        <div className="flex flex-row items-center gap-1">
          {reaction && (
            <img
              src={getReactionImage(reaction)}
              alt={getReactionLabel(reaction, t)}
              className={twJoin(size === "md" && "h-5", size === "sm" && "h-4")}
            />
          )}
          <span>{reaction ? getReactionLabel(reaction, t) : t("component.community-post.like")}</span>
        </div>
      </Button>

      {/* Invisible connector element that creates a hover bridge */}
      {isMenuOpened && (
        <>
          <div className="absolute -top-2 left-0 h-2 w-full" aria-hidden />
          <div className="absolute -bottom-2 left-0 h-2 w-full" aria-hidden />
        </>
      )}

      <ArkPopover.Root
        positioning={{
          placement: "top-start",
        }}
        initialFocusEl={undefined}
        portalled
        lazyMount
        unmountOnExit
        open={isMenuOpened}
        onFocusOutside={menuOpenHandlers.setFalse}
      >
        <ArkPopover.Anchor className="absolute bottom-0 left-0 h-full" />
        <ArkPopover.Positioner>
          <ArkPopover.Content>
            <motion.div
              data-testid="reaction-menu"
              variants={{
                initial: { scale: 0 },
                animate: {
                  scale: 1,
                },
              }}
              transition={{
                staggerChildren: 0.04,
              }}
              initial="initial"
              animate={isMenuOpened ? "animate" : "initial"}
              className="z-10 flex origin-bottom-left gap-2 overflow-x-hidden rounded-full border border-grey-100 bg-white px-3 py-2 shadow-md"
            >
              {availableReactions.map((availableReaction) => (
                <motion.button
                  aria-label={availableReaction.label}
                  key={availableReaction.value}
                  variants={{
                    initial: { scale: 0 },
                    animate: {
                      scale: 1,
                      transition: {
                        duration: 0.1,
                      },
                    },
                  }}
                  aria-selected={reaction === availableReaction.value}
                  onClick={() => handleClickReaction(availableReaction.value)}
                  className={twResolve(
                    "size-10 shrink-0 rounded-full transition-transform duration-300 hover:!scale-[1.2] focus-visible:!scale-[1.2]",
                  )}
                >
                  <img src={availableReaction.img} alt={availableReaction.label} />
                </motion.button>
              ))}
            </motion.div>
          </ArkPopover.Content>
        </ArkPopover.Positioner>
      </ArkPopover.Root>
    </div>
  );
}
