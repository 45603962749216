import type { VideoDto } from "api/types";
import iconPlay from "assets/icons/play.svg";
import ProcessingVideoBackground from "assets/images/processing-video-background.png";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { VideoFilePreview } from "components/VideoFilePreview/VideoFilePreview";
import { VideoModal } from "components/VideoModal/VideoModal";
import { useBool } from "hooks/useBool";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { CommunityItemImageSkeleton } from "./CommunityItemImage";
import { getAspectRatio } from "./helpers";

interface CommunityItemVideoProps {
  video: VideoDto;
  shouldLoad?: boolean;
}

export const CommunityItemVideo = ({ video, shouldLoad = true }: CommunityItemVideoProps): React.ReactNode => {
  const [isVideoModalOpened, videoModalOpenHandlers] = useBool();
  const [isLoadingThumbnail, setIsLoadingThumbnail] = useState(true);

  const { t } = useTranslation();

  if (video.processingStatus === "processed" && video.thumbnail) {
    const thumbnailSrc = shouldLoad ? video.thumbnail.url : "";

    return (
      <>
        <button
          type="button"
          onClick={videoModalOpenHandlers.setTrue}
          className="relative max-h-[574px] w-full max-w-full cursor-pointer overflow-hidden rounded-md bg-black/20 transition-opacity hover:opacity-90 xs:max-h-[360px] xs:w-fit"
          style={{ aspectRatio: getAspectRatio(video.thumbnail) }}
        >
          {isLoadingThumbnail && <CommunityItemImageSkeleton />}

          {/* Overlay */}
          <div className="absolute inset-0 flex items-center justify-center bg-black/20">
            <span className="flex size-10 items-center justify-center rounded-full bg-black">
              <Icon className="ml-3px fill-white" name={iconPlay} size={20} />
            </span>
            {video.formattedDuration && (
              <span className="absolute bottom-2 right-2 rounded-lg bg-black px-2 py-1 text-caption text-white">
                {video.formattedDuration}
              </span>
            )}
          </div>

          {/* Thumbnail */}
          <img
            className={twJoin(
              "max-h-full max-w-full object-contain",
              isLoadingThumbnail ? "opacity-0" : "opacity-100 transition-opacity hover:opacity-90",
            )}
            src={thumbnailSrc}
            alt={video.thumbnail.description}
            onLoad={() => setIsLoadingThumbnail(false)}
            width={video.thumbnail.metadata.width}
            height={video.thumbnail.metadata.height}
          />
        </button>

        <VideoModal isOpened={isVideoModalOpened} videoSrc={video.url} onOpenChange={videoModalOpenHandlers.set} />
      </>
    );
  }

  // Edge case: Video was not required to be processed
  // Should NOT happen in production
  if (video.processingStatus === "skipped") {
    return <VideoFilePreview {...{ video }} />;
  }

  // Processing
  return (
    <div className="relative aspect-video overflow-hidden rounded-lg">
      <img
        src={ProcessingVideoBackground}
        alt={t("component.video-preview.placeholder-image.alt")}
        className="size-full object-cover"
      />
      <div className="absolute inset-0 z-10 flex items-center justify-center bg-black/40 backdrop-blur-md">
        <div className="z-20 flex size-10 items-center justify-center rounded-full bg-black">
          <LoadingIcon className="size-5 text-white" />
        </div>
        <p className="absolute bottom-2 left-1/2 w-full max-w-[calc(100%-2rem)] -translate-x-1/2 rounded-md bg-black p-1 text-center text-caption text-white sm:max-w-[calc(100%-2rem)] md:bottom-4 md:p-2 md:text-body">
          {t("component.video-preview.processing.description")}
        </p>
      </div>
    </div>
  );
};
