import { FormDocumentInput } from "components/Form/FormDocumentInput";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormVideoInput } from "components/Form/FormVideoInput";
import { ALLOWED_FILE_TYPES } from "helpers/file-types";
import {
  createDocumentSizeRule,
  createDocumentTypeRule,
  createImageSizeRule,
  createImageTypeRule,
  createVideoSizeRule,
  createVideoTypeRule,
} from "helpers/rules";
import { MAX_AMOUNT_IMAGES_PER_POST } from "modules/messages/constants";
import type { AdminCreatePostFormValues } from "modules/messages/pages/AdminCreatePost/Layout";
import { usePostHog } from "posthog-js/react";
import type React from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

export function AdminCreatePostAttachmentField(): React.ReactNode {
  const { t } = useTranslation();
  const postHog = usePostHog();

  const selectedImages = useWatch<AdminCreatePostFormValues, "images">({ name: "images" });
  const selectedVideos = useWatch<AdminCreatePostFormValues, "videos">({ name: "videos" });
  const selectedDocuments = useWatch<AdminCreatePostFormValues, "documents">({ name: "documents" });

  const isAnyAttachmentSelected =
    selectedDocuments.length > 0 || selectedImages.length > 0 || selectedVideos.length > 0;

  return (
    <FormField label={t("page.create-post.form.file")}>
      <div className="flex flex-wrap gap-2">
        {(!isAnyAttachmentSelected || selectedImages.length > 0) && (
          <FormImageInput<AdminCreatePostFormValues, "images">
            name="images"
            texts={{
              add: t("page.create-post.form.file.image"),
            }}
            nOfImages={MAX_AMOUNT_IMAGES_PER_POST}
            rules={{
              validate: {
                size: createImageSizeRule({ t }),
                type: createImageTypeRule({ t }),
              },
            }}
          />
        )}
        {(!isAnyAttachmentSelected || selectedVideos.length > 0) && (
          <FormVideoInput<AdminCreatePostFormValues, "videos">
            name="videos"
            texts={{
              add: t("page.create-post.form.file.video"),
            }}
            rules={{
              validate: {
                size: createVideoSizeRule({ t, onError: () => postHog.capture("form_error_video_size_exceeded") }),
                type: createVideoTypeRule({ t }),
              },
            }}
            shouldProcessVideo
          />
        )}
        {(!isAnyAttachmentSelected || selectedDocuments.length > 0) && (
          <FormDocumentInput<AdminCreatePostFormValues, "documents">
            name="documents"
            accept={ALLOWED_FILE_TYPES.DOCUMENT}
            texts={{
              add: t("page.create-post.form.file.document"),
            }}
            rules={{
              validate: {
                size: createDocumentSizeRule({
                  t,
                }),
                type: createDocumentTypeRule({
                  t,
                }),
              },
            }}
          />
        )}
      </div>
    </FormField>
  );
}
