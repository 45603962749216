import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { AIAnalysisModalState } from "components/AIAnalysisModal/AIAnalysisModal";
import { AIAnalysisModal } from "components/AIAnalysisModal/AIAnalysisModal";
import type { ModalBaseProps } from "components/Modal/Modal";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

type SurveyOpenQuestionResponseSentimentModalProps = ModalBaseProps & {
  questionId: string;
};

export function SurveyOpenQuestionResponseSentimentModal({
  questionId,
  isOpened,
  onOpenChange,
}: SurveyOpenQuestionResponseSentimentModalProps): React.ReactNode {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const api = useApi();
  const { id: surveyId } = useParams<{ id: string }>();
  const { data, isPending, isError } = useQuery({
    queryKey: QUERY_KEYS.SURVEY_DETAILS_RESPONSE_SUMMARY(projectId, surveyId!, questionId),
    queryFn: () => api.getSurveysSummaryV1(surveyId!, questionId, { markdown: true }),
    select: commonAPIDataSelector,
    enabled: isOpened,
    staleTime: 1000 * 60 * 5,
  });

  let state: AIAnalysisModalState = "loading";
  if (!isPending && !isError && data) {
    state = "success";
  } else if (!isPending && isError) {
    state = "error";
  } else if (!isPending && !isError && !data) {
    state = "unavailable";
  }

  return (
    <AIAnalysisModal
      data={{
        sentiment: data?.sentiment || 0,
        summary: data?.summary || "",
        suggestion: data?.suggestion,
        hashtags: data?.hashtags,
      }}
      config={{
        success: {
          title: t("page.automated-surveys.sentiment.modal.title"),
        },
        unavailable: {
          title: t("page.automated-surveys.sentiment.no-data.notification"),
        },
        error: {
          title: t("page.automated-surveys.sentiment.failed.notification"),
        },
      }}
      {...{ state, isOpened, onOpenChange }}
    />
  );
}
